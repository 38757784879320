.category-Container {
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* border-bottom: 2px solid #eaeaea; */
    /* padding-top: 1.5%; */
}

.category-Container .event-banner {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    /* padding-bottom: 20px; */
}

.category-Container .event-banner img {
    max-width: 100%;
}

.category-Container article {
    display: flex;
    max-width: 1260px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.category-Container .card-item-root {
    border-radius: 0px;
    position: relative;
    overflow: visible;
    /* margin-right: 22px; */
    /* margin-bottom: 4%; */
    margin: 1.5% 0.8%;
}

.category-Container .list-total p {
    text-align: left;
    font-family: 'NotoSansBold';
    font-size: 16px;
    color: #222;
}
.category-Container select.select-type-s {
    position: absolute;
    right: 0;
    bottom: 15px;
}
.category-navi {
    /* height: 160px; */
    width: 100%;
    background: #f6f2e6;
}

.category-navi-inner {
    position: relative;
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
}

.category-navi-inner ul {
    position: absolute;
    left: 0;
    bottom: 20px;
    display: flex;
    align-items: center;
}

.category-navi-inner ul li {
    font-size: 14px;
    line-height: 1.6;
    color: #666;
    font-family: 'NotoSansMedium';
}

.category-navi-inner ul li:nth-child(2) {
    margin: 0 6px;
}

/* .class-detail-container {
    padding: 200px 0 50px;
    min-height: 100vh;
}
 */
@media screen and (max-width: 1023px) {
    .category-Container {
        /* padding: 20px 30px 0 30px; */
        border-bottom: none;
    }
    .category-Container .list-total {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
    .category-Container .list-total P {
        padding-bottom: 5px;
    }
    .category-Container .list-total p + .MuiOutlinedInput-root {
        width: 100%;
    }
    .category-Container select.select-type-s {
        width: 100%;
        background: #fff url(../images/common/drop_icon3.png) no-repeat 97% center;
        background-size: 14px 12px;
        font-size: 12px;
    }

    .category-Container .moContainer .card-item-root {
        display: flex;
        flex-wrap: wrap;
        margin-right: 0;
        margin-bottom: 28px;
    }
    
    .together .itemContainer {margin: 40px auto auto auto;} /* .readingbook .itemContainer, .best .itemContainer, */

    .sub-wrap.best .moContainer,
    .sub-wrap.readingbook .moContainer,
    .sub-wrap.together .moContainer,
    .sub-wrap.mypage-bookmark-wrap .moContainer{
        padding: 0 38px;
    }    
}

@media screen and (max-width: 380px) {
    .category-Container {
        padding: 0;
        border-bottom: none;
    }
}
