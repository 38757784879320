@import "../etc/variables.scss";
.class-detail-container {
  .MuiGrid-root.top {
    margin: 0 auto;
    max-width: 1260px;
    padding-top: 28px;
    padding-bottom: 64px;

    .swiper-pagination {
      display: none;
    }
  }
  .columnLeft.MuiGrid-root {
    max-width: 718px;
    width: 58%;
    flex-basis: 58%;
    .swiper {
      padding: 0;
      margin-bottom: 42px;
    }
    .slide-card-items-list-type .swiper-slide {
      height: 660px;
      .MuiPaper-root {
        width: 100%;
        height: 100%;
        button.MuiButtonBase-root {
          height: 100%;
          img.MuiCardMedia-root {
            height: 100%;
          }
        }
      }
    }

    .swiper-button-next {
      right: 0;
      top: 50%;
      width: 33px;
      height: 64px;
      font-size: 0;
      z-index: 1;
    }
    .swiper-button-next:after {
      content: "";
      background: url("../../images/common/slide-next-btn.png") no-repeat center
        center / 100%;
      width: 33px;
      height: 64px;
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
    .swiper-button-prev {
      left: 0;
      top: 50%;
      width: 33px;
      height: 64px;
      font-size: 0;
      z-index: 1;
    }
    .swiper-button-prev:after {
      content: "";
      background: url("../../images/common/slide-prev-btn.png") no-repeat center
        center / 100%;
      width: 33px;
      height: 64px;
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }

    .classNotice h3 {
      font-size: 24px;
      font-family: "NotoSansBold";
      color: #222;
      text-align: left;
      padding-bottom: 30px;
    }
    .notice-icon {
      width: 28px;
      height: 28px;
      background: url("../../images/detail/notice-icon.png") no-repeat;
      background-size: cover;
      display: inline-block;
      vertical-align: text-bottom;
      margin-right: 12px;
    }
    .notice-text {
      font-size: 16px;
      font-family: "NotoSansRegular";
      color: #666666;
      text-align: left;
    }
  }
  .recommendClass_wrap {
    h1 {
      color: #1c1c1c;
      font: 24px/1.33 "NotoSansBlack";
      letter-spacing: -0.75px;
      text-align: left;
      margin: 20px auto;
      max-width: 1260px;
      .purple {
        color: #2f99f4;
      }
      .grapefruit {
        color: #ff5651;
      }
    }
  }
}

.header.top-banner + .class-detail-container .MuiGrid-root.top {
  padding-top: 20px;
}

.class-info-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 36px;
  li {
    margin: 0 30px 16px 0;
    position: relative;
    display: flex;
    align-items: center;
    p {
      padding-left: 6px;
      color: #1c1c1c;
      font-size: 18px;
      font-family: "NotoSansBold";
      letter-spacing: -0.45px;
    }
    span {
      width: 28px;
      height: 28px;
      padding-right: 28px;
    }
    .classinfo-icon {
      background: url("../../images/detail/curriculum-icon.png") no-repeat;
      background-size: cover;
    }
    .duration-icon {
      background: url("../../images/detail/duration-icon.png") no-repeat;
      background-size: cover;
    }
    .package-icon {
      background: url("../../images/detail/package-icon.png") no-repeat;
      background-size: cover;
    }
    .usercount-icon {
      background: url("../../images/detail/usercount-icon.png") no-repeat;
      background-size: cover;
    }
    .targetage-icon {
      background: url("../../images/detail/targetage-icon.png") no-repeat;
      background-size: cover;
    }
    .bookpad-icon {
      background: url("../../images/detail/bookpad-icon.png") no-repeat;
      background-size: cover;
    }
  }
}

.categoryClassInfo {
  padding-left: 34px;
  .btnShare {
    cursor: pointer;
    color: #1c1c1c;
  }
  .class-info-top {
    display: flex;
    padding-bottom: 28px;
    position: relative;
    .category-name {
      font-size: 15px;
      color: #1c1c1c;
      font-family: "NotoSansBold";
      padding-right: 10px;
    }
    .tag-list {
      margin-bottom: 0;
    }
  }
  .class-info-middle {
    text-align: left;

    .class-title {
      font-size: 24px;
      line-height: 1.6;
      font-family: "NotoSansBold";
      color: #222;
      padding-bottom: 27px;
      border-bottom: 0;
      margin-top: 0;
    }
    .class-summary {
      font-size: 16px;
      line-height: 1.8;
      font-family: "NotoSansMedium";
      color: #222;
      padding-bottom: 13px;
    }
    .class-hashtag {
      padding-bottom: 20px;
      li {
        font-family: "NotoSansRegular";
        font-size: 14px;
        color: #666;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .class-price-wrap {
      display: flex;
      align-items: center;
      padding: 18px 0 10px;
      justify-content: space-between;
      position: relative;

      + .ref-txt-list {
        padding-bottom: 10px;
        border-bottom: 1px solid #eaeaea;
        li {
          font-size: 14px;
          line-height: 1.6;
          color: #666;
        }
      }
      .include-bookclub_point_max_rate {
        font-size: 14px;
        font-family: "NotoSansMedium";
        color: #fb5651;
        position: absolute;
        bottom: 20%;
        transform: translateY(-50%);
      }
      .info-price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        .base-price {
          font-family: "NotoSansBold";
          font-size: 16px;
          color: #666;
          text-decoration: line-through;
          position: absolute;
          top: -20px;
        }
        .discount {
          font-family: "NotoSansBold";
          font-size: 16px;
          color: #ff5651;
          margin-right: 10px;
          position: inherit;
          left: auto;
        }
        .price {
          font-family: "NotoSansBold";
          font-size: 30px;
          letter-spacing: -0.75px;
          color: #1c1c1c;
          display: flex;
          align-items: inherit;
        }
      }
    }
    .coupon-down-btn {
      border-radius: 4px;
      padding: 6px 0;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .class-info-bottom {
    padding-top: 15px;
    .total-price-wrap {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 20px;
      line-height: 1.3;
      align-items: center;

      p {
        font-size: 20px;
        font-family: "NotoSansBold";
        color: #222;
      }
      span.price {
        font-size: 30px;
        font-family: "NotoSansBold";
        color: #ff5651;
        span {
          font-size: 20px;
          font-family: "NotoSansMedium";
          color: #222;
        }
      }
    }
    .class-btnWrap {
      display: flex;
      justify-content: space-between;
      .register-class-btn {
        width: 416px;
        height: 74px;
        // padding: 19px 130px;
        border-radius: 10px;
        background-color: #2f99f4;
        color: #fff;
        font-size: 24px;
        font-family: "NotoSansBold";
        &.disabled-btn {
          color: #fff;
          background: #9aaab7;
          border: solid 2px #9aaab7;
          border-radius: 10px;
        }
      }
      .register-class-btn:hover {
        background-color: #1565c0;
      }
      .favorite-count-icon {
        width: 84px;
        height: 74px;
        padding-top: 10px;
        border-radius: 10px;
        border: solid 2px #afb4b2;
        background-color: #fff;
        flex-flow: column;
        img {
          width: 32px;
          height: 28px;
          margin-bottom: 2px;
        }
        span {
          font-size: 14px;
          color: #222;
        }
      }
    }
  }
}

.teacher-recap {
  background-color: #fdf6ea;
  // padding: 22px 21px 20px;
  padding: 20px 10px;
  margin-top: 30px;
  cursor: pointer;

  .info-teacher {
    width: 100%;
    display: flex;
    // justify-content: start;
    justify-content: space-between;
    align-items: center;
  }
  .teacher-img {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 0px solid #1c1c1c;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    img {
      width: 100%;
      // height: 100%;
      // object-fit: contain;
    }
  }
  .teacher_name {
    width: 67%;
    p {
      font-family: "NotoSansBold";
      font-size: 16px;
      color: #1c1c1c;
      margin-bottom: 5px;
    }
    span {
      font-size: 14px;
      color: #222;
    }
    span.line-info-1 {
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    div.teacher-rate {
      font-family: "NotoSansMedium";
      font-size: 14px;
      color: #4e6883;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-top: 5px;
      cursor: pointer;
      img {
        width: 18px;
        height: 17px;
      }
      span {
        font-size: 15px;
        color: #222;
        font-weight: bold;
        margin: 0 3px;
      }
      span:last-child {
        font-size: 14px;
        color: #666;
        font-weight: 400;
        position: relative;
        margin: 0 14px;
      }
      span:last-child::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 80%;
        background-color: #666;
        top: 60%;
        left: -6px;
        transform: translateY(-50%);
      }
    }
  }

  .see-more-btn {
    border: 1px solid #1c1c1c;
    border-radius: 13px;
    font-size: 12px;
    color: #222;
    font-family: "NotoSansMedium";
    height: 26px;
    width: 70px;
    padding: 4px 10px 4px 4px;
    background-color: #fff;
    position: relative;
    .arrow-icon {
      background: url("../../images/common/nex-arrow-m.png") no-repeat;
      position: absolute;
      width: 10px;
      height: 10px;
      right: 8px;
      background-size: contain;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}

.middle {
  background-color: #f8f8f8;
  padding: 60px 0 58px;
  .tapWrap {
    width: 100%;
    .detail-section {
      width: 100%;
      max-width: 1260px;
      margin: 0 auto;
      padding: 50px 60px 60px;
      background-color: #fff;
      border-radius: 15px;
    }
    .detailTabList {
      position: -webkit-sticky;
      position: sticky;
      top: 180px;
      left: 0;
      z-index: 9;
      background: #fff;
      margin-bottom: 0;
    }
    .detailTabBox {
      display: block;
      .innerHtml_style {
        color: initial;
        line-height: 1.5;
        img {
          max-width: 100%;
        }
        table {
          width: 100% !important;
        }
        iframe {
          height: 400px;
          width: 720px;
          display: block;
        }
      }
    }
    .detailTabList.fixed + .detailTabBox_wrap .detailTabBox {
      padding-top: 100px;
      p {
        img {
          max-width: 100%;
        }
      }
    }
    .detailTabList + .detailTabBox_wrap .detailTabBox {
      padding-top: 60px;
      p {
        color: #000;
        line-height: 1.5;
      }
      .innerHtml_style div {
        color: #000;
        line-height: 1.5;
      }
    }
  }
}

.header.top-banner
  + .class-detail-container
  .middle
  .tapWrap
  .detailTabList.fixed {
  top: 244px;
}

.bottom {
  padding-top: 90px;
}

.classSupplies-list-wrap {
  .list-title {
    font-family: "NotoSansBold";
    font-size: 20px;
    color: #222;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 12px;
    span {
      padding-left: 24px;
    }
  }
  .list-title::before {
    content: "";
    position: absolute;
    background: url("../../images/detail/circle-check.png") no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
  }
  .list-content {
    // display: flex;
    padding-bottom: 40px;
    .con-box {
      width: 350px;
      margin-right: 10px;
      text-align: center;
      padding: 0 25px;
      border: solid 1px #aacef6;
      border-radius: 5px;
      background-color: #eff6ff;
      p {
        color: #666;
        font-family: "NotoSansMedium";
        padding: 10px 0;
        position: relative;
      }

      span {
        color: #1499ed;
        font-family: "NotoSansMedium";
        padding-left: 25px;
      }
    }
    .con-box:first-child {
      p:first-child::before {
        content: "";
        position: absolute;
        background: url("../../images/detail/book-icon.png") no-repeat;
        background-size: contain;
        width: 18px;
        height: 18px;
      }
    }
    .con-box:nth-child(2) {
      p:first-child::before {
        content: "";
        position: absolute;
        background: url("../../images/detail/pencil-icon.png") no-repeat;
        background-size: contain;
        width: 18px;
        height: 18px;
      }
    }

    &.innerHtml_style {
      p.MuiTypography-body1 {
        div,
        p {
          white-space: break-spaces;
        }
      }
    }
    ul {
      li {
        color: #000;
        line-height: 1.5;
        span {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #666;
          margin: 0 10px 0 0;
          vertical-align: middle;
        }
      }
    }
  }
}

.calendar-date-wrap {
  .MuiTypography-root {
    font-size: 16px;
    color: #222;
    font-family: "NotoSansBold";
    padding: 20px 0;
    img {
      margin-right: 10px;
    }
  }

  .DateWrap {
    // height: 260px;
    border: 1px solid #1499ed;
  }

  .TimeRadioWrap {
    margin-bottom: 8px;
  }

  .dayTime,
  .afternoonTime {
    font-size: 14px;
  }

  .dashed-bar {
    position: relative;
    padding-bottom: 20px;
    margin-top: 20px;
  }

  .dashed-bar::before {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 2px dashed #1c1c1c;
    opacity: 0.7;
  }
  .radioGrid > div > div {
    flex-wrap: wrap;
    flex-direction: initial;
  }
  .radioGrid {
    .MuiFormControlLabel-root {
      margin-left: 0;
      margin-right: 8px;
      justify-content: center;
      width: 70px;
      height: 65px;
    }

    .MuiTypography-root {
      padding: 0;
    }
    .radio_item {
      span + .MuiTypography-root p {
        background: transparent;
        border-radius: 5px;
        border: 1px solid #1c1c1c;
        text-align: center;
        width: 100%;
        height: 100%;
        line-height: 3.5;
        color: #1c1c1c;
        font-family: "NotoSansBold";
        transition: all 0.2s ease-in-out;
      }

      span.Mui-checked + .MuiTypography-root p {
        padding: 0;
        background: #f8ce40;
        color: #1c1c1c;
        border: solid 1px #1c1c1c;
        font-family: "NotoSansBold";
      }

      span.Mui-disabled + .MuiTypography-root p {
        padding: 0;
        background: #f8f8f8;
        color: #b0b4b2;
        border: solid 1px #b0b4b2;
        font-family: "NotoSansBold";
      }
    }
  }

  .schedule-alert {
    .MuiPaper-root {
      padding: 16px;
      border: 1px solid #1c1c1c;
      position: relative;
      background: #fff;
    }

    .MuiAlert-message {
      padding: 0;
    }

    .MuiTypography-root {
      padding: 0;
      font-family: "NotoSansMedium";
    }

    .MuiAlert-action button.MuiButtonBase-root {
      color: #b0b4b2;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

// 추가 hong
.classInquiry-create-wrap {
  position: relative;
  width: 885px;
  /* .classInquiry-info-wrap {
        display: flex;
        .classInquiry-info-img {
            width: 154px;
            height: 142px;
            overflow: hidden;
            .orderInfo-img {
                display: block;
                width: 100%;height: 100%;
                cursor: default;
                img {width: 100%;height: 100%;object-fit: contain;}
            }
        }

        .card-content-wrap {
            padding: 0 0 30px 30px;
            width: calc(100% - 154px);
            .content-top + h3 {
                // font-size: $h6;
                font-size: 18px;
                margin-bottom: 18px;
                margin-top: 10px;
                cursor: default;
            }

            .content-bottom{
                .info-teacher{
                    display: flex;
                    .teacher-img{
                        img{
                            width: 60px;
                            height: 60px;
                            border: 1px solid rgb(121, 108, 108);
                            border-radius: 100%;
                        }
                    }
                    
                    .teacher_name{
                        // display: flex;
                        p{
                            padding-left: 2px;
                            font-size: 18px;
                            font-family: 'NotoSansMedium';
                            margin-left: 10px;
                        }
                        .teacher-rate{
                            display: flex;
                            align-items: center;
                            margin: 10px;
                        }
                    }

                }
            }
        }
    } */
  .classInquiry-info-text-wrap {
    .pop-table {
      border-top: 1px solid #e5e8ea;
      border-bottom: 1px solid #e5e8ea;
      padding: 14px 0;
      .tr2 {
        margin-top: 12px;
      }
      .columnLeft {
        font-size: $h5;
        color: $black;
        font-weight: $bold;
        span.important {
          color: $red;
        }
      }
      .columnRight {
        .input-type1 {
          width: 100%;
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid #d9dcde;
            border-radius: 6px;
          }
          input {
            width: 100%;
            // border: 1px solid #d9dcde;
            padding: 18px 20px;
            font-size: $h6;
            &::-webkit-input-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &::-moz-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &:-ms-input-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &::-ms-input-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &::placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
          }
        }
        .textarea-type1 {
          width: 100%;
          border-radius: 6px;
          // border: 1px solid #afb4b2;
          border: 1px solid #d9dcde;
          padding: 20px;
          font-size: $h6;
          resize: none;
          overflow-y: scroll;
          height: 240px;
          &::-webkit-input-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &::-moz-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &:-ms-input-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &::-ms-input-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &::placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
        }
        .student-info {
          color: #1c1c1c;
        }
      }
    }
  }
  .classInquiry-notice-wrap {
    padding-top: 20px;
    .classInquiry-notice-dot {
      position: relative;
      font-size: $h8;
      color: $gray6;
      padding-left: 8px;
      font-family: "NotoSansRegular";
      &::before {
        content: "";
        background: $gray6;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 5px;
      }
      .classInquiry-notice-bar {
        display: block;
        font-family: "NotoSansRegular";
        font-size: $h8;
        color: $gray6;
      }
    }
  }
}

.classReview-show-wrap {
  position: relative;
  width: 960px;
  height: 530px;
}

.classReview-create-wrap {
  position: relative;
  width: 885px;
  .classReview-info-wrap {
    display: flex;
    .classReview-info-img {
      width: 154px;
      height: 142px;
      overflow: hidden;
      .orderInfo-img {
        display: block;
        width: 100%;
        height: 100%;
        &.myImg-m {
          img {
            width: 100%;
            height: 100%;
            -o-object-fit: contain;
            object-fit: contain;
          }
        }
      }
    }

    .card-content-wrap {
      padding: 0 0 0 30px;
      width: calc(100% - 154px);
      .content-top + h3 {
        margin-top: 10px;
      }
    }
  }
  .classReview-info-text-wrap {
    .pop-table {
      //border-top: 1px solid #e5e8ea;
      border-bottom: 1px solid #e5e8ea;
      // padding: 14px 0;
      letter-spacing: -1px;
      .tr2 {
        padding: 20px 0;
        border-bottom: 1px solid #eaeaea;
        &.line2 {
          .columnRight {
            max-width: 84%;
            flex-basis: 84%;
          }
        }
      }
      .columnLeft {
        font-size: $h5;
        color: $black;
        font-weight: $bold;

        &.MuiGrid-grid-xs-3 {
          flex-basis: 16%;
          max-width: 16%;
        }
        span.important {
          color: $red;
        }
      }
      .columnRight {
        .MuiRating-iconFilled {
          .MuiSvgIcon-root {
            stroke: #1c1c1c;
            stroke-width: 1px;
            fill: #f8ce40;
          }
        }
        .MuiRating-iconEmpty {
          .MuiSvgIcon-root {
            stroke: #1c1c1c;
            stroke-width: 1px;
            fill: #fffbf4;
            opacity: 1;
          }
        }
        .score-text {
          display: inline-block;
          vertical-align: top;
          font-size: $h4;
          color: $gray2;
          font-weight: $bold;

          span {
            color: #afb4b2;
            display: inline-block;
            vertical-align: top;
            font-size: $h4;
            font-weight: $bold;
          }
        }
        .input-type1 {
          width: 100%;
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid #d9dcde;
            border-radius: 6px;
          }
          input {
            width: 100%;
            // border: 1px solid #d9dcde;
            padding: 18px 20px;
            font-size: $h6;
            &::-webkit-input-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &::-moz-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &:-ms-input-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &::-ms-input-placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
            &::placeholder {
              font-size: $h6;
              color: #afb4b2;
              opacity: 1;
            }
          }
        }
        .textarea-type1 {
          width: 100%;
          border-radius: 6px;
          border: 1px solid #afb4b2;
          height: 180px;
          padding: 20px;
          font-size: $h6;
          resize: none;
          overflow-y: scroll;
          &::-webkit-input-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &::-moz-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &:-ms-input-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &::-ms-input-placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
          &::placeholder {
            font-size: $h6;
            color: #afb4b2;
          }
        }
      }
    }
  }
}

// .popContents .together-info-regist {margin-top: 0;}

@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .teacher-recap {
    flex-wrap: nowrap;
    .teacher_name {
      span {
        font-size: 14px;
        color: #222;
      }
      div.teacher-rate {
        img {
          width: 15px;
          height: 14px;
        }
        span {
          font-size: 14px;
        }
        span:last-child {
          font-size: 13px;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    padding: 90px 20px 0 20px;
  }
  .recomendClass_container {
    width: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .class-detail-container {
    padding: 0;
    .MuiGrid-root.top {
      max-width: 100%;
      padding-top: 0;
      .swiper-pagination {
        display: block;
      }
    }

    .columnLeft.MuiGrid-root {
      max-width: 100%;
      width: 100%;
      flex-basis: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
      .slide-card-items-list-type.swiper {
        position: relative;
        padding: 0px 0 30px 0;
        margin-bottom: 0;
        .swiper-slide {
          height: 100%;
          max-height: 100%;
        }
        .swiper-slide .MuiPaper-root {
          height: 90vw;
          border-radius: 0;
          box-shadow: none;
          button.MuiButtonBase-root {
            height: 100%;
            img.MuiCardMedia-root {
              height: 100%;
            }
          }
        }
      }
    }

    .columnRight.MuiGrid-grid-xs-5 {
      max-width: 100%;
      flex-basis: 100%;
      .classNotice {
        padding: 0 25px;
        h3 {
          font-size: 16px;
          padding-top: 30px;
          padding-bottom: 20px;
          font-family: "NotoSansBold";
          color: #222;
          text-align: left;
        }
      }

      .notice-icon {
        width: 20px;
        height: 20px;
        background: url("../../images/detail/notice-icon.png") no-repeat;
        background-size: cover;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 9px;
      }
      .notice-text {
        font-size: 13px;
        font-family: "NotoSansRegular";
        color: #666666;
        text-align: left;
        text-indent: -10px;
        padding-left: 10px;
        p {
          font-size: 13px;
        }
      }
    }

    .recommendClass_wrap h1 {
      font: 18px/1.33 "NotoSansBlack";
      padding-left: 20px;
    }
  }

  .categoryClassInfo {
    padding: 0 25px 0 25px;
    .class-info-top {
      padding-bottom: 20px;
    }
    .category-name {
      font-size: 15px;
      padding-right: 8px;
    }
    .class-info-middle {
      // border-top: 2px solid #1c1c1c;
      padding-top: 10px;
      .class-title {
        font-size: 20px;
        line-height: 1.3;
        padding-bottom: 10px;
        padding-top: 10px;
        margin: 0;
      }
      .class-summary {
        font-size: 14px;
        line-height: 1.5;
        padding-bottom: 0;
      }
      .class-hashtag {
        padding-top: 10px;
        padding-bottom: 10px;
        li {
          font-size: 12px;
        }
      }
      .class-price-wrap {
        padding-bottom: 20px;
        .include-textbook {
          font-size: 12px;
          bottom: 15px;
        }
        .info-price {
          .price {
            font-size: 20px;
            span {
              font-size: 16px;
            }
          }
          .discount {
            font-size: 13px;
          }
          .discount-price {
            font-size: 20px;
          }
        }
        + .ref-txt-list {
          padding-bottom: 6px;
          li {
            font-size: 12px;
            line-height: 1.6;
            color: #666;
          }
        }
      }
    }

    .class-info-bottom {
      position: fixed;
      bottom: -1px;
      left: 0;
      width: 100%;
      z-index: 100;
      padding-top: 0;
      .class-btnWrap {
        .favorite-count-icon {
          width: 73px;
          height: 56px;
          border: solid 1px #b0b4b2;
          border-radius: 0;
          img {
            width: 24px;
            height: 21px;
            margin-bottom: 0;
          }
          span {
            font-size: 12px;
            color: #666;
          }
        }
        .register-class-btn {
          width: calc(100% - 73px);
          height: auto;
          border-radius: 0;
          font-size: 18px;
          margin-left: 0;
          &.disabled-btn {
            border-radius: 0;
            color: #fff;
            background: #8d99a5;
            border: solid 2px #8d99a5;
          }
        }
      }
    }
    .class-btnWrap {
      display: flex;
      justify-content: space-between;
      .register-class-btn {
        width: 416px;
        height: 74px;
        // padding: 19px 130px;
        border-radius: 10px;
        background-color: #2f99f4;
        color: #fff;
        font-size: 24px;
        font-family: "NotoSansBold";
        &.disabled-btn {
          color: #fff;
          background: #9aaab7;
          border: solid 2px #9aaab7;
          border-radius: 10px;
        }
      }
      .register-class-btn:hover {
        background-color: #1565c0;
      }
      .favorite-count-icon {
        width: 84px;
        height: 74px;
        padding-top: 10px;
        border-radius: 10px;
        border: solid 2px #afb4b2;
        background-color: #fff;
        flex-flow: column;
        img {
          width: 32px;
          height: 28px;
          margin-bottom: 2px;
        }
        span {
          font-size: 14px;
          color: #222;
        }
      }
    }
  }

  .teacher-recap {
    padding: 20px 16px 20px;
    margin-top: 20px;
    .teacher-img {
      width: 56px;
      height: 56px;
    }
    .teacher_name {
      width: 74%;
      p {
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
      span.line-info-1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        padding-top: 5px;
      }
      div.teacher-rate {
        font-size: 14px;
        img {
          width: 16px;
          height: 15px;
        }
        span {
          font-size: 13px;
        }
        span:last-child {
          font-size: 12px;
        }
      }
    }
    .info-teacher + button {
      // display: inline-block;
      // padding-left: 66px;
      // padding-top: 10px;
      margin: 10px 60px 0;
    }
  }

  .class-info-list {
    padding-bottom: 10px;
    li {
      margin: 0 0 10px 0;
      width: 50%;
      span {
        width: 20px;
        height: 20px;
        padding-right: 20px;
      }
      p {
        padding-left: 6px;
        color: #1c1c1c;
        font-size: 13px;
      }
    }
  }
  .middle {
    padding: 10px 0 10px 0;
    .tapWrap {
      .detailTabList + .detailTabBox_wrap .detailTabBox {
        padding-top: 35px;
        p {
          img {
            max-width: 100%;
          }
          table {
            width: 100% !important;
          }
        }
      }
      .detailTabList.fixed + .detailTabBox_wrap .detailTabBox {
        padding-top: 60px;
      }

      .detailTabList.fixed {
        width: 100%;
        top: 56px;
        padding: 0;
      }
      .detailTabBox {
        .innerHtml_style {
          img {
            max-width: 100%;
          }
          table {
            width: 100% !important;
          }
          iframe {
            height: 51vw;
            width: 100%;
          }
        }
      }
    }
  }
  .detailTabBox_Container {
    padding: 0;
    max-width: 100%;
    border-radius: 0;
  }
  .detailTabList {
    li {
      // width: calc(100% / 4);
      width: 100%;
      height: auto;
      font: 14px/1.4 "NotoSansMedium";
      // padding: 10px 0px 10px 0px;

      word-break: keep-all;
      &.isOn {
        border-bottom: 4px solid #ff5651;
      }
      &.isOn::after {
        content: none;
      }
    }
  }

  .classSupplies-list-wrap {
    .list-title {
      font-size: 16px;
      // padding-bottom: 12px;
    }
    .list-title::before {
      width: 16px;
      height: 16px;
    }
    .list-content {
      padding-bottom: 30px;
      justify-content: space-between;
      ul {
        padding-left: 40px;
        li {
          font-size: 14px;
          text-indent: -16px;
          span {
            margin: 0 6px 0 0;
          }
        }
      }
      .con-box {
        width: 48%;
        padding: 0;
        span {
          font-size: 14px;
        }
        p {
          font-size: 13px;
          padding: 5px 0px 5px;
        }
        p:first-child {
          padding: 16px 5px 5px;
        }
        p:last-child {
          padding: 5px 0 15px;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .bottom {
    padding-top: 0 !important;
    width: 100%;
  }
  .recomendClass_container {
    h1 {
      font: 18px/1.33 "NotoSansBlack";
      margin-bottom: 15px;
      padding-left: 25px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    .swiper-slide:first-child {
      margin-left: 25px;
    }
  }

  .classReview-show-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .together-info-regist {
    .regist-area {
      .item-slt-area {
        &.step3 {
          padding-bottom: 26px;
          border-bottom: 1px dashed #1c1c1c;
        }
        .item-slt-each {
          position: relative;
          margin-bottom: 30px;

          &:last-of-type {
            margin-bottom: 0 !important;
          }
          .MuiFormControl-root {
            width: 100%;

            .comboBox-type1 {
              width: 100%;
              height: 60px;
            }
          }
          .chk-txt-box {
            padding: 18px 30px;
            background-color: #fff;
            border: 1px solid #afb4b2;
            border-radius: 5px;
            color: #1c1c1c;
            font-size: 16px;
            line-height: 24px;
          }
          .change-btn {
            position: absolute;
            right: 0;
            top: -6px;
            width: 62px;
            height: 32px;
            line-height: 30px;
            border-color: #1c1c1c;
            border-radius: 16px;
            color: #1c1c1c;
            font-family: "NotoSansMedium";
            font-size: 14px;
          }
        }
        .guide-txt {
          margin-top: 5px;
          color: #666;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.3px;
        }
      }

      .guide-tit {
        margin-bottom: 10px;
        padding-left: 30px;
        background: url("../../images/common/grade.png") 0 2px/20px no-repeat;
        color: #1c1c1c;
        font-family: "NotoSansBold";
        line-height: 24px;

        &.period {
          background-image: url("../../images/common/period.png");
          background-size: 24px;
        }
        &.doc-check {
          background-image: url("../../images/common/doc-check.png");
        }
        &.cal {
          background-image: url("../../images/common/calendar2.png");
          background-size: 20px;
        }
      }

      .schedule-area {
        .top-area {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .guide-tit {
            margin-bottom: 0;
            margin-right: 10px;
          }
          .ps-txt {
            padding: 3px 0;
            color: #666;
            font-size: 12px;
            letter-spacing: -0.3px;
          }
        }

        .day-btn-area {
          button {
            flex: 1;
            height: 50px;
            padding: 0 5px;
            border-color: #1c1c1c;
            border-radius: 6px;
            color: #1c1c1c;
            font-family: "NotoSansBold";
            font-size: 16px;

            &.active {
              background-color: #f8ce40;
            }
          }
        }

        .slt-area {
          margin-top: 30px;

          .cell {
            flex: 1;
            height: 35px;
            line-height: 35px;
            color: #1c1c1c;
            font-family: "NotoSansMedium";
            font-size: 14px;
            text-align: center;
            letter-spacing: -0.4px;

            &:nth-child(2) {
              flex-grow: 2;
            }
            .MuiRadio-root {
              padding: 0;
              color: #afb4b2;

              &.Mui-checked {
                color: #1c1c1c;
              }
            }
          }

          .thead {
            .cell {
              background-color: #0e7fe0;
              color: #fff;
              font-family: "NotoSansBold";
              font-size: 15px;
            }
          }
          .tbody {
            border: 2px solid #0e7fe0;

            .row {
              &.active {
                background-color: #f8ce40;
              }
              &.disabled {
                background-color: #f0f0f0;

                .cell {
                  color: #afb4b2;
                }
              }
            }
          }
        }
      }
    }

    .step-view {
      height: 85px;
      padding: 14px;

      .tit {
        font-size: 14px;
      }
    }

    .regist-area {
      .item-slt-area {
        margin-bottom: 30px;
        padding: 0 25px;

        .item-slt-each {
          .MuiFormControl-root {
            .comboBox-type1 {
              height: 44px;
            }
          }
        }
      }
      .schedule-area {
        .top-area {
          margin-bottom: 15px;
          padding: 0 25px;
        }
        .day-btn-area {
          flex-wrap: wrap;
          padding: 0 25px;

          button {
            flex-basis: 92px;
            flex-grow: 0;
            margin-left: 0;
            margin-right: 12px;
            margin-bottom: 11px;
          }
        }
        .ps-txt {
          padding: 0 25px;
          font-size: 12px;
        }
        .slt-area {
          margin-top: 20px;

          .thead {
            .cell {
              height: 42px;
              line-height: 42px;
              font-size: 14px;
            }
          }
          .tbody {
            border-left-width: 0;
            border-right-width: 0;

            .cell {
              height: 50px;
              line-height: 50px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .schedule-alert {
    .together-info-regist {
      .regist-area {
        .item-slt-area {
          margin-bottom: 0;
          padding: 0;

          .item-slt-each {
            margin-bottom: 20px;

            .chk-txt-box {
              padding: 12px 14px;
              border-color: #1c1c1c;
              font-family: "NotoSansMedium";
              font-size: 14px;
            }
          }
        }
      }
    }
    .alert-close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      min-width: 0;
      height: 20px;
      padding: 0;
      color: #afb4b2;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sub-header + .class-detail-container {
    padding: 0;
  }
  .class-detail-container {
    padding: 56px 0 0 0;

    .MuiGrid-root.top {
      max-width: 100%;
    }
    .columnLeft.MuiGrid-root {
      max-width: 100%;
      width: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
      .slide-card-items-list-type.swiper {
        position: relative;
        padding: 0px 0 30px 0;
        margin-bottom: 20px;
        .swiper-slide {
          height: 100%;
          max-height: 100%;
        }
        .swiper-slide .MuiPaper-root {
          height: 90vw;
          border-radius: 0;
          box-shadow: none;
          button.MuiButtonBase-root {
            height: 100%;
            img.MuiCardMedia-root {
              height: 100%;
            }
          }
        }
      }
    }
    .columnRight.MuiGrid-grid-xs-5 {
      max-width: 100%;
      flex-basis: 100%;
      .classNotice {
        padding: 0 25px;
        h3 {
          font-size: 16px;
          padding-top: 30px;
          padding-bottom: 20px;
          font-family: "NotoSansBold";
          color: #222;
          text-align: left;
        }
      }
      .notice-icon {
        width: 20px;
        height: 20px;
        background: url("../../images/detail/notice-icon.png") no-repeat;
        background-size: cover;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 9px;
      }
      .notice-text {
        font-size: 13px;
        font-family: "NotoSansRegular";
        color: #666666;
        text-align: left;
      }
    }
  }

  .categoryClassInfo {
    padding: 0 25px 0 25px;
    .class-info-bottom {
      // display: none;
      bottom: -1px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      padding-top: 0;
      .class-btnWrap {
        .favorite-count-icon {
          width: 73px;
          height: 56px;
          border: solid 1px #b0b4b2;
          border-radius: 0;
          img {
            width: 24px;
            height: 21px;
            margin-bottom: 0;
          }
          span {
            font-size: 12px;
            color: #666;
          }
        }
        .register-class-btn {
          width: calc(100% - 73px);
          height: auto;
          border-radius: 0;
          font-size: 18px;
          margin-left: 0;
          &.disabled-btn {
            border-radius: 0;
            color: #fff;
            background: #8d99a5;
            border: solid 2px #8d99a5;
          }
        }
      }
    }
  }

  .middle {
    padding: 10px 0 10px 0;
    .tapWrap {
      .detailTabList + .detailTabBox_wrap .detailTabBox {
        padding-top: 35px;
      }
      .detailTabList.fixed + .detailTabBox_wrap .detailTabBox {
        padding-top: 60px;
      }

      .detailTabList.fixed {
        width: 100%;
        top: 56px;
        padding: 0;
      }
    }
  }

  .detailTabBox_Container {
    padding: 0;
    max-width: 100%;
    border-radius: 0;
  }
  .detailTabList {
    li {
      width: calc(100% / 4);
      height: auto;
      font: 14px/1.4 "NotoSansMedium";
      padding: 10px 18px 9px 17px;
      word-break: keep-all;
      &.isOn {
        border-bottom: 4px solid #ff5651;
      }
      &.isOn::after {
        content: none;
      }
    }
  }

  .classSupplies-list-wrap {
    .list-title {
      font-size: 16px;
      // padding-bottom: 12px;
    }
    .list-title::before {
      width: 16px;
      height: 16px;
    }
    .list-content {
      padding-bottom: 30px;
      justify-content: space-between;
      ul {
        padding-left: 40px;
        li {
          font-size: 14px;
          text-indent: -16px;
          span {
            margin: 0 6px 0 0;
          }
        }
      }
      .con-box {
        width: 48%;
        padding: 0;
        span {
          font-size: 14px;
        }
        p {
          font-size: 13px;
          padding: 5px 0px 5px;
        }
        p:first-child {
          padding: 16px 5px 5px;
        }
        p:last-child {
          padding: 5px 0 15px;
          word-break: break-all;
        }
      }
    }
  }

  .bottom {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 20px;
    width: 100%;
  }
  .recomendClass_container {
    h1 {
      font: 18px/1.33 "NotoSansBlack";
      margin-bottom: 15px;
      padding-left: 25px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    // .swiper-slide {
    //     width: 40% !important;
    // }
    .swiper-slide:first-child {
      padding-left: 0;
    }
  }
}

//  ********************************************************************************************************************************************************************************************
//  ********************************************************************************************************************************************************************************************
//  ************************************************************************************* 모바일 시작 *******************************************************************************************
//  ************************************************************************************* max 1023px *******************************************************************************************
//  ********************************************************************************************************************************************************************************************
//  ********************************************************************************************************************************************************************************************
@include mobile {
  .class-detail-container {
    .categoryClassInfo {
      .class-info-bottom {
        .total-price-wrap {
          display: none;
        }
      }
    }
  }

  .calendar-date-wrap {
    .DateWrap {
      border: none;
    }
  }
  .class-info-bottom {
    &.class-info-bottom-mobile {
      background: #fdf6ea;
      box-shadow: 0 -5px 10px 1px rgba($color: $black, $alpha: 0.1);
      border-top: 1px solid #eaeaea;
      .schedule-alert {
        padding: $mobileSideSpace $mobileSideSpace 0;
        .mobile-alert {
          border: 1px solid $pointColor;
          background: #fff;
          border-radius: 6px;
          .MuiTypography-body1 {
            font-size: $h7;
            color: $black;
          }
        }
      }

      .class-btnWrap {
        .favorite-count-icon {
          width: 73px;
          height: 56px;
          // border: solid 1px #b0b4b2;
          border-radius: 0;
          background-color: #ffffff;
          img {
            width: 24px;
            height: 21px;
            margin-bottom: 0;
          }
          span {
            font-size: 12px;
            color: #666;
          }
        }
        .register-class-btn {
          width: calc(100% - 73px);
          height: auto;
          border-radius: 0;
          font-size: 18px;
          margin-left: 0;
          &.disabled-btn {
            border-radius: 0;
            color: #fff;
            background: #8d99a5;
            border: solid 2px #8d99a5;
          }
        }
      }
    }
  }
  .pop-wrapoption-change-btn {
    .categoryClassInfo {
      .class-info-middle {
        padding-top: 0;
      }
    }
  }

  .classInquiry-create-wrap {
    width: 100%;
    padding: 0 $mobileSideSpace;

    .classInquiry-info-wrap {
      border-bottom: 1px solid #eaeaea;
      .classInquiry-info-img {
        width: 80px;
        height: 74px;
      }
      .card-content-wrap {
        padding: 0 0 20px 20px;
        // width: calc(100% - 154px);
        width: calc(100% - 80px);
        .content-top + h3 {
          font-size: $h6;
        }
      }
    }
    .classInquiry-info-text-wrap {
      .pop-table {
        border: none;

        letter-spacing: -1px;
        .MuiGrid-root.type,
        .MuiGrid-root.top,
        .tr2 {
          margin-top: 20px;
          display: block;
          .columnLeft {
            margin-bottom: 8px;
          }
          > * {
            display: block;
            width: 100%;
            max-width: none;
          }
          .classReview-info-wrap {
            display: flex;
          }
        }
        .columnLeft {
          font-size: $h7;
        }
        .columnRight {
          .input-type1 {
            // display: block;
            width: 100%;
            .MuiOutlinedInput-input {
              height: auto;
            }
            .MuiOutlinedInput-notchedOutline {
              border: 1px solid #d9dcde;
              top: 0;
            }
            input {
              padding: 14px;
              font-size: 14px;
              &::-webkit-input-placeholder {
                font-size: 14px;
                color: #afb4b2;
                opacity: 1;
              }
              &::-moz-placeholder {
                font-size: 14px;
                color: #afb4b2;
                opacity: 1;
              }
              &:-ms-input-placeholder {
                font-size: 14px;
                color: #afb4b2;
                opacity: 1;
              }
              &::-ms-input-placeholder {
                font-size: 14px;
                color: #afb4b2;
                opacity: 1;
              }
              &::placeholder {
                font-size: 14px;
                color: #afb4b2;
                opacity: 1;
              }
            }
          }
          .textarea-type1 {
            padding: 14px;
            height: 180px;
            overflow-y: scroll;
            font-size: $h7;
            resize: none;
            &::-webkit-input-placeholder {
              font-size: $h7;
            }
            &::-moz-placeholder {
              font-size: $h7;
            }
            &:-ms-input-placeholder {
              font-size: $h7;
            }
            &::-ms-input-placeholder {
              font-size: $h7;
            }
            &::placeholder {
              font-size: $h7;
            }
          }
        }
      }
    }
    .classInquiry-notice-wrap {
      .classInquiry-notice-dot {
        font-size: $h8;
        .classInquiry-notice-bar {
          font-size: $h8;
        }
      }
    }
  }

  .classReview-create-wrap {
    width: 100%;
    .classReview-info-wrap {
      .classReview-info-img {
        width: 96px;
        height: 90px;
      }
      .card-content-wrap {
        padding: 0 0 0 20px;
        width: calc(100% - 96px);
        .content-top + h3 {
          font-size: $h6;
          margin-top: 6px;
        }
      }
    }
    .classReview-info-text-wrap {
      .pop-table {
        border: none;
        padding: 0 $mobileSideSpace;
        letter-spacing: -1px;
        .tr2 {
          // margin-top: 20px;
          &:first-child,
          &:last-child {
            padding: 0 0 20px 0;
          }
          &.line1 {
            // border-bottom: 2px solid $pointColor;
            padding-bottom: 20px;
            .columnLeft {
              flex-basis: 25%;
              max-width: 25%;
            }
            .columnRight {
              padding-left: 8px;
              max-width: 70%;
              flex-basis: 70%;
              .student-info {
                font-size: 13px;
                line-height: 1.3;
                color: #1c1c1c;
              }
            }
          }
          &.line2 {
            display: block;
            .columnLeft {
              margin-bottom: 8px;
            }
            > * {
              display: block;
              width: 100%;
              max-width: none;
            }

            .classReview-info-wrap {
              display: flex;
            }
            .columnRight {
              max-width: 100%;
              flex-basis: 100%;
            }
            &.rateLine {
              display: flex;
              .columnLeft {
                flex-basis: 25%;
                margin-bottom: 0;
              }
              .columnRight {
                padding-left: 6px;
                max-width: 75%;
                flex-basis: 75%;
              }

              + .tr2.line1 {
                display: block;
                border-bottom: 0;

                .columnRight {
                  max-width: 100%;
                  padding-top: 10px;
                  padding-left: 0px;
                }
              }
            }
          }
        }
        .columnLeft {
          font-size: $h7;
        }
        .columnRight {
          .MuiRating-iconFilled {
            .MuiSvgIcon-root {
              stroke-width: 1px;
            }
          }
          .MuiRating-iconEmpty {
            .MuiSvgIcon-root {
              stroke-width: 1px;
            }
          }
          .score-text {
            font-size: $h6_2;
            margin-top: 6px;
            margin-left: 5px;
            span {
              font-size: $h6_2;
            }
          }
          .textarea-type1 {
            // min-height: 160px;
            padding: 14px;
            font-size: $h7;
            resize: none;
            overflow-y: scroll;
            height: 160px;
            &::-webkit-input-placeholder {
              font-size: $h7;
            }
            &::-moz-placeholder {
              font-size: $h7;
            }
            &:-ms-input-placeholder {
              font-size: $h7;
            }
            &::-ms-input-placeholder {
              font-size: $h7;
            }
            &::placeholder {
              font-size: $h7;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1261px) and (max-width: 1306px) {
  .bottom {
    width: 100%;
    padding: 90px 20px 0 20px;
  }
}

@media screen and (max-width: 374px) {
  .detailTabList {
    li {
      padding: 10px 5px 10px 5px;
    }
    // li:nth-child(3),li:nth-child(4) {
    //     padding: 10px 5px 10px 5px;
    // }
  }
}
@media screen and (min-width: 375px) and (max-width: 1023px) {
  .detailTabList {
    li {
      padding: 15px 5px;
    }
    // li:nth-child(3),li:nth-child(4) {
    //     padding: 18px 15px 15px 15px;
    // }
  }
}

@media screen and (max-width: 420px) {
  .classSupplies-list-wrap .list-content .con-box span {
    font-size: 13px;
    padding-left: 20px;
  }
  // .detailTabBox_wrap {padding: 0 16px 25px;word-break: break-all;}
}

@media screen and (max-width: 1024px) {
  .middle {
    .tapWrap {
      .detailTabList {
        top: 105px;
      }
    }
  }
  .top-banner + .sub-header + .MuiGrid-root {
    .middle {
      .tapWrap {
        .detailTabList {
          top: 168px;
        }
      }
    }
  }
}
