.arrowIcon {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	color: #1c1c1c;
}
.arrowIcon svg {
	width: 16px;
	transform: rotate(90deg);
}
.arrowIcon.isOn svg {
	transform: rotate(-90deg);
}
