.footer {
    background-color: #1470bf;
    padding-top: 20px;

    .footer-inner {
        padding: 0 25px;
    }

    .footer-inner-top {
        border-bottom: solid 1px #7fc2f7;
        padding-bottom: 16.5px;

        .footer-term-wrapper {
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            max-width: 1260px;

            .footer-term {
                flex: 0 0 auto;
                a {
                    color: #f8f8f8;
                    font-size: 0.813rem;
                    &.bold-footer {
                        font-family: 'NotoSansBold';
                        color: #f8ce40;
                    }
                }
                
                &:after {
                    content: '';
                    display: inline-block;
                    margin: 0 10px 0;
                    width: 1px;
                    height: 10px;
                    background: #f8f8f8;
                    opacity: .3;
                }

                &:last-child::after {
                    content: none;
                }
            }
        }
    }
    .footer-inner-bottom {
        box-sizing: border-box;
        margin: auto;
        max-width: 1260px;
        padding: 25px 0;

        p {
            text-align: left;
            font-size: 0.75em;
            color: #f8f8f8;
        }

        img {
            width: 14px;
            margin-bottom: 2px;
        }
        
        span {
            display: inline-block;
            position: relative;
            margin-right: 10px;

            &.line {
                &::after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 62%;
                    background-color: #afb4b2;
                    position: absolute;
                    top: 24%;
                    right: -5px;
                }
            }
        }

        h5 {
            font-size: 1.5rem;
            font-weight: 600;
            text-align: right;
            color: #fff;
        }
        .footer-inner-bottom-logo {
            text-align: left;
            img{
                width: 33px;
                margin-right: 12px;
                margin-bottom: 25px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1023px) { 
    .footer {
        .footer-inner-top {
            padding-bottom: 15px;

            .footer-term-wrapper {
                line-height: 1.35;
                
                .footer-term {
                    &:before {
                        margin: 0px 10px;
                        height: 60%;
                    }

                    a {
                        font-size: 12px;
                    }
                }
            }
        }
        .footer-inner-bottom {
            align-items: start;
            flex-direction: column-reverse;
            padding: 10px 0;

            p {
                font-size: 10px;
                letter-spacing: -.25px;
            }
            span {
                margin-right: 8px;
            }
            .footer-inner-bottom-logo {
                text-align: left;
                img{
                    width: 26px;
                    margin-right: 12px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

