.classFeedback .subVisual {
  margin-bottom: 60px;
}

article {
  display: flex;
  max-width: 1260px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.classInfo-wrap {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 28px 31px 30px;
  display: flex;
  justify-content: start;
  align-items: start;
  /* gap: 30px; */
  border: 1px solid #1c1c1c;
  /* box-shadow: 8px 8px 0 0 #0e2b49; */
}
/* .classInfo-img {
	width: 154px;
	height: 142px;
}
.classInfo-img img {
	width: 100%;
	height: 100%;
} */
.classInfo-wrap .myImg-m {
  width: 154px;
  height: 142px;
}
.myImg-m img {
  width: 100%;
  height: 100%;
}
.classFeedback .classFeedback-inner .classInfo-desc {
  padding-left: 20px;
}
.classInfo-desc {
  text-align: left;
}
.classInfo-desc .tag {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
}
.classInfo-desc .tag li {
  padding: 8px 19px;
  font: 16px/1 "NotoSansBold";
  letter-spacing: -0.4px;
  border-radius: 15px;
}
.active .classInfo-desc .tag li {
  padding: 8px 17px;
  font: 12px/1 "NotoSansBold";
  letter-spacing: -0.3px;
}
.classInfo-desc .tag .package {
  color: #fff;
  background-color: #1499ed;
}
.classInfo-desc .tag .age {
  color: #1c1c1c;
  background-color: #fbc831;
}
.classInfo-desc h2 {
  font: 20px/1.3 "NotoSansBold";
  letter-spacing: -0.5px;
  margin: 10px 0 20px;
  color: #1c1c1c;
}
.active .classInfo-desc h2 {
  margin: 16px 0 26px;
}
.classInfo-desc h3 {
  font: 20px/1.3 "NotoSansBold";
  letter-spacing: -0.35px;
  color: #1c1c1c;
  margin-bottom: 6px;
}
.classFeedback-inner .classInfo-desc h3 {
  margin-bottom: 12px;
}
.classFeedback-inner .classInfo-desc .tag-list {
  margin-bottom: 10px;
}
.classInfo-desc h4 {
  font: 14px/1 "NotoSansRegular";
  letter-spacing: -0.35px;
  color: #666;
  display: flex;
  align-items: center;
}
.classInfo-desc h4 img {
  width: 15px;
  height: 14px;
  margin-right: 2px;
  margin-bottom: 2px;
}
.classInfo-desc .teacher_name p {
  color: #222;
}
.classInfo-desc .teacher_name p.teacher-rate {
  font-family: "NotoSansRegular";
  color: #666;
}

.commentBox-wrap {
  max-width: 1260px;
  margin: 0 auto;
}

.commentBox-top {
  padding-bottom: 41px;
}

.commentBox-wrap .comment-top {
  padding: 14px 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.commentBox-wrap .comment-top .avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  overflow: hidden;
}
.commentBox-wrap .comment-top h1 {
  font: 16px/1.5 "NotoSansBold";
  letter-spacing: -0.6px;
  color: #1c1c1c;
}

.commentBox-wrap .comment {
  width: 100%;
  text-align: left;
  border: 1px solid #afb4b2;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
}
.commentBox-wrap .comment_textWrap {
  position: relative;
  padding: 20px 30px 45px;
  border-bottom: 1px solid #afb4b2;
  height: calc(100% - 58px);
  border-width: 1px;
}
.commentBox-wrap .comment input {
  border: none;
}
.commentBox-wrap .comment textarea {
  width: 100%;
  height: 194px;
  border: none;
  /* border-bottom: 2px solid #afb4b2; */
  /* padding: 20px 30px 50px; */
  resize: none;
  font: 16px/1.625 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #1c1c1c;
}
.commentBox-wrap .comment textarea:focus {
  outline: none;
}
.commentBox-wrap .comment textarea::-webkit-input-placeholder {
  color: #afb4b2;
}
.commentBox-wrap .comment textarea::-moz-placeholder {
  color: #afb4b2;
}
.commentBox-wrap .comment textarea:-ms-input-placeholder {
  color: #afb4b2;
}
.commentBox-wrap .comment textarea::-ms-input-placeholder {
  color: #afb4b2;
}
.commentBox-wrap .comment textarea::placeholder {
  color: #afb4b2;
}
.commentBox-wrap .comment_textWrap .comment-file-wrap {
  position: relative;
  left: 0;
  bottom: -20px;
  display: inline-block;
}
.delete-btn-wrap {
  width: 10px;
  margin-left: 5px;
}
.delete-btn-wrap img {
  width: 100%;
}
.commentBox-wrap .comment_textWrap .file {
  font: 14px/1 "NotoSansMedium";
  letter-spacing: -0.35px;
  color: #1499ed;
  text-decoration: underline;
}
.commentBox-wrap .comment_textWrap .len {
  position: absolute;
  right: 20px;
  bottom: 30px;
  font: 13px/1 "NotoSansRegular";
  letter-spacing: -0.33px;
  color: #afb4b2;
}
.commentBox-wrap .sub-wrap {
  padding: 10px 0 0;
  min-height: 400px;
}
.commentBox-wrap .sub-wrap .list-empty {
  padding-top: 0 !important;
}
.commentBox-wrap .list-empty .title-section::before {
  width: 33px;
  height: 33px;
  margin-bottom: 20px;
}
.commentBox-wrap .list-empty .title-section .title {
  display: none;
}

.comment_bottom {
  width: 100%;
  height: 58px;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
}
.comment_bottom input[type="file"] {
  display: none;
}
.comment_bottom label {
  font: 16px/1 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #afb4b2;
  margin-left: 20px;
}
.comment_bottom label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(../../images/icon/feedback_clip.png) center/cover;
  margin-right: 10px;
  vertical-align: sub;
}
.comment_bottom button {
  width: 130px;
  height: 36px;
  border-radius: 30px;
  border: 1px solid #1c1c1c;
  background: #c7defa;
  font: 14px/28px "NotoSansBold";
  text-align: center;
  color: #1c1c1c;
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
}

.commentBox-list {
  border-top: 2px solid #afb4b2;
}
.commentBox-list li > div {
  min-height: 74px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px 30px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
}
.commentBox-list li > div.new {
  flex-direction: column;
  background-color: #f8f8f8;
}
.commentBox-list li > div.new::before {
  display: none;
}
.commentBox-list li > div.new .top {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 0 10px;
  position: relative;
}
.commentBox-list li > div.new .top::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-left: 2px solid #666;
  border-bottom: 2px solid #666;
  margin-right: 26px;
  margin-left: 10px;
}
.commentBox-list li > div.new.edit .top::before {
  visibility: hidden;
}
.commentBox-list li > div.new .comment {
  width: calc(100% - 50px);
  margin-left: 50px;
}
.commentBox-list li > div.deleted p {
  font: 16px/1 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #afb4b2;
}

.commentBox-list .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background: #ccc;
  margin-right: 10px;
}
.commentBox-list .name {
  width: 239px;
  text-align: left;
}
.commentBox-list .name h4 {
  font: 16px/1.5 "NotoSansBold";
  letter-spacing: -0.4px;
  color: #1c1c1c;
  padding-bottom: 4px;
}
.commentBox-list .name p {
  font: 14px/1.2 "NotoSansRegular";
  letter-spacing: -0.35px;
  color: #999;
}
.commentBox-list .desc {
  text-align: left;
  width: 52%;
  word-break: break-all;
}
.commentBox-list .desc h5 {
  font: 16px/1.625 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #222;
  white-space: pre-wrap;
}
.commentBox-list .desc a {
  font: 14px/1.714 "NotoSansMedium";
  letter-spacing: -0.35px;
  text-decoration: underline;
  color: #1499ed;
}
.commentBox-list .button-wrap {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  display: flex;
  justify-content: end;
  align-items: center;
  /* gap: 6px; */
}
.commentBox_button {
  width: 62px;
  height: 32px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #162a47;
  text-align: center;
  font: 14px/30px "NotoSansBold";
  letter-spacing: -0.35px;
  color: #222;
  margin-right: 6px;
}
.commentBox_button.grayBg {
  padding: 0;
  background-color: #fff;
  width: 62px;
  margin-right: 6px;
  color: #f04d4d;
}

.commentBox-list .teacher {
  background: #f8f8f8;
}

.commentBox-list .reply::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-left: 2px solid #666;
  border-bottom: 2px solid #666;
  margin-right: 26px;
  margin-left: 10px;
}
.commentBox-list .reply.edit::before {
  display: none;
}

@media screen and (max-width: 1260px) {
  .classFeedback-inner {
    width: 100%;
    padding: 0 3.73%;
  }
}

@media screen and (max-width: 1023px) {
  .classFeedback.sub-wrap {
    padding-bottom: 0;
  }

  .classFeedback .pagination-type {
    margin-bottom: 30px;
  }

  .classFeedback .subVisual {
    display: none;
  }
  .classInfo-wrap {
    margin: 30px 0;
    padding: 24px 14px;
    /* gap: 3.733vw; */
  }
  .classFeedback .classFeedback-inner .classInfo-desc {
    padding-left: 14px;
  }

  .classInfo-wrap .myImg-m {
    width: 80px;
    height: 74px;
    flex: 0 0 auto;
  }
  .classInfo-desc .tag-list {
    margin-bottom: 5px;
  }
  .classInfo-desc h3 {
    font-size: 14px;
    line-height: 1.428;
    margin-bottom: 8px;
  }
  .classInfo-desc .teacher_name {
    display: flex;
    gap: 5px;
  }
  .classInfo-desc .teacher_name p {
    font-size: 12px;
    font-family: "NotoSansMedium";
  }
  .classInfo-desc .teacher_name p.teacher-rate {
    font-size: 12px;
  }

  .commentBox-wrap .comment {
    border-radius: 5px;
  }

  .commentBox-wrap .comment_textWrap {
    padding: 15px 15px 45px;
    border-bottom: 1px solid #afb4b2;
    /* height: 249px; */
    height: calc(100% - 41px);
    border-width: 1px;
  }
  .commentBox-wrap .comment-top {
    padding: 0 0 10px;
    border-bottom: 1px solid #f5f5f5;
  }
  .commentBox-wrap .comment-top .avatar {
    width: 30px;
    height: 30px;
  }
  .commentBox-wrap .comment-top h1 {
    font-size: 13px;
  }
  .commentBox-wrap .comment textarea {
    padding: 15px 0 0;
    /* height: calc(100% - 41px); */
    height: 147px;
  }
  .commentBox-wrap .comment textarea::-webkit-input-placeholder {
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 1.666;
  }
  .commentBox-wrap .comment textarea::-moz-placeholder {
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 1.666;
  }
  .commentBox-wrap .comment textarea:-ms-input-placeholder {
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 1.666;
  }
  .commentBox-wrap .comment textarea::-ms-input-placeholder {
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 1.666;
  }
  .commentBox-wrap .comment textarea::placeholder {
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 1.666;
  }
  .commentBox-wrap .comment_textWrap .file {
    font-size: 12px;
    left: 15px;
    bottom: 15px;
    word-break: break-all;
  }
  .commentBox-wrap .comment_textWrap .len {
    font-size: 12px;
    right: 15px;
    bottom: 15px;
  }

  .commentBox-top {
    padding-bottom: 30px;
  }

  .comment_bottom {
    height: 46px;
    justify-content: space-between;
  }
  .comment_bottom label {
    margin-left: 15px;
    font: 12px/1 "NotoSansRegular";
  }
  .comment_bottom label::before {
    width: 20px;
    height: 20px;
  }
  .comment_bottom button {
    position: relative;
    top: 0;
    /* right: -1px; */
    right: 0;
    transform: translateY(0);
    background: #c7defa;
    width: 71px;
    /* height: calc(100% + 1px); */
    height: calc(100% + 0px);
    border-radius: 0;
    border: 1px solid #1c1c1c;
    border-bottom-right-radius: 5px;
    box-shadow: none;
  }

  <<<<<<< Updated upstream .commentBox-list {
    border-top: none;
    padding-top: 10px;
    background-color: #f8f8f8;
  }
  .commentBox-list li > div {
    background-color: #fff;
    padding: 30px 5.33% 20px 4%;
    min-height: 50px;
    flex-direction: column;
    align-items: start;
    text-align: left;
  }
  .commentBox-list li .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .commentBox-list li .top > div {
    display: flex;
    align-items: center;
  }
  .commentBox-list .avatar {
    width: 30px;
    height: 30px;
  }
  .commentBox-list h4 {
    color: #1c1c1c;
    font: 13px/1 "NotoSansBold";
  }
  .commentBox-list p {
    font-size: 12px;
    letter-spacing: -0.3px;
    margin-right: 10px;
    color: #999;
  }
  .commentBox-list .button-wrap {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
  }
  .commentBox-list .button-wrap .MuiIconButton-root {
    padding: 0;
  }
  .commentBox-list .button-wrap .MuiIconButton-root svg {
    margin-top: 5px;
    font-size: 20px;
    fill: #999;
  }
  .feedback-edit .MuiPaper-root.MuiPopover-paper {
    margin-top: 5px;
    margin-left: -3.2%;
    border: 1px solid #172b47;
    border-radius: 5px !important;
  }
  .feedback-edit .MuiList-root.MuiMenu-list {
    width: 80px;
    padding: 0;
    border-radius: 5px !important;
  }
  .feedback-edit .MuiList-root li {
    font: 13px/1.846 "NotoSansMedium";
    letter-spacing: -0.33px;
    color: #222;
    justify-content: center;
    padding: 0;
    height: 43px;
  }
  .feedback-edit .MuiList-root li:not(:last-child) {
    border-bottom: 1px solid #172b47;
  }
  ======= .commentBox-list {
    border-top: none;
    padding-top: 10px;
    background-color: #f8f8f8;
  }
  .commentBox-list li > div {
    background-color: #fff;
    padding: 30px 5.33% 20px 4%;
    min-height: 50px;
    flex-direction: column;
    align-items: start;
    text-align: left;
  }
  .commentBox-list li .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .commentBox-list li .top > div {
    display: flex;
    align-items: center;
  }
  .commentBox-list .avatar {
    width: 30px;
    height: 30px;
  }
  .commentBox-list h4 {
    color: #1c1c1c;
    font: 13px/1 "NotoSansBold";
  }
  .commentBox-list p {
    font-size: 12px;
    letter-spacing: -0.3px;
    margin-right: 10px;
    color: #999;
  }
  .commentBox-list .button-wrap {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
  }
  .commentBox-list .button-wrap .MuiIconButton-root {
    padding: 0;
  }
  .commentBox-list .button-wrap .MuiIconButton-root svg {
    font-size: 20px;
    fill: #999;
  }
  .feedback-edit .MuiPaper-root.MuiPopover-paper {
    margin-top: 5px;
    margin-left: -3.2%;
    border: 1px solid #172b47;
    border-radius: 5px !important;
  }
  .feedback-edit .MuiList-root.MuiMenu-list {
    width: 80px;
    padding: 0;
    border-radius: 5px !important;
  }
  .feedback-edit .MuiList-root li {
    font: 13px/1.846 "NotoSansMedium";
    letter-spacing: -0.33px;
    color: #222;
    justify-content: center;
    padding: 0;
    height: 43px;
  }
  .feedback-edit .MuiList-root li:not(:last-child) {
    border-bottom: 1px solid #172b47;
  }
  >>>>>>>Stashed changes .commentBox-list li .bottom {
    padding: 0;
    word-break: break-all;
  }
  .commentBox-list li .bottom h5 {
    font-size: 14px;
    line-height: 1.5;
    color: #222;
    margin-bottom: 10px;
    padding-right: 12.267vw;
  }
  .commentBox-list li .bottom a {
    font: 12px/1 "NotoSansMedium";
    color: #1499ed;
    text-decoration: underline;
  }

  .commentBox-list .reply {
    background: transparent;
  }
  .commentBox-list .reply::before {
    display: none;
  }
  .commentBox-list li .reply .top > div:first-child:before {
    content: "";
    display: block;
    width: 2.133vw;
    height: 2.133vw;
    border-left: 1px solid #afb4b2;
    border-bottom: 1px solid #afb4b2;
    margin-right: 10px;
    margin-left: 5px;
  }
  .commentBox-list .reply .bottom {
    padding: 0;
    padding-left: 23px;
  }
  .commentBox-wrap .reply .comment-top {
    gap: 0;
  }

  .commentBox-list li > div.new .top {
    display: none;
  }
  .commentBox-list li > div.new .comment {
    width: calc(100% - 8.8%);
    margin-left: 8.8%;
    overflow: initial;
    position: relative;
  }
  .commentBox-list li > div.new .comment::before {
    content: "";
    display: block;
    width: 2.133vw;
    height: 2.133vw;
    border-left: 1px solid #afb4b2;
    border-bottom: 1px solid #afb4b2;
    position: absolute;
    top: 10px;
    left: calc(-4vw - 2.133vw);
  }
  .commentBox-wrap .comment textarea {
    font-size: 14px;
    line-height: 1.428;
  }

  .commentBox-list li > div.deleted p {
    font-size: 14px;
  }

  .commentBox-wrap .sub-wrap {
    width: 100%;
    padding-top: 40px;
    background-color: #f8f8f8;
  }
  .commentBox-wrap .list-empty .list-empty-caution {
    width: 100%;
  }
  .commentBox-wrap .list-empty .title-section::before {
    width: 22px;
    height: 22px;
    margin-bottom: 10px;
  }
  .commentBox-wrap .list-empty .defalut-section li {
    font-size: 13px;
    font-family: "NotoSansMedium";
    letter-spacing: -0.33px;
  }
  .commentBox-wrap .comment_textWrap .comment-file-wrap {
    position: relative;
    left: 0;
    bottom: -10px;
    display: inline-block;
  }
}
