.classInfo {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  border-bottom: 2px solid #eaeaea;
  position: relative;
  padding: 20px 0;
}

.classInfo button,
.classInfo button:hover,
.classInfo button:focus,
.classInfo a,
.classInfo a:hover,
.classInfo a:focus {
  -webkit-tap-highlight-color: transparent;
  color: transparent;
}
.classInfo_title {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  text-align: left;
}
.classInfo_title h2 {
  font: 24px/1.41 "NotoSansBold";
  letter-spacing: -0.6px;
  color: #1c1c1c;
  margin-bottom: 4px;
}
.classInfo_title h3 {
  font: 14px/1.71 "NotoSansMedium";
  letter-spacing: -0.35px;
  color: #666;
}

.classInfo_share_icon {
  cursor: pointer;
  color: #0e2b49;
}
.classInfo_share_icon svg {
  width: 28px;
  height: 28px;
  color: #1c1c1c;
}

.classInfo_img {
  width: 100%;
  height: 563px;
  background-color: #ebebeb;
}

.tem {
  position: absolute;
  width: 100%;
  height: 500px;
  bottom: 31%;
}
.tem2 {
  width: 100%;
  height: 100%;
}

.tem3 {
  position: absolute;
  width: 100%;
  height: 500px;
  bottom: 40px;
}

.card-item-root {
  width: 100%;
}
.magazine_slide_wrap .slide-card-items-list-type.swiper {
  max-width: 724px;
}
.magazine_slide_wrap .swiper-button-next {
  right: 19.5%;
  top: 50%;
  width: 38px;
  height: 74px;
  font-size: 0;
  z-index: 2;
}
.magazine_slide_wrap .swiper-button-prev {
  left: 19.5%;
  top: 50%;
  width: 38px;
  height: 74px;
  font-size: 0;
  z-index: 2;
}
.magazine_slide_wrap .swiper-button-prev:after {
  content: "";
  background: url(../../images/common/slide-prev-btn.png) no-repeat center
    center / 100%;
  width: 38px;
  height: 74px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.magazine_slide_wrap .swiper-button-next:after {
  content: "";
  background: url(../../images/common/slide-next-btn.png) no-repeat center
    center / 100%;
  width: 38px;
  height: 74px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.closed-noti-txt {
  margin-left: 10px;
  text-indent: -10px;
  text-align: left;
  padding: 10px 0;
  color: #222;
  line-height: 1.2;
}
.card-layout {
  max-width: 1260px;
}
.recommendClassTItle {
  width: 990px;
  font: 24px/1.41 "NotoSansBold";
  color: #111111;
  margin-top: 10px;
  text-align: left;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .classInfo_title {
    padding-bottom: 20px;
    border-bottom: 2px solid #1c1c1c;
    margin-bottom: 30px;
  }
  .classInfo_title h2 {
    font: 5.4vw/1.3 "NotoSansBold";
    margin-bottom: 10px;
  }
  .classInfo_title h3 {
    font: 3.2vw/1 "NotoSansMedium";
  }
  .recommendClassTItle {
    width: 100%;
    font-size: 18px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .recommendClassTItle {
    width: 692px;
  }
}
@media screen and (min-width: 540px) and (max-width: 767px) {
  .recommendClassTItle {
    width: 456px;
  }
}
@media screen and (min-width: 381px) and (max-width: 539px) {
  .recommendClassTItle {
    width: 325px;
  }
}

/* 매거진 상세 css 시작 */
.magazinDetail .subVisual {
  margin-bottom: 76px;
}

.magazinDetail iframe {
  width: 100%;
}

.magazinDetail .recomendClass_container {
  padding: 0 20px 0;
  /* border-bottom: 2px solid #eaeaea; */
}
/* 매거진 상세 css 끝 */
.event-detail-img {
  position: relative;
}

.eventDetail .classInfo img {
  max-width: 100%;
  /* margin: 0 auto;  */
  /* display: block; */
}

.sub-wrap.event.eventDetail.onlyLiveAll .classInfo {
  padding-top: 0;
}
.sub-wrap.event.eventDetail.onlyLiveAll .coupon-btn-box {
  width: 96%;
  height: 13%;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 18%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
}

.sub-wrap.event.eventDetail.onlyLiveAll button {
  width: 39%;
  height: 80%;
  display: flex;
  &:focus,
  &:hover {
    background-color: transparent;
    color: transparent;
  }
}

.event-link {
  font-size: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 150px;
}
.link1 {
  bottom: 30%;
  left: 50%;
}
.link2 {
  bottom: 8%;
  left: 50%;
}
.eventImg1.link2 {
  bottom: 10.5%;
}
.eventImg3.link2 {
  bottom: 11%;
}
button.event-link.MuiButton-root.eventImg4.link1 {
  bottom: 38%;
}
.eventImg4.link2 {
  bottom: 9.5%;
}
button.event-link.MuiButton-root {
  font-size: 0;
  position: absolute !important;
  bottom: 32.5%;
  height: 180px;
}
button.event-link.MuiButton-root.eventImg2 {
  bottom: 30.5%;
}
button.event-link.MuiButton-root.eventImg3.link1 {
  height: 15vw;
  left: 30%;
  transform: translateX(-50%);
  width: 38%;
  bottom: 49.5%;
}
button.event-link.MuiButton-root.eventImg3.link3 {
  height: 15vw;
  right: -7%;
  transform: translateX(-50%);
  width: 38%;
  bottom: 49.5%;
}
button.event-link.MuiButton-root.eventImg3.link1:hover,
button.event-link.MuiButton-root.eventImg3.link3 {
  background-color: transparent;
}

.live-link.link1 {
  bottom: 79%;
  left: 50%;
  font-size: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 150px;
  cursor: pointer;
  z-index: 10;
}

.live-link.link2 {
  bottom: 6.5%;
  left: 50%;
  font-size: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 150px;
  cursor: pointer;
  z-index: 10;
}
@media screen and (max-width: 1023px) {
  .classInfo {
    padding: 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
  }

  .sub-wrap.event.eventDetail {
    min-height: 100vh;
    /* padding-top: 30px; */
  }

  .sub-wrap.event.readingBook {
    padding: 0 25px;
    min-height: 100vh;
  }
  .sub-wrap.magazine.magazinDetail {
    padding: 0 0 50px;
    min-height: 100vh;
  }
  .sub-wrap.event.eventDetail.onlyLiveAll {
    padding: 60px 0 0 0;
    min-height: 100vh;
  }

  .header + .sub-wrap.event.eventDetail.onlyLiveAll {
    padding: 105px 0px 60px !important;
  }

  .header.mo-header.top-banner + .sub-wrap.event.eventDetail.onlyLiveAll {
    padding: 153px 0px 60px !important;
  }

  .classInfo_title {
    height: auto;
    padding: 20px 0;
    border-bottom: 2px solid #1c1c1c;
    margin-bottom: 0;
    position: relative;
  }
  .classInfo_title h2 {
    font: 20px/1.2 "NotoSansBold";
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .classInfo_title h3 {
    font: 12px/1.5 "NotoSansMedium";
  }
  .classInfo_share_icon {
    position: absolute;
    right: 0;
    bottom: 20px;
  }
  .classInfo_share_icon svg {
    width: 16px;
    height: 18px;
    font-size: 1rem;
  }
  .classInfo_img {
    height: 100%;
    min-height: 180px;
  }

  .sub-wrap.magazine.magazinDetail .classInfo {
    width: auto;
    margin: 0 25px;
    border-bottom: 2px solid #eaeaea;
  }
  .magazinDetail .recomendClass_container {
    border-bottom: 0;
    padding-top: 16px;
    padding-bottom: 10px;
  }
  .eventDetail .classInfo iframe {
    width: 100%;
    height: 53vw;
  }
  /* .classInfo_title h2 {
        font: 5.4vw/1.3 'NotoSansBold';
        margin-bottom: 10px;
    }
    .classInfo_title h3 {
        font: 3.2vw/1 'NotoSansMedium';
    } */
  .magazine_slide_wrap .slide-card-items-list-type.swiper {
    width: 100%;
  }
  .magazine_slide_wrap .swiper-button-prev {
    left: -15px;
    top: 60%;
    width: 25px;
    height: 50px;
  }
  .magazine_slide_wrap .swiper-button-next {
    right: -16px;
    top: 60%;
    width: 25px;
    height: 50px;
  }
  .tem {
    bottom: 24%;
  }

  .sub-wrap.event.eventDetail.onlyLiveAll .coupon-btn-box {
    width: 100%;
    bottom: 19%;
  }
  .sub-wrap.event.eventDetail.onlyLiveAll .coupon-btn-box button {
    width: 42%;
  }

  .event-link {
    height: 30vw;
  }
  .link1 {
    bottom: 37%;
    left: 50%;
  }
  .link2 {
    bottom: 12.5%;
    left: 50%;
  }

  button.event-link.MuiButton-root {
    bottom: 39%;
  }
  .eventImg1.link2 {
    bottom: 13.5%;
    left: 50%;
  }
  .eventImg3.link2 {
    bottom: 13%;
  }
  button.event-link.MuiButton-root.eventImg4.link1 {
    bottom: 39%;
    height: 30vw;
  }
  .eventImg4.link2 {
    bottom: 15%;
  }
  button.event-link.MuiButton-root.eventImg2 {
    bottom: 41%;
    height: 50vw;
  }
  button.event-link.MuiButton-root.eventImg3.link1 {
    bottom: 52.6%;
    height: 16vw;
    width: 100%;
    left: auto;
    transform: translate(-50%, -50%);
  }
  button.event-link.MuiButton-root.eventImg3.link3 {
    bottom: 50.6%;
    height: 17vw;
    width: 100%;
    left: auto;
    right: auto;
    transform: translate(-50%, -50%);
  }

  .live-link.link1 {
    bottom: 85%;
    height: 30vw;
  }

  .live-link.link2 {
    bottom: 9.3%;
    height: 30vw;
  }
  .magazinDetail .classInfo iframe {
    width: 100%;
    height: 53vw;
  }
}

.magazinDetail .classInfo img {
  max-width: 100%;
}

/* @media screen and (min-width: 1024px) and (max-width: 1260px)  {
 
    .sub-wrap.magazine.magazinDetail .classInfo,
    .sub-wrap.event.eventDetail.onlyLiveAll .classInfo,
    .sub-wrap.event.eventDetail .classInfo {padding: 20px 15px;}
} */

/* @media screen and (max-width: 749px)  {
    .sub-wrap.event.eventDetail.onlyLiveAll {
        padding: 80px 0 0 0;
    }
}
 */
