.nav-search-box {
    position: absolute;
    top: 50%; left: 50%; transform: translate(-50%, -50%);
    width: 440px; height: 40px;

    .search-input {
        display: block;
        height: 100%;

        > div {
            display: block;
            height: 100%;
            padding: 0 60px 0 24px;
            background-color: #f8f8f8;
            border: 2px solid transparent;
            border-radius: 20px;
            transition: all 0.15s;

            &::before {
                display: none;
            }
            &::after {
                display: none;
            }

            > input {
                height: 100%;
                padding: 0;
                color: #1c1c1c;
                font-family: 'NotoSansBold';
                font-size: 14px;

                &:focus {
                    border: 0 none;
                }

            }
        }
    }

    .search-btn {
        position: absolute;
        top: 8px; right: 16px;
        width: 24px; height: 24px;

        img {
            width: 100%; height: 100%;
        }
    }
}

.search-result-wrap {
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 101;
    width: 100%;
    padding: 14px 0 12px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);

    .tit {
        margin-bottom: 15px;
        padding: 0 23px;
        color: #1c1c1c;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
    }
    
    .rank li {
        text-align: left;
        a {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            height: 40px;
            padding: 0 23px 0 60px;
            color: #666;
            font-size: 14px;

            &:hover {
                background-color: #f8f8f8;
            }
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 23px;
                top: 8px;
                width: 24px;
                height: 24px;
                background: #e0e0e0 url('../../images/common/search-icon-w@2x.png') center/16px no-repeat;
                border-radius: 50%;
            }
        }
    }
    .close {
        position: absolute;
        top: 12px; right: 16px;
        min-width: 0;
        width: 25px; height: 25px;
        border-radius: 50%;
        color: #afb4b2;
        font-size: 0;
        opacity: 1;

        &:hover {
            background-color: transparent;
        }
    }
}

@media screen and (max-width: 1023px) {
    .search-result-wrap {
        border-radius: 0;
        border-width: 0;
        min-height: 76vh;
        overflow-y: auto;
        position: static;
    }
}