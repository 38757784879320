input:focus,
.Mui-focused {
  outline: none;
  border: inherit;
}

.userInfoWrap .MuiGrid-root.userInput_wrap {
  border-bottom: none;
  padding: 0;
}
.MuiFormControl-root.userInfoWrap {
  max-width: 645px;
  margin: 0 auto;
  padding-bottom: 52px;
  border-bottom: 2px solid #eaeaea;
}
.MuiFormControl-root.userInfoWrap:not(:first-child) {
  margin: 40px auto 0;
}
.userInfoWrap .userNum + .userInput_wrap {
  width: 100%;
  align-items: center;
  text-align: left;
  margin: 0;
}

.required-mark {
  position: relative;
}
.required-mark::after {
  content: "*";
  color: #ff5651;
  position: absolute;
  top: -4px;
  right: -10px;
  font-family: "NotoSansBlack";
}

.btnDelete {
  width: 62px;
  height: 32px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #1c1c1c;
  text-align: center;
  font: 14px/30px "NotoSansBold";
  letter-spacing: -0.35px;
  color: #f04d4d;
}

.grid-select
  .MuiFormControl-root
  ul.css-6hp17o-MuiList-root-MuiMenu-list
  li.MuiButtonBase-root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.MuiFormControl-root.birthSelectWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* z-index: 100000; */
  /* gap: 14px; */
}
.MuiFormControl-root.birthSelectWrap .comboBox-type1 {
  width: 33.3%;
  height: 58px;
  margin-right: 14px;
}
.MuiFormControl-root.birthSelectWrap .comboBox-type1:last-child {
  margin-right: 0;
}
.MuiFormControl-root.birthSelectWrap .comboBox-type1 + div {
  margin-right: 14px;
}
.register_container select.select-type-s {
  border: solid 1px #b0b4b2;
  font-size: 16px;
  width: 33.3%;
  height: 58px;
}
.register_container select.select-type-s option {
  font-family: "NotoSansMedium";
  font-size: 14px;
  color: #222;
}
.userInput_wrap > .MuiGrid-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.userInput_wrap > .MuiGrid-item.top {
  align-items: flex-start;
}
.userInput_wrap > .MuiGrid-item.top .input_title {
  padding-top: 10px;
}
.MuiTypography-body1.input_title {
  font-size: 16px;
  font-family: "NotoSansBold";
  line-height: 2.1;
  color: #1c1c1c;
}

.profile_noteWrapper {
  position: relative;
}
textarea.profile_note {
  resize: none;
  width: 100%;
  height: 130px;
  color: #222;
  border: 1px solid #b0b4b2;
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  font-size: 16px;
  letter-spacing: -0.4px;
  overflow-y: auto;
}
textarea.profile_note::-webkit-input-placeholder {
  color: #afb4b2;
  font-size: 16px;
  letter-spacing: -0.4px;
}
textarea.profile_note::-moz-placeholder {
  color: #afb4b2;
  font-size: 16px;
  letter-spacing: -0.4px;
}
textarea.profile_note:-ms-input-placeholder {
  color: #afb4b2;
  font-size: 16px;
  letter-spacing: -0.4px;
}
textarea.profile_note::-ms-input-placeholder {
  color: #afb4b2;
  font-size: 16px;
  letter-spacing: -0.4px;
}
textarea.profile_note::placeholder {
  color: #afb4b2;
  font-size: 16px;
  letter-spacing: -0.4px;
}
textarea.profile_note::-webkit-scrollbar {
  width: 4px;
}
textarea.profile_note::-webkit-scrollbar-thumb {
  width: 4px;
  background: #eaeaea;
  border-radius: 2px;
}
textarea.profile_note::-webkit-scrollbar-track {
  background-color: transparent;
}
.profile_note_len {
  position: absolute;
  right: 18px;
  bottom: 18px;
  font-family: "NotoSansRegular";
  font-size: 13px;
  letter-spacing: -0.33px;
  color: #afb4b2;
}

.userInput_wrap > .MuiGrid-item.profile_imgWrapper {
  flex-wrap: wrap;
  gap: 7px;
}
.userInfo_item .profile_img {
  width: calc((100% - 7px * 5) / 6);
  height: auto;
  border-radius: 50%;
  overflow: inherit;
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;
}
.userInfo_item .profile_img.isOn {
  border-color: #0e2b49;
  box-sizing: border-box;
}
.userInfo_item .profile_img img {
  width: 100%;
  height: 100%;
  display: block;
}
.userInfo_item .profile_img .chk {
  position: absolute;
  top: 71%;
  left: 71%;
  z-index: 2;
  display: none;
  width: 20px;
  height: 20px;
}
.userInfo_item .profile_img.isOn .chk {
  display: block;
}

.userInfo_item input[type="text"]::-webkit-input-placeholder,
.userInfo_item textarea::-webkit-input-placeholder {
  font: 16px/1 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #afb4b2;
  opacity: 1;
}

.userInfo_item input[type="text"]::-moz-placeholder,
.userInfo_item textarea::-moz-placeholder {
  font: 16px/1 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #afb4b2;
  opacity: 1;
}

.userInfo_item input[type="text"]:-ms-input-placeholder,
.userInfo_item textarea:-ms-input-placeholder {
  font: 16px/1 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #afb4b2;
  opacity: 1;
}

.userInfo_item input[type="text"]::-ms-input-placeholder,
.userInfo_item textarea::-ms-input-placeholder {
  font: 16px/1 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #afb4b2;
  opacity: 1;
}

.userInfo_item input[type="text"]::placeholder,
.userInfo_item textarea::placeholder {
  font: 16px/1 "NotoSansRegular";
  letter-spacing: -0.4px;
  color: #afb4b2;
  opacity: 1;
}

@media screen and (min-width: 1024px) {
  .userInfo_item.item_title.MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }
  .item_info.MuiGrid-grid-md-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
  }
  .MuiFormControl-root.userInfoWrap .table-list-type-1 td.MuiTableCell-root {
    width: 68%;
  }
}

@media screen and (max-width: 1023px) {
  .sub-wrap.register_container {
    padding: 0 0 50px;
  }
  .userInfo_item.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
  .MuiTypography-body1.input_title {
    font-size: 14px;
    line-height: 1;
  }
  .required-mark::after {
    top: 3px;
  }
  .MuiFormControl-root.birthSelectWrap {
    /* gap: 12px; */
  }
  .register_container select.select-type-s {
    height: 44px;
    font-size: 14px;
  }
  .userInfo_item input[type="text"]::-webkit-input-placeholder,
  .userInfo_item textarea::-webkit-input-placeholder {
    font-size: 14px;
  }
  .userInfo_item input[type="text"]::-moz-placeholder,
  .userInfo_item textarea::-moz-placeholder {
    font-size: 14px;
  }
  .userInfo_item input[type="text"]:-ms-input-placeholder,
  .userInfo_item textarea:-ms-input-placeholder {
    font-size: 14px;
  }
  .userInfo_item input[type="text"]::-ms-input-placeholder,
  .userInfo_item textarea::-ms-input-placeholder {
    font-size: 14px;
  }
  .userInfo_item input[type="text"]::placeholder,
  .userInfo_item textarea::placeholder {
    font-size: 14px;
  }
  .radioGrid > div > div label {
    height: auto;
  }
  .radioGrid .radio_item span:last-child p {
    line-height: 44px;
    font-size: 11px;
  }
  textarea.profile_note {
    padding: 15px 15px 30px;
    font-size: 14px;
  }
  .profile_note_len {
    font-size: 12px;
    right: 10px;
    bottom: 10px;
  }
  .userInput_wrap > .MuiGrid-item.profile_imgWrapper {
    gap: 3vw;
  }
  .userInfo_item .profile_img {
    width: calc((100% - 3vw * 3) / 4);
  }
  .userInfo_item .profile_img .chk {
    width: 29.94%;
    height: 29.94%;
    min-width: 13px;
    min-height: 13px;
  }

  .userInput_wrap .PrivateNotchedOutline-root-3 {
    height: 44px;
    top: 0;
  }
  .userInput_wrap .PrivateNotchedOutline-root-3 {
    top: 0;
  }
  .userInput_wrap .MuiOutlinedInput-input {
    padding: 16px;
    font-family: NotoSansRegular, Lato, sans-serif;
  }
  .userInput_wrap .MuiOutlinedInput-input + fieldset {
    top: 0;
  }
  .profile_more_wrap {
    padding-top: 40px;
  }
  .profile_more_wrap fieldset {
    height: 44px;
    top: 0;
  }
  .profile_more_wrap input {
    padding: 12px 16px;
  }
  .MuiFormControl-root.birthSelectWrap .comboBox-type1 {
    height: 51px;
    margin-right: 12px;
  }
  .MuiFormControl-root.birthSelectWrap .comboBox-type1 + div {
    margin-right: 12px;
  }

  .userInfoWrap .table-container.MuiTableContainer-root {
    margin: 0;
    width: 100%;
  }
  .MuiFormControl-root.userInfoWrap .table-list-type-1 th.MuiTableCell-root {
    padding: 11px;
    font-size: 13px;
    width: 112px;
    min-width: auto;
  }
  .MuiFormControl-root.userInfoWrap .table-list-type-1 td.MuiTableCell-root {
    padding: 11px;
    font-size: 13px;
    width: 82%;
  }
  .MuiFormControl-root.userInfoWrap
    .table-list-type-1
    td.MuiTableCell-root.email-row {
    padding: 11px 0 11px 11px;
  }
  .btnDelete {
    width: auto;
    height: 25px;
    min-width: 50px;
    font: 12px/1.2 "NotoSansMedium";
  }
}

@media screen and (max-width: 768px) {
  .MuiFormControl-root.userInfoWrap .table-list-type-1 td.MuiTableCell-root {
    padding: 11px;
    font-size: 13px;
    width: 77%;
  }

  .MuiFormControl-root.birthSelectWrap .comboBox-type1 .MuiSelect-select {
    font-size: 12px;
  }
}
