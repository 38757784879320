$c-pc1: #f5f5f5;
$c-pc2: #eaeaea;
$c-pc3: #222222;
$c-pc4: #f6f2e6;

$c-ac1: #c9adef;
$c-ac2: #84d7bd;
$c-ac3: #ffabc9;
$c-ac4: #a0cffa;
$c-ac5: #fbc831;
$c-ac6: #1499ed;
$c-ac7: #7239eb;
$c-ac8: #ff5651;
$c-ac9: #5abd62;

$c-sc1: #afb4b2;
$c-sc2: #666666;
$c-sc3: #4e6883;
$c-sc4: #1c1c1c;

// $bg-pc1: #F5F5F5;
// $bg-pc2: #EAEAEA;
// $bg-pc3: #222222;
// $bg-pc4: #F6F2E6;

// $bg-ac1: #C9ADEF;
// $bg-ac2: #84D7BD;
// $bg-ac3: #FFABC9;
// $bg-ac4: #A0CFFA;
// $bg-ac5: #FBC831;
// $bg-ac6: #1499ED;
// $bg-ac7: #7239EB;
// $bg-ac8: #FF5651;
// $bg-ac9: #5ABD62;

// $bg-sc1: #AFB4B2;
// $bg-sc2: #666666;
// $bg-sc3: #4E6883;
// $bg-sc4: #0E2B49;

.c-pc1 {
    color: #f5f5f5 !important;
}
.c-pc2 {
    color: #eaeaea !important;
}
.c-pc3 {
    color: #222222 !important;
}
.c-pc4 {
    color: #f6f2e6 !important;
}

.c-ac1 {
    color: #c9adef !important;
}
.c-ac2 {
    color: #84d7bd !important;
}
.c-ac3 {
    color: #ffabc9 !important;
}
.c-ac4 {
    color: #a0cffa !important;
}
.c-ac5 {
    color: #fbc831 !important;
}
.c-ac6 {
    color: #1499ed !important;
}
.c-ac7 {
    color: #7239eb !important;
}
.c-ac8 {
    color: #ff5651 !important;
}
.c-ac9 {
    color: #5abd62 !important;
}

.c-sc1 {
    color: #afb4b2 !important;
}
.c-sc2 {
    color: #666666 !important;
}
.c-sc3 {
    color: #4e6883 !important;
}
.c-sc4 {
    color: #1c1c1c !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/*  text 정렬 */
.tl {
    text-align: left !important;
}
.tc {
    text-align: center !important;
}
.tr {
    text-align: right !important;
}
.tj {
    text-align: justify !important;
}

/*margin-top*/
.mt5_ {
    margin-top: -5px !important;
}
.mt0 {
    margin-top: 0px !important;
}
.mt2 {
    margin-top: 2px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt6 {
    margin-top: 6px !important;
}
.mt7 {
    margin-top: 7px !important;
}
.mt8 {
    margin-top: 8px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt25 {
    margin-top: 25px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt35 {
    margin-top: 35px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt45 {
    margin-top: 45px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt55 {
    margin-top: 55px !important;
}
.mt60 {
    margin-top: 60px !important;
}
.mt65 {
    margin-top: 65px !important;
}
.mt70 {
    margin-top: 70px !important;
}
.mt80 {
    margin-top: 80px !important;
}
.mt90 {
    margin-top: 90px !important;
}
.mt100 {
    margin-top: 100px !important;
}
.mt120 {
    margin-top: 100px !important;
}
.mt150 {
    margin-top: 150px !important;
}

/*margin-bottom*/
.mb0 {
    margin-bottom: 0px !important;
}
.mb5 {
    margin-bottom: 5px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb15 {
    margin-bottom: 15px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb25 {
    margin-bottom: 25px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb35 {
    margin-bottom: 35px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb45 {
    margin-bottom: 45px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb55 {
    margin-bottom: 55px !important;
}
.mb60 {
    margin-bottom: 60px !important;
}
.mb65 {
    margin-bottom: 65px !important;
}
.mb70 {
    margin-bottom: 70px !important;
}
.mb80 {
    margin-bottom: 80px !important;
}
.mb85 {
    margin-bottom: 85px !important;
}
.mb90 {
    margin-bottom: 90px !important;
}
.mb100 {
    margin-bottom: 100px !important;
}
.mb130 {
    margin-bottom: 130px !important;
}
.mb150 {
    margin-bottom: 150px !important;
}
.mb160 {
    margin-bottom: 160px !important;
}
.mb250 {
    margin-bottom: 250px !important;
}

/*margin-left*/
.ml0 {
    margin-left: 0px !important;
}
.ml5 {
    margin-left: 5px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml12 {
    margin-left: 12px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml26 {
    margin-left: 26px !important;
}
.ml30 {
    margin-left: 30px !important;
}
.ml40 {
    margin-left: 40px !important;
}
.ml45 {
    margin-left: 45px !important;
}
.ml52 {
    margin-left: 52px !important;
}
.ml112 {
    margin-left: 112px !important;
}

/*margin-right*/
.mr0 {
    margin-right: 0px !important;
}
.mr5 {
    margin-right: 5px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mr18 {
    margin-right: 18px !important;
}
.mr20 {
    margin-right: 20px !important;
}
.mr25 {
    margin-right: 25px !important;
}
.mr30 {
    margin-right: 30px !important;
}
.mr40 {
    margin-right: 40px !important;
}
.mr90 {
    margin-right: 90px !important;
}
.mr95 {
    margin-right: 95px !important;
}
.mr112 {
    margin-right: 112px !important;
}
.mr331 {
    margin-right: 331px !important;
}

/*paddding-top*/
.pt0 {
    padding-top: 0px !important;
}
.pt10 {
    padding-top: 10px !important;
}
.pt12 {
    padding-top: 12px !important;
}
.pt15 {
    padding-top: 15px !important;
}
.pt20 {
    padding-top: 20px !important;
}
.pt25 {
    padding-top: 25px !important;
}
.pt30 {
    padding-top: 30px !important;
}
.pt35 {
    padding-top: 35px !important;
}
.pt40 {
    padding-top: 40px !important;
}
.pt45 {
    padding-top: 45px !important;
}
.pt50 {
    padding-top: 50px !important;
}
.pt55 {
    padding-top: 55px !important;
}
.pt60 {
    padding-top: 60px !important;
}
.pt65 {
    padding-top: 65px !important;
}
.pt70 {
    padding-top: 70px !important;
}
.pt75 {
    padding-top: 75px !important;
}
.pt80 {
    padding-top: 80px !important;
}
.pt85 {
    padding-top: 85px !important;
}
.pt90 {
    padding-top: 90px !important;
}
.pt95 {
    padding-top: 95px !important;
}
.pt100 {
    padding-top: 100px !important;
}

/*padding-bottom*/
.pb0 {
    padding-bottom: 0px !important;
}
.pb5 {
    padding-bottom: 5px !important;
}
.pb10 {
    padding-bottom: 10px !important;
}
.pb20 {
    padding-bottom: 20px !important;
}
.pb29 {
    padding-bottom: 29px !important;
}
.pb30 {
    padding-bottom: 30px !important;
}
.pb35 {
    padding-bottom: 35px !important;
}
.pb40 {
    padding-bottom: 40px !important;
}
.pb50 {
    padding-bottom: 50px !important;
}
.pb60 {
    padding-bottom: 60px !important;
}
.pb100 {
    padding-bottom: 100px !important;
}

.pl0 {
    padding-left: 0px !important;
}
.pl20 {
    padding-left: 20px !important;
}
.pl25 {
    padding-left: 25px !important;
}
.pl30 {
    padding-left: 30px !important;
}
.pl60 {
    padding-left: 60px !important;
}

.pr0 {
    padding-right: 0px !important;
}
.pr10 {
    padding-right: 10px !important;
}
.pr20 {
    padding-right: 20px !important;
}
.pr30 {
    padding-right: 30px !important;
}

.p0 {
    padding: 0 !important;
}
.p10 {
    padding: 10px !important;
}
.plr10 {
    padding: 0 10px !important;
}

.w10 {
    width: 10px !important;
}
.w20 {
    width: 20px !important;
}
.w30 {
    width: 30px !important;
}
.w40 {
    width: 40px !important;
}
.w50 {
    width: 50px !important;
}
.w52 {
    width: 52px !important;
}
.w53 {
    width: 53px !important;
}
.w60 {
    width: 60px !important;
}
.w70 {
    width: 70px !important;
}
.w75 {
    width: 75px !important;
}
.w80 {
    width: 80px !important;
}
.w81 {
    width: 81px !important;
}
.w85 {
    width: 85px !important;
}
.w88 {
    width: 88px !important;
}
.w90 {
    width: 90px !important;
}
.w100 {
    width: 100px !important;
}
.w110 {
    width: 110px !important;
}
.w120 {
    width: 120px !important;
}
.w130 {
    width: 130px !important;
}
.w140 {
    width: 140px !important;
}
.w150 {
    width: 150px !important;
}
.w160 {
    width: 160px !important;
}
.w170 {
    width: 170px !important;
}
.w180 {
    width: 180px !important;
}
.w190 {
    width: 190px !important;
}
.w200 {
    width: 200px !important;
}
.w220 {
    width: 220px !important;
}
.w230 {
    width: 230px !important;
}
.w270 {
    width: 270px !important;
}
.w280 {
    width: 280px !important;
}
.w295 {
    width: 295px !important;
}
.w300 {
    width: 300px !important;
}
.w310 {
    width: 310px !important;
}
.w345 {
    width: 345px !important;
}
.w350 {
    width: 350px !important;
}
.w360 {
    width: 360px !important;
}
.w370 {
    width: 370px !important;
}
.w384 {
    width: 384px !important;
}
.w390 {
    width: 390px !important;
}
.w410 {
    width: 410px !important;
}
.w500 {
    width: 500px !important;
}
.w530 {
    width: 530px !important;
}
.w552 {
    width: 552px !important;
}
.w590 {
    width: 590px !important;
}
.w660 {
    width: 660px !important;
}
.w860 {
    width: 860px !important;
}
.w996 {
    width: 996px !important;
}

.w10p {
    width: 10% !important;
}
.w20p {
    width: 20% !important;
}
.w30p {
    width: 30% !important;
}
.w40p {
    width: 40% !important;
}
.w50p {
    width: 50% !important;
}
.w60p {
    width: 60% !important;
}
.w70p {
    width: 70% !important;
}
.w75p {
    width: 75% !important;
}
.w80p {
    width: 80% !important;
}
.w90p {
    width: 90% !important;
}
.w95p {
    width: 95% !important;
}
.w100p {
    width: 100% !important;
}

.border-none {
    border: none !important;
}

/**** 폰트 가이드 ****/
.font-kr-light {
    font-family: 'NotoSansLight', sans-serif !important;
}
.font-kr-regular {
    font-family: 'NotoSansRegular', sans-serif !important;
}
.font-kr-medium {
    font-family: 'NotoSansMedium', sans-serif !important;
}
.font-kr-bold {
    font-family: 'NotoSansBold', sans-serif !important;
}

/**** 셀렉트 박스 기본 타입 ****/
select::-ms-expand {
    display: none;
}
select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/**** 셀렉트 박스 s 사이즈 ****/
.comboBox-type1 {
    width: 150px;
    height: 40px;
    font-family: 'NotoSansRegular' !important;
    .MuiOutlinedInput-notchedOutline {
        top: 0;
    }
    &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-width: 1px !important;
            border-color: #b0b4b2 !important;
        }
    }

    .MuiSelect-select {
        padding: 2px 29px 2px 16px !important;
        text-align: left;
        font-size: 14px;
        // font-size: 16px;
        // font-weight: bold;
        color: #222;
    }
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        fill: #222;
    }
}
.MuiMenu-paper {
    margin-top: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 10px !important;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1) !important;
}
// .MuiPopover-root{
//     z-index: 9; //2024-02-23 드랍다운 z-index 추가
// }
.MuiMenu-list {
    overflow-y: auto;
    // max-height: 280px;
    padding-top: 0;
    padding-bottom: 0;
    .MuiMenuItem-root {
        font-size: 16px;      
        font-family: 'NotoSansRegular';
        color: #555;
        background: none;
        // 2022-07-27 셀렉트 박스 중복 방지 css 추가
            text-decoration: none;
            min-height: 48px;
            padding-top: 6px;
            padding-bottom: 6px;
            box-sizing: border-box;
            white-space: nowrap;
            padding-left: 16px;
            padding-right: 16px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            /* -webkit-box-pack: start; */
            -ms-flex-pack: start;
            /* -webkit-justify-content: flex-start; */
            justify-content: flex-start;
            /* -webkit-align-items: center; */
            /* -webkit-box-align: center; */
            -ms-flex-align: center;
            align-items: center;
        //////  

        &.Mui-focusVisible,
        &.Mui-selected {
            background: #f8f8f8 !important;
            // color: #222;
        }
        &:hover {
            background-color: #f8f8f8;
            // color: #222;
        }
    }
}

select.select-type-s {
    border: solid 1px #afb4b2;
    background: #fff url(../images/common/drop_icon3.png) no-repeat 90% center;
    background-size: 14px 12px;
    padding: 10px 30px 10px 16px;
    border-radius: 5px;
    transition: border-color 0.3s ease-out;
    font-family: 'NotoSansMedium';
    font-size: 14px;
    color: #222;
}

select.select-type-s option {
    font-family: 'NotoSansMedium';
    font-size: 14px;
    color: #222;
}

.select-type-m select.select-type-s {
    background: #fff url(../images/common/drop_icon3.png) no-repeat 94% center;
    background-size: 14px 12px;
    width: 350px;
    height: 60px;
}

/* 태그 리스트 원데이 / 패키지 / 연령 시작 */
.tag-list {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0;
}
.tag-list li {
    position: relative;
    font-size: 12px;
    font-family: 'NotoSansBold';
    padding: 0 15px 0 0;
    text-align: center;
    color: #1c1c1c;
}
.tag-list li::after {
    content:"";
    position: absolute;
    width: 1px;
    height: 8px;
    right: 6px;
    bottom: 1px;
    background-color: #1c1c1c;
}
.tag-list li:last-child::after {
    content: none;
}

/* 폰트 디테일 별 가이드 */
.fs10 {
    font-size: 10px !important;
}
.fs11 {
    font-size: 11px !important;
}
.fs12 {
    font-size: 12px !important;
}
.fs13 {
    font-size: 13px !important;
}
.fs14 {
    font-size: 14px !important;
}
.fs15 {
    font-size: 15px !important;
}
.fs16 {
    font-size: 16px !important;
}
.fs17 {
    font-size: 17px !important;
}
.fs18 {
    font-size: 18px !important;
}
.fs19 {
    font-size: 19px !important;
}
.fs20 {
    font-size: 20px !important;
}
.fs21 {
    font-size: 21px !important;
}
.fs22 {
    font-size: 22px !important;
}
.fs23 {
    font-size: 23px !important;
}
.fs24 {
    font-size: 24px !important;
}
.fs25 {
    font-size: 25px !important;
}
.fs26 {
    font-size: 26px !important;
}
.fs27 {
    font-size: 27px !important;
}
.fs28 {
    font-size: 28px !important;
}
.fs29 {
    font-size: 29px !important;
}
.fs30 {
    font-size: 30px !important;
}
.fs35 {
    font-size: 35px !important;
}
.fs40 {
    font-size: 40px !important;
}
.fs45 {
    font-size: 45px !important;
}
.fs50 {
    font-size: 50px !important;
}

.ov {
    overflow: hidden;
}

/* 
  라디오 선택 버튼 기본 
  감싸주는 div 태그 에 select-radio 클래스 추가 할 것
*/
.radio-wrap.MuiFormGroup-root .MuiRadio-root {
    color: #9f9f9f;
}
.radio-wrap.MuiFormGroup-root .MuiRadio-root.Mui-checked {
    color: #9f9f9f;
}
.radio-wrap.MuiFormGroup-root .MuiRadio-root.Mui-checked .MuiSvgIcon-root:last-child {
    color: #ff5651;
}
.radio-wrap.MuiFormGroup-root .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}

/* 
  기본 인풋 체크박스 커스텀 - 1

  <input
      type="checkbox"
      id="chk1"
      name="terms"
      onChange={handleChange2}
      checked={checked[0]}
      className="check-radio"
  /> 
  <label htmlFor="chk1">
      결제자 정보와 동일
  </label>
  
*/
input.check-radio[type='checkbox'] {
    margin-right: 0;
    margin-left: 10px;
    display: none;
}
input.check-radio[type='checkbox'] + label {
    position: relative;
    padding-left: 26px;
    margin-left: 10px;
}

input.check-radio[type='checkbox'] + label::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #9f9f9f;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #fff;
}

input.check-radio[type='checkbox'] + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid transparent;
    background: url('../images/icon/check_off.png') no-repeat center/12px;
}
.check-radio + input[type='checkbox']:checked + label::before {
    background: #1c1c1c;
    border-color: #1c1c1c;
}
.check-radio + input[type='checkbox']:checked + label::after {
    background: url('../images/icon/check_on.png') no-repeat center/12px;
}
.check-radio + label {
    position: relative;
    padding-left: 6px;
}

/* mui 기본 체크박스 커스텀 -1 check-box

<FormControlLabel
    className="check-box"
    value="end"
    control={<Checkbox color="primary" />}
    label="결제자 정보와 동일"
    labelPlacement="결제자 정보와 동일"
/>

*/
.check-box.MuiFormControlLabel-root {
	margin-right: 0;
	span.MuiCheckbox-root {padding: 9px;}//2022-07-15 확인
}
.check-box.MuiFormControlLabel-root span.MuiCheckbox-root input[type='checkbox'],
.check-box.MuiFormControlLabel-root .MuiSvgIcon-root {
    display: none;
}

.check-box .MuiCheckbox-root + .MuiTypography-root {
    position: relative;
    padding-left: 6px;
    font-family: 'NotoSansRegular';
    font-size: 14px;
    text-align: left;
}
.check-box.MuiFormControlLabel-root .MuiCheckbox-root::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #9f9f9f;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #fff;
}
.check-box.MuiFormControlLabel-root .MuiCheckbox-root::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid transparent;
    background: url('../images/icon/check_off.png') no-repeat center/12px;
}
.check-box.MuiFormControlLabel-root span.Mui-checked::before {
    background: #2f99f4;
    border-color: #2f99f4;
}
.check-box.MuiFormControlLabel-root span.Mui-checked::after {
    background: url('../images/icon/check_on.png') no-repeat center/12px;
}

/* IE의 경우 */
input::-ms-clear,
input::-ms-reveal {
    display: none;
}
/* 크롬의 경우 */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
    display: none;
}

/* 
    회원가입( EX: 남자 || 여자 선택 버튼 ) , 
   주문결제( EX: 학습자 선택, 결제수단 선택 ) ,
   클래스 상세 시간 선택 
   라디오 타입 박스 css
*/
.radioGrid .radio_item span:first-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 15px;
    height: 15px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
}
.radioGrid > div > div {
    flex-wrap: nowrap;
    flex-direction: initial;
}
.radioGrid > div > div label {
    margin-left: 0;
    margin-right: 8px;
    justify-content: center;
    width: 50%;
    height: 60px;
}

.radioGrid > div,
.radioGrid .radio_item span:last-child {
    width: 100%;
}
.radioGrid .radio_item span:last-child p {
    background: transparent;
    border-radius: 5px;
    border: 1px solid #1c1c1c;
    text-align: center;
    width: 100%;
    height: 100%;
    line-height: 3.5;
    color: #1c1c1c;
    font-family: 'NotoSansMedium';
}
.radioGrid .radio_item:last-child {
    margin-right: 0;
}
.radioGrid .radio_item span.Mui-checked + .MuiTypography-root p {
    background: #2f99f4;
    border: 1px solid #2f99f4;
    color: #fff;
    font-family: 'NotoSansRegular';
}
/* ////////////////////////////// */

p.MuiTypography-body1 {
    font-family: 'NotoSansRegular', sans-serif;
}

span.desc-m {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #1c1c1c;
    margin: 0 10px 0 0;
    vertical-align: middle;
}
.img img {
    width: 100%;
    height: 100%;
}

.h4-bold.MuiTypography-h4 {
    font-size: 24px;
    color: #1c1c1c;
    font-family: 'NotoSansBold';
}

.h5-bold.MuiTypography-h5 {
    font-size: 18px;
    color: #1c1c1c;
    font-family: 'NotoSansBold';
}

.h5-medium.MuiTypography-h5 {
    font-size: 14px;
    font-family: 'NotoSansMedium';
    color: #1c1c1c;
}

/*  박스 버튼 타입 가이드 

<Button className="download-btn button-square-type btn-color-type2" variant="outlined">
    자료 다운로드
</Button >


*/

.button-square-type.MuiButton-contained:hover {
    background: #1c1c1c;
    border: 2px solid #1c1c1c;
}
.button-square-type.MuiButton-outlined:hover {
    background: #fff;
    border: 2px solid #1c1c1c;
}

.btn-color-type1.MuiButton-contained.MuiButton-root {
    background: #2f99f4;
    border: 2px solid #2f99f4;
    color: #fff;
}

.btn-color-type1.MuiButton-outlined {
    background-color: #fff;
    border: 1px solid #2f99f4;
    color: #2f99f4;
}

.btn-color-type2.MuiButton-outlined {
    background: #fff;
    color: #1c1c1c;
}
.btn-color-type3.MuiButton-contained.MuiButton-root,
.btn-color-type3.MuiButton-contained.MuiButton-root:hover {
    color: #1c1c1c;
    background: #f8ce40;
    border: 2px solid #f8ce40;
}
// .btn-color-type3.MuiButton-outlined {
//     background: #fff;
//     color: #666;
// }

.btn-bdr-type1.MuiButton-outlined {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #afb4b2;
    padding: 6px 12px 6px 40px;
    font-size: 14px;
}
.btn-bdr-type1.MuiButton-outlined:hover {
    border: 1px solid #afb4b2;
}

.button-square-type.MuiButton-root {
    display: inline-block;
    border-radius: 6px;
    border: 1px solid #1c1c1c;
    width: 170px;
    height: 60px;
    margin-right: 10px;
    font-size: 18px;
    letter-spacing: -0.4px;
    font-family: 'NotoSansBold';
}
/*////////////////////////////////////*/

/*
  조회 필터 박스 && 아이템
*/
.filter-section {margin-bottom: 30px;}
.filter-box {
	background: #fdf6ea;
	border: 1px solid #1c1c1c;
	padding: 20px 30px;
	position: relative;
	.filter-item {
		display: flex;
		align-items: center;
		&.filter-top {
			.radioGrid.MuiGrid-root{display: flex;}
		}
	}
	.radioGrid {
		.MuiFormControl-root .MuiFormGroup-root label {
			margin-right: 0;
			justify-content: center;
			width: 80px;
			height: 34px;
		}
		.radio_item span:last-child p {
			background: #fff;
			border-radius: 0;
			border: 1px solid #eaeaea;
			text-align: center;
			font-size: 12px;
			width: 100%;
			height: 100%;
			padding: 8px;
			line-height: 1;
			color: #666;
		}
		.radio_item:first-child span:last-child p {
			border-radius: 5px 0px 0px 5px;
		}
		.radio_item:last-child span:last-child p {
			border-radius: 0 5px 5px 0;
		}
		.radio_item span.Mui-checked + .MuiTypography-root p {
			color: #1c1c1c;
			border: 1px solid #1c1c1c;
			font-family: 'NotoSansMedium';
		}
	}

    .chk-term-btn {
        height: 34px;
        line-height: 1;
        font-size: 14px;
        margin-right: 0;
        position: absolute;
        right: 30px;
    }
}

.date-picker {
	display: flex;
	align-items: center;
	margin-left: 10px;
	.date-picker-inner {
		display: flex;
		align-items: center;
	}
	.MuiOutlinedInput-root {
		color: #666;
		font-size: 12px;
		background: #fff;
		width: 126px;
		input {
			padding: 8px 0 8px 18px;
			width: 62%;
			height: 100%;
		}
	}
	fieldset {
		top: 0;
		border-color: #eaeaea;
	}
	button.MuiButtonBase-root {
		padding: 0;
		svg {
			display: none;
		}
		background: url('../images/mypage/date-pick-icon.jpg') no-repeat 100% center;
		background-size: contain;
		width: 17px;
		height: 17px;
		border-radius: 0;
	}
}

/* ////////////////////////////// */

.round-beige-btn-type.MuiButton-root {
    width: auto;
    min-width: 62px;
    height: 32px;
    line-height: 1;
    border: solid 1px #1c1c1c;
    border-radius: 25px;
    color: #1c1c1c;
    font-family: 'NotoSansMedium';
}
.round-white-btn-type.MuiButton-root {
    width: 62px;
    height: 32px;
    font-size: 14px;
    border: solid 1px #1c1c1c;
    font-family: 'NotoSansMedium';
    border-radius: 25px;
    background-color: #fff;
    color: #1c1c1c;
}
.dp-f {
    display: flex;
}

// 목록으로 가기(뒤로가기) 버튼
button.btnGoBack {
    margin: 53px auto 0;
    width: 202px;
    height: 54px;
    border-radius: 27.5px;
    background-color: rgb(243, 202, 83);
    border: 1px solid #1c1c1c;
    font: 20px / 50px 'NotoSansBold';
    letter-spacing: -0.6px;
    color: #1c1c1c;
}

button.btnGoBack.goToMain {
    background-color: #d3e2a3;
    color: #5e7c01;
    border: none;
}
button.btnGoBack_w {
    margin: 53px 10px auto 0;
    width: 202px;
    height: 54px;
    border-radius: 27.5px;
    background-color: #fff;
    border: 1px solid #1c1c1c;
    font: 20px / 50px 'NotoSansBold';
    letter-spacing: -0.6px;
    color: #1c1c1c;
}

// 개인정보 관리 테이블 css & 회원가입 가입정보 확인 테이블 css 
h3 + .MuiTableContainer-root table.table.MuiTable-root {
    border-top: none;
}
.table-list-type-1 tbody.MuiTableBody-root {
    display: flex;
    flex-wrap: wrap;
}
.table-list-type-1 tbody.tableMerge.MuiTableBody-root {
    display: inline-table;
    width:100%;
}
.table-list-type-1 tr.MuiTableRow-root {
    width: 50%;
    border-bottom: 1px solid #eaeaea;
}
.table-list-type-1 tr.MuiTableRow-root.fullWidth {
    width: 100%;
}

.table-list-type-1 th.MuiTableCell-root {
    text-align: left;
    padding: 18px 12px;
    border-bottom: none;
    font-family: 'NotoSansMedium';
    font-size: 16px;
    color: #1c1c1c;
    width: 200px;
    background: #f5f5f5;
}
.table-list-type-1 td.MuiTableCell-root {
    padding: 18px 20px;
    width: calc(100% - 200px);
    border-bottom: none;
    font-size: 16px;
    font-family: 'NotoSansRegular';
    color: #1c1c1c;

    label {
        margin-left: 0;
    }
    label ~ .agreement-date {
        font-family: 'NotoSansRegular';
        font-size: 14px;
        margin-right: 20px;
    }
    label:first-child + .agreement-date {
        margin-right: 20px;
    }
    &.email-row {
        .MuiFormControl-root{vertical-align: middle;width: calc(100% - 136px);}
        .MuiOutlinedInput-input {font-size: 15px;}
    }
}
/* ////////////////////////////// */

// 2022-07-27 피시 비활성화 버튼 스타일  disabled-btn 클래스 추가
.button-square-type.MuiButton-root.disabled-btn {
    // color: #b0b4b2;
    // border-radius: 6px;
    // border: solid 2px #b0b4b2;
    // background-color: #f8f8f8;
    border-radius: 6px;
    color: #fff;
    background: #8D99A5;
    border: solid 2px #8D99A5;
    box-shadow: none;
}
.disabled-btn {
    // color: #b0b4b2;
    // border-radius: 6px;
    // border: solid 2px #b0b4b2;
    // background-color: #f8f8f8;
    border-radius: 6px;
    color: #fff;
    background: #8D99A5;
    border: solid 2px #8D99A5;
}
.goToClass-btn.button-square-type.MuiButton-root.disabled-btn,
.cal-list-button-type2.disabled-btn {
    border: 1px solid #9aaab7;
    color: #fff;
    background: #9aaab7;
    border-radius: 5px;
}
/////////// 
.video-container iframe {
    width: 720px;
    height: 400px;
    /* left: 50%;
    transform: translateX(-50%); */
}

.modal-share-wrap {
    align-items: center;
    background: #fff;
    border: 2px solid #1499ed;
    border-radius: 8px;
    box-shadow: 4px 6px 14px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 50%;
    padding: 40px 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 320px; height: 174px;
    z-index: 10;
    
    > p {
        color: #0e2b49;
        font-family: NotoSansBold;
        font-size: 16px;
    }
    .close-btn-wrap {
        background: none;
        height: auto;
        margin: 0;
        position: absolute;
        right: 6px;
        top: 4px;
        width: 6%;
    }
    .confirm-btn {
        background: #2f99f4;
        border-radius: 20px;
        color: #fff;
        font-family: NotoSansMedium;
        font-size: 14px;
        height: 40px;
        width: 114px;
    }
}

@media screen and (max-width: 1023px) {
	.tag-list li {
		font-size: 10px;
	}
    .tag-list li::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 8px;
        right: 7px;
        bottom: 1px;
        background-color: #1c1c1c;
    }

    select.select-type-s {
        background-size: 10px 10px;
        padding: 8px 40px 8px 12px;
        border-radius: 5px;
        font-size: 13px;
    }

    select.select-type-s option {
        font-size: 13px;
    }

	button.btnGoBack {
		// 목록으로 가기(뒤로가기) 버튼
		margin: 40px auto 0px;
		width: 140px;
		height: 44px;
		border-radius: 27.5px;
		background-color: rgb(243, 202, 83);
        border: 1px solid #1c1c1c;
        font: 16px / 1 'NotoSansBold';
		letter-spacing: -0.6px;
		color: #1c1c1c;
	}
    button.btnGoBack_w {
        margin: 40px 10px auto 0px;
		width: 140px;
		height: 44px;
        font: 16px / 1 'NotoSansBold';
    }
	.filter-section {margin-bottom: 0;}
	.filter-section + ul.mypage-list-wrap {border-top: none;}
    .filter-box {
        .sub-filter-wrap {
            // padding: 5%;
            padding: 2% 5% 2% 5%;
            background: #f3f3f3;
            display: block;
            .filter-item {
                padding: 0 !important;
                .radioGrid {
                    .radio_item {
                        span.Mui-checked + .MuiTypography-root p {
                            border: 1px solid #0f8ff8;
                            color: #0f8ff8;
                        }
                    }
                    .MuiFormControl-root {
                        .MuiFormGroup-root {
                            label {
                                margin-right: 8px;
                                p {
                                    border-radius: 20px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .filter-box {
        background: #fff;
        border: none;
        // padding: 15px 0% 20px;
        padding: 15px 0 0;
        position: relative;
        .filter-item {
            padding: 0 5% 15px;
            display: block;
            > p.MuiTypography-body1 {
                display: none;
            }
            &.filter-top {
                .radioGrid {
                    .MuiFormControl-root .MuiFormGroup-root label {
                        width: 20%;
                        height: 40px;
                    }
                    .radio_item span:last-child p {
                        font-size: 13px;
                        padding: 0;
                        // height: 40px;
                        // line-height: 40px;
                        height: 40px;
                        line-height: 40px;
                    }
                    .radio_item:first-child span:last-child p {
                    }
                    .radio_item:last-child span:last-child p {
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
			&.filter-bottom {
                .radioGrid {
                    .radio_item {
                        width: 100%;
                        height: 34px;
                        min-width: 80px;
                        width: 80px;
                        span:last-child p {
                            font-size: 13px;
                            padding: 0;
                            height: 34px;
                            line-height: 34px;
                        }
                    }
                }
				
			}
        }
    

        .chk-term-btn {
            height: 40px;
            width: 20% !important;
            line-height: 1;
            font-size: 13px;
            margin-right: 0;
            padding: 0;
            border-radius: 0px 5px 5px 0px;
            box-shadow: none;
            position: static;
        }
    }

    .date-picker {
        display: flex;
        align-items: center;
        margin-top: 10px;
		margin-left: 0;
        .date-picker-inner {
            display: flex;
            align-items: center;
            border: 1px solid #eaeaea;
			border-radius: 6px;
            width: 79%;
            > div {
                width: calc(48%);
            }
            > span {
                width: 4%;
            }
            +.button-square-type.MuiButton-contained:hover {
                background: #2f99f4 !important;
            }
        }
        .MuiOutlinedInput-root {
            color: #666;
            font-size: 12px;
            background: #fff;
            width: 100%;
            input {
                padding: 0px 0 0px 18px;
                width: 62%;
                height: 40px;
            }
        }
        fieldset {
            top: 0;
            border: none;
        }
        button.MuiButtonBase-root {
            &.chk-term-btn {
                height: 40px;
                width: 20% !important;
                // line-height: 40px;
                font-size: 13px;
                margin-right: 0;
                padding: 0;
                // border-radius: 5px;
                box-shadow: none;
                position: static;
                // background: #162a47;
                margin-left: 1%;
                transform: translateY(0%);

                // 2022-08-09 기간조회버튼 수정
                border: 1px solid #2f99f4;
                border-radius: 5px;
                color: #fff;
                background: #2f99f4;
                font-family: 'NotoSansMedium';
                line-height: 1.5;
                box-shadow: none;
                &.button-square-type.MuiButton-contained:hover {
                    background: #2f99f4;
                    // background: transparent;
                    border: 2px solid #2f99f4;
                    box-shadow: none;
                }
            }
            padding: 0;
            svg {
                display: none;
            }
            background: url('../images/mypage/date-pick-icon.jpg') no-repeat 100% center;
            background-size: contain;
            width: 17px;
            height: 17px;
            border-radius: 0;
            position: absolute;
            right: 15%;
            top: 50%;
            transform: translateY(-50%);
        }
    }
	.round-white-btn-type.MuiButton-root {
		width: auto;
		height: 25px;
        font-size: 12px;
		min-width: 50px;
		line-height: 1;
	}
	.round-beige-btn-type.MuiButton-root {
		width: auto;
		height: 25px;
		min-width: 50px;
		line-height: 1;
		font-size: 12px;
		border: solid 1px #1c1c1c;
		border-radius: 25px;
		color: #1c1c1c;
	}
    button.btnGoBack {
        // 목록으로 가기(뒤로가기) 버튼
        margin: 40px auto 0px;
        width: 140px;
        height: 44px;
        border-radius: 27.5px;
        background-color: rgb(243, 202, 83);
        border: 1px solid #1c1c1c;
        font: 16px / 1 'NotoSansBold';
        letter-spacing: -0.6px;
        color: #1c1c1c;
    }
    // 2022-07-27 모바일 비활성화 버튼 스타일 disabled-btn 클래스 추가 
    .button-square-type.MuiButton-root.disabled-btn {
        color: #b0b4b2;
        border-radius: 5px;
        border: solid 1px #b0b4b2;
        background-color: #f8f8f8;
    }
    .disabled-btn {
        // color: #b0b4b2;
        // border-radius: 5px;
        // border: solid 1px #b0b4b2;
        // background-color: #f8f8f8;
        border-radius: 5px;
        color: #fff;
        background: #9aaab7;
        border: solid 2px #9aaab7;
    }
    .disabled-btn:hover {box-shadow: none;}
    .underline-text {margin-left: 0;}

    .goToClass-btn.button-square-type.MuiButton-root.disabled-btn,
    .cal-list-button-type2.disabled-btn  {
        border: 1px solid #9aaab7;
        color: #fff;
        background: #9aaab7;
        border-radius: 5px;
    }

    .table-container.MuiTableContainer-root {
        margin: 0 14px;
        width: 92%;
    }
    .table-list-type-1 tr.MuiTableRow-root {
        width: 100%;
    }
    .table-list-type-1 th.MuiTableCell-root {
        padding: 11px 14px;
        font-size: 13px;
        width: auto;
        min-width: 120px;
    }
    .table-list-type-1 .tableMerge th.MuiTableCell-root {
        width: 120px;
    }
    .table-list-type-1 td.MuiTableCell-root {
        padding: 11px 14px;
        font-size: 13px;
        width: calc(100% - 146px);
        &.email-row {
            .MuiFormControl-root{vertical-align: middle;    width: 100%;}
            .MuiOutlinedInput-input {padding: 16px;font-size: 14px;}
            fieldset {top: 0;}
            .btnAddProfile {margin-top: 10px;}
        }
    }
    .btn-bdr-type1.MuiButton-outlined {
        border: 1px solid #afb4b2;
        padding: 6px 10px 6px 36px;
    }

    .modal-share-wrap {
        width: 131px; height: 50px;
        max-width: 205px; max-height: 60px;
        background: #fff;
        border: 1px solid #000;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        padding: 0 37px 0 21px;
        left: auto;
        right: 0;
        top: 0;
        position: absolute;
        transform: translateX(0);

        .close-btn-wrap {
            position: absolute;
            top: 4px;
            right: 6px;
            width: 6%;
            margin: 0;
            background: none;
            height: auto;

            img {
                width: 100%;
            }
        }
        .share-btn-wrap {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
                width: calc(100% / 2 - 6%);
            }
            img {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .MuiMenu-list {
        .MuiMenuItem-root {
            font-size: 14px;
        }   
    }
}

// 기본 버튼 스타일
@mixin defaultButton {
    width: 356px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid #1c1c1c;
    background-color: #fff;
    // box-shadow: 1px 2px 0 0 rgba(154, 154, 154, 0.8);
    font: 24px/1 'NotoSansBold';
    color: $c-sc4;
    margin-top: 43px;

    @media screen and (max-width: 1023px) {
        width: 196px;
        height: 44px;
        font-size: 15px;
        border-width: 1px;
        margin-top: 30px;
    }
}

.c-bg1 {
    background-color: #fff !important;
}
.borderTop {border-top: 1px solid #eaeaea;} 
.underline-text {
    font-family: "NotoSansRegular";
    margin-left: 8px;
    font-size: 14px;
    color: #222222;
    text-decoration: underline;
}

.bOgMYl {
    width: 320px;
    min-height: 174px;
    height: auto;
    background: #fff;
    border: 2px solid #2f99f4;
    border-radius: 8px;
    box-shadow: 4px 6px 14px rgb(0 0 0 / 16%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dJBTvo {
    width: 100%;
    padding: 0;
}
.dJBTvo h3 {
    margin: 0;
    font-size: 16px;
    color: #1c1c1c;
    font-family: 'NotoSansBold';
}
.kZRYH {
    text-align: center;
    margin-top: 10px;
}
.dJBTvo p {font-size: 14px;}
.iiOERc {
    justify-content: center;
    margin-top: 30px;
}
.iiOERc {
    button {
        border: 2px solid #1c1c1c;
        width: 114px;
        height: 40px;
        border-radius: 20px;
        background: #fff;
        color: #162a47;
        font-size: 14px;
        font-family: 'NotoSansMedium';
        margin-right: 10px;
    }
    button:last-child {
        width: 114px;
        height: 40px;
        border-radius: 20px;
        background: #162a47;
        color: #fff;
        font-size: 14px;
        font-family: 'NotoSansMedium';
    }
}

.footer-content-box {
    width: 630px;
    margin: 0 auto;
    padding-top: 60px;
    text-align: left;
    border: none;
    box-shadow: none;
    p.MuiTypography-body1 {
        font-size: 14px;    
        font-family: 'NotoSansRegular', sans-serif;
        text-indent: -16px;
    }
    .MuiTypography-h5 {font-family: 'NotoSansBold';font-size: 20px;}

    .MuiCardContent-root {
        padding: 16px 32px;
        border: 1px solid #eee;
        p.MuiTypography-body1 {font-size: 13px; text-indent: -14px;}
    }
}
@media screen and (max-width: 768px) {
    .footer-content-box {
        width: 100%;
        margin: 0 auto;
        padding: 20px 14px;

        p.MuiTypography-body1 {padding: 0 16px;}
        .MuiCardContent-root {
            padding: 16px 14px;
        }
    }
}
@media screen and (max-width: 876px) {
    .video-container iframe {
        width: 100% !important;
        height: 51vw !important;
    }
}

@media screen and (max-width: 420px) {
    .recommendCode tr .round-beige-btn-type.MuiButton-root:last-child  {
        margin-left: 0 !important;
        margin-top: 5px;
    }
}


.MuiInputBase-root.Mui-disabled {background-color: #f8f8f8;}

.m0 {margin:0 !important;}
.p_r {position: relative;}