.gnb-area {
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  .container {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    align-items: center;
  }
  .category-box {
    position: relative;
    .category-btn {
      justify-content: flex-start;
      width: 230px;
      height: 63px;
      min-width: 0;
      padding-left: 88px;
      background: #2f99f4 url("../../images/common/category-icon@2x.png") 16px
        center/32px no-repeat;
      border-radius: 0;
      font-family: "NotoSansMedium";
      color: #fff;
      font-size: 20px;
    }
    .category-menu {
      display: none;
      position: absolute;
      left: 0;
      top: 63px;
      width: 100%;
      // width: 18.3%;
      min-height: 264px;
      padding: 12px 0;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 0 0 12px 12px;
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
      &.on {
        display: block;
        height: 35rem;
        overflow: scroll;
        &::-webkit-scrollbar {
          width: 0px;
          background-color: transparent;
        }
        &.extend {
          width: 200%;
          padding-right: 100%;
        }
      }

      .btn {
        align-items: center;
        border-radius: 0;
        color: #1c1c1c;
        display: flex;
        font-family: NotoSansMedium;
        font-size: 16px;
        width: 100%;
        height: 48px;
        justify-content: flex-start;
        padding: 0 25px;
        position: relative;
        text-align: left;
        &.arr {
          &::after {
            content: "";
            display: block;
            position: absolute;
            right: 16px;
            top: 50%;
            width: 16px;
            height: 16px;
            margin-top: -8px;
            background: url("../../images/common/category-arr.png") center/12px
              no-repeat;
          }
          &:hover,
          &.active,
          &.dep2on {
            background-color: #ebf6ff;
            color: #2f99f4;
            font-family: "NotoSansBold";
          }
          &.active:after,
          &.dep2on:after,
          &:hover:after {
            background-image: url("../../images/common/category-arr-active.png");
          }
          &.dep2on::after {
            transform: rotate(180deg);
          }
        }
      }
    }
    .category-dep2-area {
      position: absolute;
      left: 50%;
      top: 0;
      width: 50%;
      height: 100%;
      padding: 12px 4px 12px 0;
      border-left: 1px solid #e0e0e0;

      .category-dep2-menu {
        overflow: auto;
        height: 100%;

        &.extend {
          width: 200%;
          padding-right: 100%;
        }

        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          width: 4px;
          background: #e0e0e0;
          border-radius: 2px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }
    }
  }
  .gnb {
    display: flex;
    justify-content: space-around;
    flex: 1;
    .swiper-slide {
      width: auto;
    }

    a {
      display: inline-block;
      height: 63px;
      line-height: 63px;
      padding: 0 30px;
      color: #1c1c1c;
      font-family: "NotoSansBold";
      font-size: 20px;

      &:hover {
        color: #2f99f4;
      }
    }
  }
}

.header .gnb-area .category-menu .btn.arr.dep2on + .category-dep2-area {
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .gnb-area {
    .container {
      max-width: 1023px;
    }
    .category-box {
      position: static;

      .category-btn {
        width: 48px;
        height: 48px;
        padding-left: 0;
        background-image: url("../../images/common/category-icon-m@2x.png");
        background-position: center;
        background-size: 24px;
        font-size: 0;
      }
      .category-menu-wrap {
        display: none;
        position: absolute;
        left: 0;
        top: 48px;
        width: 100%;
        height: calc(100vh - 105px);
        background-color: #fff;
        border-top: 1px solid #e0e0e0;
        &.on {
          display: flex;
        }
      }
      .category-menu {
        display: block;
        position: static;
        width: 170px;
        height: 100%;
        padding: 0;
        background-color: #f8f8f8;
        border: 0 none;
        border-radius: 0;
        box-shadow: none;
        .btn {
          padding: 0 50px 0 16px;
          border-bottom: 1px solid #ececec;
          font-size: 14px;

          &:hover,
          &.active,
          &.dep2on {
            background-color: #ebf6ff;
            color: #2f99f4;
            font-family: "NotoSansBold";
          }
          &.arr {
            &:hover::after,
            &.active::after,
            &.dep2on::after {
              background-image: url("../../images/common/category-arr-active.png");
            }
          }
        }
      }
      .category-dep2-area {
        display: block;
        position: relative;
        left: auto;
        top: auto;
        width: 100%;
        height: auto;
        margin-bottom: 16px;
        padding: 4px 0 16px;
        border-width: 0;

        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 16px;
          bottom: 0;
          width: calc(100% - 32px);
          height: 1px;
          background-color: #e0e0e0;
        }

        &:last-child {
          margin-bottom: 0;
          &::after {
            display: none;
          }
        }

        .tit-link {
          display: block;
          margin-bottom: 4px;
          padding: 12px 16px;
          color: #1c1c1c;
          font-family: "NotoSansBold";
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .gnb {
      height: 48px;
      padding: 0 8px;

      a {
        font-size: 1rem;
        display: inline-block;
        height: 100%;
        line-height: 48px;
        padding: 0 8px;
        color: #1c1c1c;
        font-family: "NotoSansBold";
        &.active {
          color: #2f99f4;
        }
      }
    }
    .m-dep2-category-menu {
      flex: 1;
      overflow: auto;
      height: 100%;
      padding-bottom: calc(100vh - 200px);
      text-align: left;
    }

    .category-dep2-menu {
      overflow: visible;
      height: auto;
      .btn {
        display: flex;
        align-items: center;
        position: relative;
        height: 48px;
        padding: 0 16px;
        color: #1c1c1c;
        font-size: 14px;

        &.active,
        &:active {
          background-color: #ebf6ff;
          color: #2f99f4;
          font-family: "NotoSansBold";
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .gnb-area {
    .container {
      max-width: 1023px;
    }
    .category-box {
      position: static;

      .category-btn {
        width: 48px;
        height: 48px;
        padding-left: 0;
        background-image: url("../../images/common/category-icon-m@2x.png");
        background-position: center;
        background-size: 24px;
        font-size: 0;
      }
      .category-menu-wrap {
        display: none;
        position: absolute;
        left: 0;
        top: 48px;
        width: 100%;
        height: calc(100vh - 105px);
        background-color: #fff;
        border-top: 1px solid #e0e0e0;
        z-index: 10;
        &.on {
          display: flex;
        }
      }
      .category-menu {
        display: block;
        position: static;
        width: 170px;
        height: 100%;
        padding: 0;
        background-color: #f8f8f8;
        border: 0 none;
        border-radius: 0;
        box-shadow: none;

        .btn {
          padding: 0 50px 0 16px;
          border-bottom: 1px solid #ececec;
          font-size: 14px;

          &:hover,
          &.active,
          &.dep2on {
            background-color: #ebf6ff;
            color: #2f99f4;
            font-family: "NotoSansBold";
          }
          &.arr {
            &:hover::after,
            &.active::after,
            &.dep2on::after {
              background-image: url("../../images/common/category-arr-active.png");
            }
          }
        }
      }
      .category-dep2-area {
        display: block;
        position: relative;
        left: auto;
        top: auto;
        width: 100%;
        height: auto;
        margin-bottom: 16px;
        padding: 4px 0 16px;
        border-width: 0;

        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 16px;
          bottom: 0;
          width: calc(100% - 32px);
          height: 1px;
          background-color: #e0e0e0;
        }

        &:last-child {
          margin-bottom: 0;
          &::after {
            display: none;
          }
        }

        .tit-link {
          display: block;
          margin-bottom: 4px;
          padding: 12px 16px;
          color: #1c1c1c;
          font-family: "NotoSansBold";
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .gnb {
      height: 48px;
      padding: 0 8px;
      justify-content: flex-start;

      a {
        font-size: 0.9rem;
        display: inline-block;
        height: 100%;
        line-height: 48px;
        padding: 0 8px;
        color: #1c1c1c;
        font-family: "NotoSansBold";
      }
    }
    .m-dep2-category-menu {
      flex: 1;
      overflow: auto;
      height: 100%;
      padding-bottom: calc(100vh - 200px);
      text-align: left;
    }

    .category-dep2-menu {
      overflow: visible;
      height: auto;
      .btn {
        display: flex;
        align-items: center;
        position: relative;
        height: 48px;
        padding: 0 16px;
        color: #1c1c1c;
        font-size: 14px;

        &.active,
        &:active {
          background-color: #ebf6ff;
          color: #2f99f4;
          font-family: "NotoSansBold";
        }
      }
    }
  }
}

.mobile-gnb {
  overflow-x: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.top-menu {
  display: inline-block;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
