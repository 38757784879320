@import "../style.scss";

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;

  // &.top-banner {
  //   + .search-wrap .sub-wrap,
  //   + .sub-wrap-footer,
  //   + .sub-wrap.best,
  //   + .sub-wrap.readingBook {
  //     padding-top: 244px;
  //   }
  // }
  .topBanner-area {
    height: 64px;
    .btn-close {
      position: fixed;
      top: 20px;
      right: 11%;
      transform: translateX(-50%);
      width: 18px;
      height: 18px;
      z-index: 10;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .slide-topBanner {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      .slideBg {
        width: 100%;
        height: 100%;
        > img {
          width: 100%;
          height: 100%;
          -o-object-fit: none;
          object-fit: none;
          cursor: pointer;
        }
      }
      .swiper-pagination {
        bottom: 2px;
      }
    }
  }
  .util-area {
    width: 100%;
    height: 44px;
    background-color: #f8f8f8;

    .container {
      display: flex;
      position: relative;
      width: 100%;
      max-width: 1260px;
      margin: 0 auto;
      align-items: center;

      justify-content: flex-end;
      height: 100%;
    }
    .head-menu {
      display: flex;
      align-items: center;

      li {
        position: relative;
        margin-right: 24px;
        font-size: 14px;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: inline-block;
          color: #666;
          font-family: "NotoSansMedium";
          font-size: 14px;
        }
      }

      .btn-calendar {
        position: relative;
        width: 98px;
        line-height: 28px;
        background-color: #fc5651;
        border-radius: 14px;
        color: #fff;
        font-family: "NotoSansLight";
        font-size: 14px;

        .calendar-num {
          display: inline-block;
          position: absolute;
          top: -8px;
          right: -12px;
          color: #fff;
          font-size: 14px;
          width: 25px;
          line-height: 25px;
          background: #1c1c1c;
          border-radius: 50%;
          text-align: center;
        }

        & + .alarm-wrap {
          position: absolute;
          right: 12px;
          top: 88%;
          width: 190px;
          z-index: 11;

          img {
            width: 100%;
            height: 35px;
          }

          p {
            width: 100%;
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: "NotoSansMedium";
            font-size: 13px;
            color: #1c1c1c;
          }
        }
      }
    }
  }
  .logo-area {
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    .container {
      display: flex;
      position: relative;
      width: 100%;
      max-width: 1260px;
      margin: 0 auto;
      align-items: center;

      justify-content: space-between;
      z-index: 10;
      height: 71px;
      // padding: 0 15px; logo-n
      padding: 0 15px 0 0;
    }
    .logo {
      // width: 145px; height: 26px; logo-n
      width: 215px;
      height: 53px; // logoWinter
      margin: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sub-menu {
      align-items: center;
      display: flex;

      li {
        font-size: 14px;
        margin-right: 15px;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: -8px;
          width: 1px;
          height: 16px;
          margin-top: -8px;
          background-color: #e0e0e0;
        }
        &:last-child {
          margin-right: 0;
          &::after {
            display: none;
          }
        }
      }
      a {
        color: #1c1c1c;
        font-family: "NotoSansMedium";
        font-size: 16px;

        &:hover,
        &:active {
          color: #2f99f4;
        }
      }
    }
  }
  .nav-container + .all-nav {
    position: absolute;
    z-index: 1;
    top: 80px;
    left: 0;
    display: none;
    transition: 0.5s ease;
  }
  .all-nav {
    &.show {
      display: block;
      transition: 0.5s ease;
    }
    &.hide {
    }
    .category-list {
      padding: 29px 32px;
      display: inline-block;
      border: 2px solid #0e2b49;
      background: #f6f2e6;
      min-width: 240px;
      text-align: left;

      li a {
        font-size: 16px;
        font-family: "NotoSansBold";
        color: #222;
        line-height: 2;
        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #ff5651;
          border-radius: 50%;
          margin-right: 6px;
          vertical-align: middle;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    height: 105px;
    &.top-banner {
      height: 168px;
      + .mainWrap,
      + .sub-wrap-footer,
      + .sub-wrap.best,
      + .sub-wrap.readingBook,
      + .sub-wrap,
      + .class-detail-container {
        padding-top: 28px;
      }

      /* 2024-04-26 검색결과 상단 헤더 추가로 수정 */
      + .search-wrap .sub-wrap {
        padding-top: 16px;
      }
    }
    .topBanner-area .slide-topBanner .slideBg > img {
      -o-object-fit: cover;
      object-fit: cover;
    }
    .logo-area {
      .container {
        height: 55px;
        padding: 0 15px;
      }
      .logo {
        display: flex;
        align-items: center;
        img {
          width: 160px;
          height: 39px;
        }
      }

      .sub-menu {
        position: absolute;
        top: auto;
        right: 14px;

        li {
          margin-right: 17px;
          &::after {
            width: 0;
          }
        }
        img {
          height: 22px;
          vertical-align: top;
        }
        .btn-calendar {
          background-color: transparent;
          width: 24px;

          .calendar-num {
            right: -9px;
            width: 20px;
            line-height: 20px;
            font-size: 10px;
          }
        }
      }
    }

    .all-nav {
      width: 100%;
      height: calc(100vh - 56px);
      position: fixed;
      top: 56px;
      left: 0;
      .category-list {
        width: 100%;
        height: 100%;
        background: #fff;
        border: none;
        overflow-y: auto;
        padding: 30px 7.5%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    &.top-banner {
      + .mainWrap,
      + .sub-wrap-footer,
      + .sub-wrap.best,
      + .sub-wrap.readingBook,
      + .sub-wrap,
      + .class-detail-container {
        padding-top: 0;
      }
    }

    .topBanner-area .slide-topBanner .slideBg > img {
      -o-object-fit: contain;
      object-fit: contain;
    }

    .logo-area {
      .container {
        height: 55px;
        padding: 0 15px;
      }
      .logo {
        display: flex;
        align-items: center;
        img {
          width: 120px;
          height: 30px;
        }
      }
      .sub-menu {
        position: absolute;
        top: auto;
        right: 14px;

        li {
          margin-right: 17px;
          &::after {
            width: 0;
          }
        }
        img {
          height: 22px;
          vertical-align: top;
        }
      }
    }
  }
}
