.sub-wrap {
  padding: 0 0 50px;
  min-height: 100vh;
  &.mypage-wrap,
  &.teacher {
    padding-top: 0;
  }
}

.sub-header + .sub-wrap {
  padding: 0;
}

.sub-wrap-footer {
  /* padding: 90px 0 50px; */
  padding: 0 0 50px;
  min-height: 100vh;
}

.sub-fallback-margin {
  margin-top: 100%;
}

.subVisual {
  width: 100%;
  padding: 50px 0 48px;
  font-size: 42px;
  line-height: 1.5;
  font-family: "NotoSansBold";
  color: #1c1c1c;
  /* background: #f6f2e6; */
}

.liveall .subVisual {
  background: #badfe8;
}
.best .subVisual {
  background: #fbc831;
}
.readingbook .subVisual {
  background: #a0cffa;
}
.together .subVisual {
  padding: 0;
  background: #f5f9e8;
}
.magazine .subVisual {
  background: #ffabc9;
}
.event .subVisual {
  background: #84d7bd;
}
.sub-wrap.event .classInfo {
  border-bottom: none;
}
.teacher .subVisual {
  background: #c9adef;
  margin-bottom: 79px;
}
.sub-wrap.liveall {
  padding: 180px 0 0;
  background-color: #f8f8f8;
  position: relative;
}
.sub-wrap.liveall img {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  display: block;
  z-index: 9;
  position: relative;
}
/* .sub-wrap.liveall::before{
    content: "";
    position: absolute;
    background-color: #6a40e2;
    width: 100%;
    height: 366px;
    top: 449px;
    left: 0;
    z-index: 1;
 } */
.sub-wrap.liveall .purpleBg {
  position: absolute;
  background-color: #1e3352;
  width: 100%;
  height: 647px;
  top: 1044px;
  left: 0;
}
.sub-wrap.liveall .whiteBg {
  position: absolute;
  background-color: #f6f2e6;
  width: 100%;
  height: 1383px;
  top: 1693px;
  left: 0;
}
.sub-wrap.liveall .purpleBg2 {
  position: absolute;
  background-color: #1e3352;
  width: 100%;
  height: 909px;
  top: 3076px;
  left: 0;
}
.sub-wrap.liveall .whiteBg2 {
  position: absolute;
  background-color: #f6f2e6;
  width: 100%;
  height: 1170px;
  top: 3985px;
  left: 0;
}
.sub-wrap.liveall .grayBg {
  position: absolute;
  background-color: #edefee;
  width: 100%;
  height: 927px;
  top: 5160px;
  left: 0;
}

/* .readingbook .itemContainer, .best .itemContainer, */
.together .itemContainer {
  margin: 3.6% auto auto auto;
}
.subVisual .container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1260px;
  height: 160px;
  margin: 0 auto;
  padding: 0 120px;
  text-align: left;
}
.subVisual .container .txt-box .tit {
  color: #1c1c1c;
  font-family: "NotoSansBold";
  font-size: 30px;
  line-height: 44px;
}
.subVisual .container .txt-box .tit b {
  font-size: 40px;
}
.subVisual .container .txt-box .txt {
  margin-top: 5px;
  color: #1c1c1c;
  font-size: 20px;
  line-height: 28px;
}
.together .subVisual .container {
  background: url("../images/together/together_banner_obj.png") right 135px
    bottom/255px no-repeat;
}
.together .subVisual .container .txt-box .tit {
  color: #7ca00c;
}
.together .subVisual .container .txt-box br {
  display: none;
}

/* 2023-03-23 라이브올이란 개편 */
.intro-content .container {
  position: relative;
  overflow: hidden;
  max-width: 1260px;
  margin: 0 auto;
  text-align: center;
}
.intro-content .container img {
  max-width: 100%;
}
.intro-content .link-btn {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  font-size: 0;
}

/* .intro-content.con01 {background: #2eaaf6 url('../images/intro/pc/pc_01_bg.png') 0 bottom repeat-x;} */
.intro-content.con01 {
  background: #2eaaf6 url("../images/intro/pc/pc_01_new_bg.png") 0 bottom
    repeat-x;
}
.intro-content.con01 .container {
  background: url("../images/intro/pc/pc_01_new.png") no-repeat;
}
.intro-content.con01 .container iframe {
  /* pc 985x560  */
  width: 78.7%;
  height: 43.5%;
  position: absolute;
  top: 39.2%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.8vw;
}
/* .intro-content.con01::before {content: ''; display: block; position: absolute; left: 0; right: 0; bottom: 0; height: 210px; background: linear-gradient(180deg, rgba(48,142,240,1) 0%, rgba(46,170,246,1) 100%);} */
.intro-content.con01-1 {
  background-color: #9b9b9b;
}
.intro-content.con01-2 {
  background-color: #2ca6f5;
}

.intro-content.con02 {
  background-color: #f0f9ff;
}
.intro-content.con02 .container {
  padding: 0 80px;
}
.intro-content.con02 .scroll-ico {
  display: block;
  position: absolute;
  left: 50%;
  top: 6.12%;
  width: 44px;
  height: 70px;
  background: url("../images/intro/pc/pc_02_scroll_ico@2x.png") center/100%
    no-repeat;
  transform: translateX(-50%);
  -webkit-animation: scrollBounce 1s infinite;
  animation: scrollBounce 1s infinite;
}
.intro-content.con02 .bubble-box {
  position: relative;
  margin: 22.23% 0 7.14%;
}
.intro-content.con02 .bubble-box .liveall-logo {
  position: absolute;
  left: 4.2%;
  top: 4.15%;
  max-width: 27.72%;
  -webkit-animation: logoScale 2.3s ease-in-out infinite;
  animation: logoScale 2.3s ease-in-out infinite;
}
.intro-content.con02 .bubble-box .wj-logo {
  position: absolute;
  left: 59.72%;
  top: 63.79%;
  max-width: 28.09%;
  -webkit-animation: logoScale 2.3s 0.27s ease-in-out infinite;
  animation: logoScale 2.3s 0.27s ease-in-out infinite;
}
.intro-content.con02 .txt {
  margin-bottom: 13.49%;
}

.intro-content.con03 .container {
  padding: 0 23px;
}
.intro-content.con03 .tit {
  max-width: 70.2%;
  margin: 12.06% 0 5.55%;
}
.intro-content.con03 .txt {
  margin-bottom: 12%;
}
.intro-content.con03 .class-each {
  margin-bottom: 14.28%;
}
.intro-content.con03 .class-each .class-box {
  position: relative;
  margin-bottom: 5.15%;
}
.intro-content.con03 .class-each .class-box .list {
  position: absolute;
  left: 50%;
  top: 21.22%;
  transform: translateX(-50%);
  width: 84.92%;
}
.intro-content.con03 .class-each .class-box .list img {
  float: left;
  width: 45.875%;
  margin-right: 8.25%;
  margin-bottom: 6.79%;
}
.intro-content.con03 .class-each .class-box .list img:nth-child(2n) {
  margin-right: 0 !important;
}
.intro-content.con03 .class-each .class-box .list img:nth-last-child(-n + 2) {
  margin-bottom: 0 !important;
}
.intro-content.con03 .class-each.liking {
  margin-bottom: 11.42%;
}
.intro-content.con03 .class-each.liking .class-box .list {
  top: 12.29%;
  width: 84.02%;
}
.intro-content.con03 .class-each.liking .class-box .list img {
  width: 46.325%;
  margin-right: 7.35%;
  margin-bottom: 6.86%;
}
.intro-content.con03 .class-each.liking .class-box .box-txt {
  position: absolute;
  left: 50%;
  top: 91.69%;
  transform: translateX(-50%);
  max-width: 62.52%;
}

.intro-content.con03 .class-each .class-box .list img.fade-in:nth-child(2) {
  transition-delay: 0.2s;
}
.intro-content.con03 .class-each .class-box .list img.fade-in:nth-child(3) {
  transition-delay: 0.4s;
}
.intro-content.con03 .class-each .class-box .list img.fade-in:nth-child(4) {
  transition-delay: 0.6s;
}

.intro-content.con04 {
  background-color: #f0f9ff;
}
.intro-content.con04 .container {
  padding: 0 23px;
}
.intro-content.con04 .txt {
  margin: 13.67% 0 11.11%;
}
.intro-content.con04 .teacher-slider {
  position: relative;
  overflow: hidden;
  margin-bottom: 13.17%;
  padding-bottom: 67.1334%;
}
.intro-content.con04 .teacher-slider .box {
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  left: 0;
  top: 0;
  width: 674.7%;
  height: 100%;
  -webkit-animation: bannermove 30s linear infinite;
  animation: bannermove 30s linear infinite;
}
.intro-content.con04 .teacher-slider .box > a {
  display: block;
  flex-shrink: 0;
  height: 100%;
}
.intro-content.con04 .teacher-slider .box > a img {
  max-height: 100%;
}

.intro-content.con05 .container {
  padding: 0 23px;
}
.intro-content.con05 .txt {
  margin: 11.36% 0 5.6%;
}
.intro-content.con05 .capture-tab-area {
  position: relative;
  margin-bottom: 4.77%;
  padding-bottom: 10.62%;
  background: url("../images/intro/pc/pc_05_tab_on1.png") center 0 / cover
    no-repeat;
}
.intro-content.con05 .capture-tab-area .btn-box {
  position: absolute;
  left: 1.31%;
  right: 1.31%;
  top: 6.97%;
  height: 75.19%;
}
.intro-content.con05 .capture-tab-area .btn-box button {
  height: 100%;
  font-size: 0;
}
.intro-content.con05 .capture-tab-area .btn-box button:nth-child(1) {
  width: 24.03%;
}
.intro-content.con05 .capture-tab-area .btn-box button:nth-child(2) {
  width: 25.97%;
}
.intro-content.con05 .capture-tab-area .btn-box button:nth-child(3) {
  width: 25.97%;
}
.intro-content.con05 .capture-tab-area .btn-box button:nth-child(4) {
  width: 24.03%;
}
.intro-content.con05 .capture-tab-area.on2 {
  background-image: url("../images/intro/pc/pc_05_tab_on2.png");
}
.intro-content.con05 .capture-tab-area.on3 {
  background-image: url("../images/intro/pc/pc_05_tab_on3.png");
}
.intro-content.con05 .capture-tab-area.on4 {
  background-image: url("../images/intro/pc/pc_05_tab_on4.png");
}

.intro-content.con05 .capture-slide-box img {
  display: none;
}
.intro-content.con05 .capture-slide-box img:first-child {
  display: inline;
}
.intro-content.con05 .capture-slide-pager {
  display: inline-flex;
  align-items: center;
  height: 26px;
  margin: 3.95% 0 22.65%;
}
.intro-content.con05 .capture-slide-pager span {
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  background-color: #2f99f4;
  border-radius: 50%;
  transition: all 0.3s;
}
.intro-content.con05 .capture-slide-pager span.on {
  width: 26px;
  height: 26px;
  background-color: #eef8fd;
  border: 4px solid #2f99f4;
}
.intro-content.con05 .capture-slide-pager span:last-child {
  margin-right: 0;
}

.intro-content.con06 {
  background-color: #f0f9ff;
}
.intro-content.con06 .container {
  padding: 0 23px;
}
.intro-content.con06 .tit {
  max-width: 70.2%;
  margin: 10.21% 0 5.35%;
}
.intro-content.con06 .txt {
  max-width: 83.7%;
  margin-bottom: 6.42%;
}
.intro-content.con06 .review-slider {
  height: auto;
  margin-bottom: 9.88%;
}
.intro-content.con06 .review-slider .swiper-slide {
  padding: 0 130px;
  background-color: transparent;
}
.intro-content.con06 .review-slider .swiper-button-prev,
.intro-content.con06 .review-slider .swiper-button-next {
  top: calc(50% + 50px);
  width: 25px;
  height: 44px;
  margin-top: 0;
  background-size: 100%;
  background-repeat: no-repeat;
}
.intro-content.con06 .review-slider .swiper-button-prev {
  left: 70px;
  background-image: url("../images/intro/pc/pc_06_slide_prev.png");
}
.intro-content.con06 .review-slider .swiper-button-next {
  right: 70px;
  background-image: url("../images/intro/pc/pc_06_slide_next.png");
}
.intro-content.con06 .review-slider .swiper-button-prev::after,
.intro-content.con06 .review-slider .swiper-button-next::after {
  display: none;
}

.intro-content.con07 {
  background-color: #2f99f4;
}

.fade-element {
  position: relative;
  top: 80px;
  opacity: 0;
  transition:
    top 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.5s linear;
}
.fade-in {
  transition:
    top 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.5s linear;
  top: 0px;
  opacity: 1;
}

@-webkit-keyframes scrollBounce {
  50% {
    margin-top: 10px;
  }
}

@keyframes scrollBounce {
  50% {
    margin-top: 10px;
  }
}

@-webkit-keyframes logoScale {
  15% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1);
  }
}

@keyframes logoScale {
  15% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1);
  }
}

@-webkit-keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

/* // 2023-03-23 라이브올이란 개편 */
@media screen and (max-width: 1260px) {
  /* 목록 필터링 콤보박스 */
  .list-sort-slt-area.MuiFormControl-root {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1023px) {
  .sub-wrap {
    padding: 0;
    min-height: 76vh;
  }
  .mo-header + .sub-wrap,
  .mo-header + .search-wrap {
    padding: 0 0 60px;
  }
  .mo-header + .sub-wrap.mypage-wrap {
    padding: 0;
  }
  .sub-header + .sub-wrap {
    min-height: calc(100vh - 233px);
  }
  .sub-wrap.liveall {
    padding: 105px 0 0;
  }
  .sub-wrap.liveall img {
    max-width: 100%;
    width: auto;
  }
  .sub-wrap .swiper.tabMenu {
    /* 2023-03-07 모바일 키워드 CSS 수정 */
    margin: 20px 0 0 0;
  }

  .sub-wrap-footer {
    padding: 0 0 50px;
    min-height: auto;
  }

  /* 2023-03-23 라이브올이란 개편 */
  .intro-content .container {
    max-width: 750px;
  }

  /* .intro-content.con01 {background-color: #51b5f3; background-image: url('../images/intro/mo/mo_01_bg.png');} */
  .intro-content.con01 {
    background-color: #51b5f3;
    background-image: url("../images/intro/mo/mo_01_new_bg.png");
  }
  .intro-content.con01 .container {
    background: url("../images/intro/mo/mo_01_new.png") no-repeat;
  }
  .intro-content.con01 .container iframe {
    /* tab 775x440 */
    width: 79.5%;
    height: 34%;
    top: 48.3%;
    border-radius: 1.2vw;
  }

  .intro-content.con02 .container {
    padding: 0 7px;
  }
  .intro-content.con02 .scroll-ico {
    top: 5.24%;
  }
  .intro-content.con02 .bubble-box {
    margin: 32% 0 8%;
  }
  .intro-content.con02 .bubble-box .liveall-logo {
    left: 1%;
    top: 10.25%;
    max-width: 32.32%;
  }
  .intro-content.con02 .bubble-box .wj-logo {
    left: 63.85%;
    top: 66.94%;
    max-width: 35.73%;
  }
  .intro-content.con02 .txt {
    max-width: 90.76%;
    margin-bottom: 18.66%;
  }

  .intro-content.con03 .container {
    padding: 0;
  }
  .intro-content.con03 .tit {
    max-width: 100%;
    margin: 13.33% 0 7.33%;
  }
  .intro-content.con03 .txt {
    margin-bottom: 18.66%;
  }
  .intro-content.con03 .class-each {
    margin-bottom: 13.33%;
  }
  .intro-content.con03 .class-each .class-box {
    margin-bottom: 6.26%;
    padding: 0 2%;
  }
  .intro-content.con03 .class-each .class-box .list {
    top: 26.78%;
    width: 88%;
  }
  .intro-content.con03 .class-each .class-box .list img {
    width: 48.64%;
    margin-right: 2.72%;
    margin-bottom: 3.33%;
  }
  .intro-content.con03 .class-each.liking {
    margin-bottom: 11.33%;
  }
  .intro-content.con03 .class-each.liking .class-box .list {
    top: 14.86%;
    width: 89.6%;
  }
  .intro-content.con03 .class-each.liking .class-box .list img {
    width: 48.07%;
    margin-right: 3.86%;
    margin-bottom: 7.14%;
  }
  .intro-content.con03 .class-each.liking .class-box .box-txt {
    top: 90.24%;
    max-width: 84.13%;
  }

  .intro-content.con04 .container {
    padding: 0;
  }
  .intro-content.con04 .txt {
    margin: 15.6% 0 12%;
  }
  .intro-content.con04 .-teacherslider {
    margin-bottom: 14%;
    padding-bottom: 67.1334%;
  }
  .intro-content.con04 .teacher-slider .box {
    width: 674.7%;
  }

  .intro-content.con05 .container {
    padding: 0;
  }
  .intro-content.con05 .txt {
    margin: 15.33% 0 10.66%;
  }
  .intro-content.con05 .capture-tab-area {
    margin-bottom: 4%;
    padding-bottom: 14.4%;
    background-image: url("../images/intro/mo/mo_05_tab_on1.png");
  }
  .intro-content.con05 .capture-tab-area.on2 {
    background-image: url("../images/intro/mo/mo_05_tab_on2.png");
  }
  .intro-content.con05 .capture-tab-area.on3 {
    background-image: url("../images/intro/mo/mo_05_tab_on3.png");
  }
  .intro-content.con05 .capture-tab-area.on4 {
    background-image: url("../images/intro/mo/mo_05_tab_on4.png");
  }
  .intro-content.con05 .capture-slide-box {
    padding: 0 14px;
  }
  .intro-content.con05 .capture-slide-pager {
    margin-bottom: 19.33%;
  }

  .intro-content.con06 .container {
    padding: 0;
  }
  .intro-content.con06 .tit {
    max-width: 100%;
    margin: 12.93% 0 3.33%;
  }
  .intro-content.con06 .txt {
    max-width: 74%;
    margin-bottom: 8%;
  }
  .intro-content.con06 .review-slider {
    margin-bottom: 11.06%;
  }
  .intro-content.con06 .review-slider .swiper-slide {
    padding: 0;
  }
  .intro-content.con06 .review-slider .swiper-button-prev,
  .intro-content.con06 .review-slider .swiper-button-next {
    top: calc(50% + 25px);
  }
  .intro-content.con06 .review-slider .swiper-button-prev {
    left: 40px;
  }
  .intro-content.con06 .review-slider .swiper-button-next {
    right: 40px;
  }
  /* // 2023-03-23 라이브올이란 개편 */

  .subVisual .container {
    height: 135px;
    padding: 0 6.66%;
  }
  .subVisual .container .txt-box .tit {
    font-size: 17px;
    line-height: 25px;
  }
  .subVisual .container .txt-box .tit b {
    font-size: 23px;
  }
  .subVisual .container .txt-box .txt {
    margin-top: 2px;
    font-size: 14px;
    line-height: 20px;
  }
  .together .subVisual .container {
    background-position: right 2.66% bottom;
    background-size: 172px;
  }
  .together .subVisual .container .txt-box br {
    display: block;
  }

  /* 목록 필터링 콤보박스 */
  .list-sort-slt-area.MuiFormControl-root {
    padding: 0 0px;
  }
}

@media screen and (max-width: 480px) {
  .intro-content.con01 .container iframe {
    /* mo 595x338 */
    width: 79.6%;
    height: 34%;
    top: 48.3%;
    border-radius: 1vw;
  }

  .intro-content.con02 .scroll-ico {
    width: 30px;
    height: 52px;
  }

  .intro-content.con06 .review-slider .swiper-button-prev,
  .intro-content.con06 .review-slider .swiper-button-next {
    top: calc(50% + 20px);
    width: 18px;
    height: 33px;
  }
  .intro-content.con06 .review-slider .swiper-button-prev {
    left: 5.5%;
  }
  .intro-content.con06 .review-slider .swiper-button-next {
    right: 5.5%;
  }

  /* 목록 필터링 콤보박스 */
  .list-sort-slt-area.MuiFormControl-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 18px;
    padding: 0 10px;
  }
  /*.list-sort-slt-area .each {width: calc(50% - 16px); min-width: 130px;}*/
  .list-sort-slt-area.MuiFormControl-root .MuiInputBase-root {
    width: 120px;
    height: 30px;
  }
  .list-sort-slt-area.MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .readingbook .itemContainer,
  .best .itemContainer {
    padding: 0 20px;
  }
}
