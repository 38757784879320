
.slide-card-items-list-type.swiper{
    height: auto;
    /* padding: 5px 0 50px; */
}
.slide-card-items-list-type .swiper-slide{
    width: auto;
    background: none;
}

@media screen and (min-width: 401px) and (max-width: 749px) {

    .detailTabBox_Container .slide-card-items-list-type.detailTabList {
        width: 100%;
        display: flex;
        margin-bottom: 0;
    }

    .detailTabBox_Container .slide-card-items-list-type.detailTabList .swiper-slide {
        font-size: 0.9rem;
        padding: 5% 0;
        border-bottom: 2px solid #eaeaea;
    }
    
    .detailTabBox_Container .slide-card-items-list-type.detailTabList .swiper-slide.isOn {
        border-bottom: 4px solid #ff5651;
    }

}

@media screen and (max-width: 400px) {
    .detailTabBox_Container .slide-card-items-list-type.detailTabList {
        width: 100%;
        display: flex;
        margin-bottom: 0;
    }

    .detailTabBox_Container .slide-card-items-list-type.detailTabList .swiper-slide {
        font-size: 0.75rem;
        padding: 5% 0;
        border-bottom: 2px solid #eaeaea;
    }
    
    .detailTabBox_Container .slide-card-items-list-type.detailTabList .swiper-slide.isOn {
        border-bottom: 4px solid #ff5651;
    } 
}