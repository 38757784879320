@import "./style.scss";

article {
  display: flex;
  max-width: 1260px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  .card-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    .card-row {
      display: flex;
      width: 990px;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
    .card-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      border-radius: 8px;
      border: 1px solid rgb(219, 222, 226);
      box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 8px;
      overflow: hidden;
      cursor: pointer;
      transition-property: box-shadow, transform;
      transition-duration: 0.25s;
      transition-timing-function: ease-in-out;
      width: 235px;
      flex: 0 1 auto;
      margin-right: 16px;
      margin-bottom: 16px;

      &.sold-out {
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          opacity: 0.4;
          border: solid 1px #707070;
          background-color: #000;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }

        &::after {
          content: "";
          background-color: #000;
          border-radius: 0;
          border-bottom-left-radius: 6px;
          width: 80px;
          height: 35px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          font-size: 16px;
          font-family: "NotoSansBold";
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }
        .commingSoon-txt {
          font-size: 16px;
          line-height: 0.8;
          font-family: "NotoSansBold";
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          position: absolute;
          z-index: 4;
          right: 8px;
          top: 11px;
        }
      }
      &.sold-out-closed {
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          opacity: 0.4;
          background-color: #000;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
        &::after {
          content: "마감";
          border-radius: 0;
          border-bottom-left-radius: 6px;
          background-color: #000;
          width: 70px;
          height: 35px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          font-size: 16px;
          font-family: "NotoSansBold";
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }
        .commingSoon-txt {
          display: none;
        }
      }
      button {
        justify-content: flex-start;
        max-height: 214px;

        img {
          width: 100%;
        }

        .commingSoon-txt {
          display: none;
          /* 2024-02-23 나중에 바뀔 수 있음 
                    font-size: 16px;
                    line-height: 0.8;
                    font-family: "NotoSansBold";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    position: absolute;
                    z-index: 4;
                    right: 8px;
                    top: 11px;
                    */
        }
      }
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
    .card-content {
      &.top {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 0px;

        .card-cover {
          overflow: hidden;
          width: 100%;

          .observer {
            position: absolute;
            margin: 1px;
          }

          > img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }
      }

      &.footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding: 16px;
        background: rgb(255, 255, 255);
        border-radius: 0px 0px 6px 6px;
        z-index: 1;

        .card-info {
          display: inline-block;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          font-weight: 700;
          font-size: 1rem;
          line-height: 23px;
          letter-spacing: -0.5px;
          color: rgb(16, 28, 51);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &.top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }

          &.bottom {
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
          }

          .tag-list {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 0;

            > li {
              position: relative;
              font-size: 0.7rem;
              font-family: "NotoSansBold";
              padding: 0 15px 0 0;
              text-align: center;
              color: #1c1c1c;

              &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 8px;
                right: 7px;
                bottom: 7px;
                background-color: #1c1c1c;
              }

              &:last-child::after {
                content: none;
              }
            }
          }

          .favorite-count-icon {
            display: flex;
            align-items: center;
            flex-direction: row;
            img {
              display: block;
              margin-right: 5px;
              width: 26px;
              height: 22px;
            }

            .num {
              font-size: 12px;
              font-family: "NotoSansBold";
              color: #1c1c1c;
            }
          }

          .info-teacher {
            display: flex;
            justify-content: start;
            align-items: center;

            .teacher-img {
              display: flex;
              width: 40px;
              height: 40px;
              border: none;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 10px;
              flex-direction: column;
            }

            .teacher_name {
              > p {
                font-size: 14px;
                font-family: "NotoSansMedium";
                color: #1c1c1c;
                margin-bottom: 5px;
                font-weight: 600;
                text-align: left;
                cursor: pointer;
                display: block;
                width: 80px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .teacher-rate {
                cursor: default;
                display: flex;
                flex-direction: row;
                align-items: center;

                > img {
                  width: 15px;
                  height: 14px;
                  margin: 0 3.5px 1px 0;
                }

                > p {
                  font-family: "NotoSansMedium";
                  color: #1c1c1c;
                  font-size: 14px;
                  margin: 0;
                  font-weight: 700;
                }
              }
            }
          }

          .info-price {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            min-width: 28%;
            .base-price {
              font-family: "NotoSansBold";
              font-size: 14px;
              color: #666;
              text-decoration: line-through;
              position: absolute;
              top: -20px;
            }
            .discount {
              font-family: "NotoSansBold";
              font-size: 14px;
              color: #ff5651;
              position: absolute;
              left: -36px;
            }
            .price {
              font-family: "NotoSansBold";
              font-size: 18px;
              letter-spacing: -0.75px;
              color: #1c1c1c;
              display: flex;
              align-items: inherit;
              > span {
                font-family: "NotoSansMedium";
                font-weight: 500;
                font-size: 16px;
                margin-left: 2px;
              }
            }
          }
        }

        .class-name {
          font-size: 1rem;
          font-family: "NotoSansBold";
          color: #1c1c1c;
          line-height: 1.6;
          text-align: left;
          margin-bottom: 20px;
          cursor: pointer;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: pre-line;
          word-break: break-all;
          overflow: hidden;
          min-height: 52px;
        }
      }
    }
    > .swiper {
      max-width: 1260px;
      &.card-row {
        width: 100%;
      }
      .swiper-wrapper {
        .swiper-slide {
          .card-container {
            width: 210px;
            > button {
              max-height: 191px;
            }
          }
          .card-content {
            &.footer {
              padding: 10px;
              .card-info {
                &.top {
                  margin-bottom: 10px;
                }
                .tag-list {
                  > li {
                    font-size: 0.6rem;
                  }
                }
                .info-teacher {
                  .teacher_name,
                  .teacher-rate {
                    > p {
                      font-size: 0.7rem;
                    }
                  }
                }
                .info-price {
                  .price,
                  span {
                    font-size: 0.9rem;
                  }
                  .base-price,
                  .discount {
                    font-size: 0.8rem;
                  }
                }
              }
              .class-name {
                font-size: 0.8rem;
                margin-bottom: 5px;
                min-height: 41px;
              }
            }
          }
        }
      }
    }

    &.bookMark {
      .card-row {
        width: 725px;
      }
      .card-container {
        margin-right: 10px;
        margin-bottom: 10px;

        &:nth-of-type(4n) {
          margin-right: 10px;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .groupContainer {
    .card-list {
      h1 {
        font: 1.5rem/1.33 "NotoSansBold";
        color: #222;
        margin: 15px 0;
      }
    }
  }

  .categoryContainer {
    h1 {
      width: 100%;
      margin: 3% 0;
      font: 1.5rem/25px "NotoSansBold";
      color: #1c1c1c;
    }

    .card-tab {
      display: flex;
      width: 100%;

      &.fixed {
        position: fixed;
        top: 243px; // 180px;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        max-width: 1260px;
        z-index: 5;
      }

      > li {
        width: 100%;
        height: 67px;
        text-align: center;
        border-bottom: 2px solid #eaeaea;
        font: 20px/65px "NotoSansMedium";
        letter-spacing: -0.5px;
        color: #666;
        cursor: pointer;

        &.isOn {
          font: 20px/65px "NotoSansBold";
          color: #222;
        }
        &.isOn::after {
          content: "";
          display: block;
          width: 100%;
          height: 4px;
          background-color: #ff5651;
          position: relative;
          bottom: 2px;
        }
      }
    }
    .button-tab {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 990px;
      min-height: 60px;
      background: #fff;
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
      padding: 10px 0;
      z-index: 9;
      position: -webkit-sticky;
      position: sticky;
      top: 179px;
      left: 0;
      button {
        margin: 5px 15px;
        text-align: center;
        font: 1.2rem/1.33 "NotoSansMedium";
        letter-spacing: -0.5px;
        color: #666;
        cursor: pointer;

        &.isOn {
          font: 1.2rem/1.33 "NotoSansBold";
          color: #ff5651;
        }
        /* &.isOn::after {
                    content: '';
                    display: block;
                    width: 100%; height: 4px;
                    background-color: #ff5651;
                    position: relative;
                    bottom: 2px;
                } */
      }
    }

    .card-list {
      text-align: left;

      > h1 {
        color: #1c1c1c;
        font: 1.5rem/1.33 NotoSansBlack;
        letter-spacing: -0.75px;
        margin: 2% 1%;
        text-align: left;
      }
    }
  }
}

.header.top-banner + .sub-wrap article .categoryContainer .card-tab.fixed,
.header.top-banner + .sub-wrap article .categoryContainer .button-tab.fixed {
  top: 243px;
}
.header + .sub-wrap article .categoryContainer .card-tab.fixed,
.header + .sub-wrap article .categoryContainer .button-tab.fixed {
  top: 179px;
}

.header.top-banner + .sub-wrap {
  article {
    .categoryContainer {
      .button-tab {
        top: 243px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  article {
    max-width: 1023px;
    .card-list {
      .card-row {
        width: 692px;
      }
      .card-container {
        width: 220px;
        > button {
          max-height: 200px;
        }

        &:nth-of-type(3n) {
          margin-right: 0;
        }
        &:nth-of-type(4n) {
          margin-right: 16px;
        }
        &:nth-of-type(12n) {
          margin-right: 0;
        }
      }
      .card-content {
        &.footer {
          padding: 10px;
          .card-info {
            &.top {
              margin-bottom: 10px;
            }
            .tag-list {
              > li {
                font-size: 0.6rem;
              }
            }
            .info-teacher {
              .teacher_name {
                > p {
                  font-size: 0.7rem;
                }

                .teacher-rate {
                  > p {
                    font-size: 0.7rem;
                  }
                }
              }
            }
            .info-price {
              .price,
              span {
                font-size: 0.9rem;
              }
              .base-price,
              .discount {
                font-size: 0.8rem;
              }
            }
          }
          .class-name {
            font-size: 0.8rem;
            margin-bottom: 5px;
            min-height: 41px;
          }
        }
      }

      &.bookMark {
        .card-row {
          width: 680px;
        }
        .card-container {
          // width: 185px;
          margin-right: 10px;
          margin-bottom: 10px;

          &:nth-of-type(4n) {
            margin-right: 10px;
          }
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
    .groupContainer {
      .card-list {
        h1 {
          font: 1.5rem/1.33 "NotoSansBold";
          color: #222;
          margin: 15px 0;
        }
      }
    }
    .categoryContainer {
      .card-tab {
        &.fixed {
          top: 55px;
        }
        > li {
          font: 1rem/53px "NotoSansMedium";
          height: 55px;
          &.isOn {
            font: 1rem/53px "NotoSansBold";
          }
        }
      }
      .button-tab {
        &.fixed {
          top: 55px;
        }

        button {
          font: 1rem/1.33 "NotoSansMedium";

          &.isOn {
            font: 1rem/1.33 "NotoSansBold";
          }
        }
      }
      h1 {
        font: 1.2rem/25px "NotoSansBold";
      }
    }
  }
}

@media screen and (min-width: 540px) and (max-width: 767px) {
  article {
    max-width: 767px;
    .card-list {
      .card-row {
        width: 456px;
      }
      .card-container {
        width: 220px;
        > button {
          max-height: 200px;
        }

        &:nth-of-type(2n) {
          margin-right: 0;
        }
        &:nth-of-type(3n) {
          margin-right: 16px;
        }
        &:nth-of-type(6n) {
          margin-right: 0;
        }
      }
      .card-content {
        &.footer {
          padding: 10px;
          .card-info {
            &.top {
              margin-bottom: 10px;
            }
            .tag-list {
              > li {
                font-size: 0.6rem;
              }
            }
            .info-teacher {
              .teacher_name {
                > p {
                  font-size: 0.7rem;
                }

                .teacher-rate {
                  > p {
                    font-size: 0.7rem;
                  }
                }
              }
            }
            .info-price {
              .price,
              span {
                font-size: 0.9rem;
              }
              .base-price,
              .discount {
                font-size: 0.8rem;
              }
            }
          }
          .class-name {
            font-size: 0.8rem;
            margin-bottom: 5px;
            min-height: 41px;
          }
        }
      }

      &.bookMark {
        .card-row {
          width: 450px;
        }
        .card-container {
          margin-right: 10px;
          margin-bottom: 10px;

          &:nth-of-type(2n) {
            margin-right: 0;
          }
          &:nth-of-type(3n) {
            margin-right: 10px;
          }
          &:nth-of-type(6n) {
            margin-right: 0;
          }
        }
      }
    }
    .groupContainer {
      .card-list {
        h1 {
          font: 1.5rem/1.33 "NotoSansBold";
          color: #222;
          margin: 15px 0;
        }
      }
    }
    .categoryContainer {
      .card-tab {
        &.fixed {
          top: 55px;
        }
        > li {
          font: 0.9rem/53px "NotoSansMedium";
          height: 55px;
          // display: -webkit-box;
          &.isOn {
            font: 0.9rem/53px "NotoSansBold";
          }
        }
      }
      .button-tab {
        &.fixed {
          top: 55px;
        }

        button {
          font: 1rem/1.33 "NotoSansMedium";

          &.isOn {
            font: 1rem/1.33 "NotoSansBold";
          }
        }
      }
      h1 {
        font: 1.2rem/25px "NotoSansBold";
      }
    }
  }
}

@media screen and (min-width: 381px) and (max-width: 539px) {
  article {
    max-width: 539px;
    .card-list {
      .card-row {
        width: 325px;
      }
      .card-container {
        width: 155px;
        > button {
          max-height: 141px;
        }

        margin-right: 10px;
        margin-bottom: 10px;

        &:nth-of-type(2n) {
          margin-right: 0;
        }
        &:nth-of-type(3n) {
          margin-right: 10px;
        }
        &:nth-of-type(6n) {
          margin-right: 0;
        }
      }
      .card-content {
        &.footer {
          padding: 10px;
          .card-info {
            &.top {
              margin-bottom: 10px;
            }
            &.bottom {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: stretch;
            }
            .tag-list {
              > li {
                font-size: 0.6rem;
              }
            }
            .info-teacher {
              margin-bottom: 20px;
              .teacher_name {
                > p {
                  font-size: 0.7rem;
                }
                .teacher-rate {
                  > p {
                    font-size: 0.7rem;
                  }
                }
              }
            }
            .info-price {
              .price,
              span {
                font-size: 0.9rem;
              }
              .base-price {
                font-size: 0.8rem;
              }
              .discount {
                font-size: 1rem;
                left: 0;
              }
            }
          }
          .class-name {
            font-size: 0.8rem;
            margin-bottom: 5px;
            min-height: 41px;
          }
        }
      }

      &.bookMark {
        .card-row {
          width: 325px;
        }
        .card-container {
          margin-right: 10px;
          margin-bottom: 10px;

          &:nth-of-type(2n) {
            margin-right: 0;
          }
          &:nth-of-type(3n) {
            margin-right: 10px;
          }
          &:nth-of-type(6n) {
            margin-right: 0;
          }
        }
      }
    }
    .groupContainer {
      .card-list {
        h1 {
          font: 1.5rem/1.33 "NotoSansBold";
          color: #222;
          margin: 15px 0;
        }
      }
    }
    .categoryContainer {
      .card-tab {
        &.fixed {
          top: 55px;
        }
        > li {
          font: 0.65rem/43px "NotoSansMedium";
          height: 45px;
          &.isOn {
            font: 0.65rem/43px "NotoSansBold";
          }
        }
      }
      .button-tab {
        &.fixed {
          top: 55px;
        }

        button {
          font: 1rem/1.33 "NotoSansMedium";

          &.isOn {
            font: 1rem/1.33 "NotoSansBold";
          }
        }
      }
      h1 {
        font: 1.3rem/25px "NotoSansBold";
      }
    }
  }
}

@media screen and (max-width: 380px) {
  article {
    max-width: 539px;
    .card-list {
      .card-row {
        width: 318px;
      }
      .card-container {
        width: 156px;
        > button {
          max-height: 141px;
        }
        margin-right: 5px;

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
      .card-content {
        &.footer {
          padding: 7px;
          .card-info {
            &.top {
              margin-bottom: 0;
            }
            &.bottom {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: stretch;
            }
            .tag-list {
              > li {
                font-size: 0.6rem;
              }
            }
            .info-teacher {
              margin-bottom: 20px;
              .teacher_name {
                > p {
                  font-size: 0.7rem;
                  margin-bottom: 0;
                }
                .teacher-rate {
                  > p {
                    font-size: 0.7rem;
                  }
                }
              }
            }
            .info-price {
              .price,
              span {
                font-size: 0.9rem;
              }
              .base-price {
                font-size: 0.8rem;
              }
              .discount {
                font-size: 0.8rem;
                left: 5px;
              }
            }
          }
          .class-name {
            font-size: 0.8rem;
            margin-bottom: 0;
            min-height: 41px;
          }
        }
      }

      &.bookMark {
        .card-row {
          width: 318px;
        }
        .card-container {
          margin-right: 5px;

          &:nth-of-type(2n) {
            margin-right: 0;
          }
          &:nth-of-type(3n) {
            margin-right: 5px;
          }
          &:nth-of-type(6n) {
            margin-right: 0;
          }
        }
      }
    }
    .groupContainer {
      .card-list {
        h1 {
          font: 1rem/1.33 "NotoSansBold";
          color: #222;
          margin: 15px 0;
        }
      }
    }
    .categoryContainer {
      .card-tab {
        &.fixed {
          top: 55px;
        }
        > li {
          font: 0.55rem/43px "NotoSansMedium";
          height: 45px;
          &.isOn {
            font: 0.55rem/43px "NotoSansBold";
          }
        }
      }
      .button-tab {
        &.fixed {
          top: 55px;
        }

        button {
          font: 1rem/1.33 "NotoSansMedium";

          &.isOn {
            font: 1rem/1.33 "NotoSansBold";
          }
        }
      }
      h1 {
        font: 1rem/25px "NotoSansBold";
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  article {
    .categoryContainer {
      .button-tab {
        top: 104px;
      }
    }
  }

  .top-banner + .sub-header + .sub-wrap {
    article {
      .categoryContainer {
        .button-tab {
          top: 168px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .top-banner + .sub-header + .sub-wrap {
    article {
      .categoryContainer {
        .button-tab {
          top: 168px;
        }
      }
    }
  }
}

@media screen and (max-width: 539px) {
  .top-banner + .sub-header + .sub-wrap {
    article {
      .categoryContainer {
        .button-tab {
          top: 168px;
        }
      }
    }
  }
}
