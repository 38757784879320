@import '../etc/variables.scss';

.schedule-wrap {
    padding: 0 20px;
    .wrap-title {
        padding-left: 30px;
        color: #1c1c1c;
        font-family: "NotoSansBold";
        line-height: 24px;
        text-align: left;
        background: url('../../images/common/calendar2.png') 0 2px/20px no-repeat;
    }
    .notice {
        padding: 3px 0;
        p {
            color: #666;
            font-size: 12px;
            letter-spacing: -0.3px;

            &:nth-child(1) {
                color: #FC5651;
                font-weight: bold;
            }

            span {
                text-decoration: underline;
            }
        }
      
    }

    .day-btn-area {
        display: flex;
        margin-top: 10px;

        button {
            flex: 1;
            height: 36px;
            padding: 0 5px;
            border-color: #1c1c1c;
            border-radius: 6px;
            color: #1c1c1c;
            font-family: "NotoSansBold";
            font-size: 16px;

            &.active {
                background-color: #f8ce40;
            }
            &.disabled {
                border-color: #afb4b2;
                color: #afb4b2;
                pointer-events: none;
            }
        }
    }
    .slt-area {
        margin-top: 10px;
        
        .cell {
            flex: 1;
            height: 35px;
            line-height: 35px;
            color: #1c1c1c;
            font-family: "NotoSansMedium";
            font-size: 14px;
            text-align: center;
            letter-spacing: -0.4px;

            &:nth-child(3) {
                flex-grow: 1.6;
            }
            .MuiRadio-root {
                padding: 0;
                color: #afb4b2;

                &.Mui-checked {
                    color: #1c1c1c;
                }
            }

            & > label {
                margin-left: 0;
                margin-right: 0;
            }

        }

        .thead {
            .cell {
                background-color: #0e7fe0;
                color: #fff;
                font-family: "NotoSansBold";
                font-size: 15px;
            }
        }
        .tbody {
            border: 2px solid #0e7fe0;
            .row {
                &.active {
                    background-color: #f8ce40;
                }
                &.disabled {
                    background-color: #f0f0f0;
                    .cell {
                        color: #afb4b2;
                    }
                }
            }
            .MuiFormGroup-root {
                .MuiFormControlLabel-root {
                    margin: 0;
                    &.isChecked {
                        background-color: #f8ce40;
                    }
                    &.disabled {
                        background-color: #f0f0f0;
                        .MuiFormControlLabel-label .cell {
                            color: #afb4b2;
                        }
                    }
                    .MuiFormControlLabel-label {
                        width: 100%;
                        display: flex;

                        .cell {
                            flex: 1;
                            height: 35px;
                            line-height: 35px;
                            color: #1c1c1c;
                            font-family: "NotoSansMedium";
                            font-size: 14px;
                            text-align: center;
                            letter-spacing: -0.4px;

                            &:nth-child(2) {
                                flex-grow: 1.6;
                            }
                            .MuiRadio-root {
                                color: #afb4b2;
        
                                &.Mui-checked {
                                    color: #1c1c1c;
                                }
                            }
    
                            & > label {
                                margin-left: 0;
                                margin-right: 0;
                            }
    
                        }
                    }
                    .MuiRadio-root {
                        padding: 9px 38px;
                    }
                }
            }
        }
        
    }
}

@media screen and (max-width: 1023px) {
    
}