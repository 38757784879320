.detailTabBox_Container {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto;
	padding: 10px 60px 60px;
	/* padding: 10px 0 0; */
	background-color: #fff;
	border-radius: 15px;
}

.detailTabList {
	width: 100%;
	display: flex;
}

.detailTabList li {
	/* width: 50%; */
    width: 100%;
	height: 67px;
	text-align: center;
	border-bottom: 2px solid #eaeaea;
	font: 20px/65px 'NotoSansMedium';
	letter-spacing: -0.5px;
	color: #666;
	cursor: pointer;
}
.detailTabList li.isOn {
	font-family: 'NotoSansBold';
	color: #222;
}
.detailTabList li.isOn::after {
	content: '';
	display: block;
	width: 100%;
	height: 4px;
	background-color: #ff5651;
	position: relative;
	bottom: 2px;
}

.detailTabBox_wrap {
	width: 100%;
	text-align: left;
}


/* .detailTabBox_wrap li {height: 700px;} */
.detailTabList.fixed {
    position: fixed;
    top: 180px;
    background: #fff;
    max-width: 1260px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    padding: 0 60px 0;
}

.detailTabList.fixed li {
    width: 45%;
}

@media screen and (max-width:1023px) {
	.detailTabBox_wrap {padding: 0 25px 25px;word-break: break-all;}
	.detailTabBox_Container {padding: 0;border-radius: 0;}
    .detailTabList.fixed { top: 55px; padding: 0;}
	.detailTabList li {
		/* width: 100%; */
		height: auto;
		font: 14px/1.4 "NotoSansMedium";
		word-break: keep-all;
	}
	.detailTabList li.isOn::after {
		content: none;
	}

}