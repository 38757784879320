.pagination-type {
	margin-top: 40px;
}
.pagination-list {
	display: flex;
	justify-content: center;
	align-items: center;

    li:not(:last-child, :first-child) button {
        background-color: #eaeaee;
        color: #666666;
        font: bold 16px/1.43 'NotoSansBlack';
        margin: 0 3px;
        border-radius: 16px;
    }

    li button.Mui-selected {
        background-color: #2f99f4;
        color: #fff;
    }
}
	
@media screen and (max-width:1023px) {
	.pagination-type {
        margin-top: 30px;
        margin-bottom: 40px;
    }

	.pagination-list {
        li button.MuiButtonBase-root{    
            min-width: 24px;
            height: 24px;
        }

        li:not(:last-child, :first-child) button {
            font: bold 12px/1.43 'NotoSansBlack';
        }
    }
}