@import '../etc/variables.scss';
//////// 팝업 레이아웃
.modal-pop-wrap {
    .MuiBackdrop-root {
        background-color: $modalBg;
    }
    .pop-close-x-button {
        position: absolute;
        right: 0;
        top: 12px;
        min-width: 64px;
        padding: 6px 8px;
    }

    &.pop-wrap-profileEdit {
        .modal-pop-contents {
            background: $papperBg;
            border: none;
            border-radius: 15px;
            height: auto;

            .popTitle {
               border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                line-height: 3.2;
                font-size: 22px;
                padding: 0 25px;
            }
            .popContents {
                padding: 25px 40px;
                height: auto;
            }
            .popBottom {
                padding: 0;
            }
        }
    }
    &.pop-wrap-changeSchedule {
        .modal-pop-contents {
            background: $papperBg;
            border: none;
            border-radius: 15px;
            padding: 0;
            width: auto;
            min-width: 500px;
            height: auto;
            .popTitle {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                line-height: 3.2;
                font-size: 22px;
                padding: 0 25px;
            }
            .popContents {
                padding: 25px;
                height: auto;
                display: flex;
                flex-direction: column;

                .guide-tit {
                    margin-bottom: 10px;
                    // padding-left: 30px;
                    color: #1c1c1c;
                    font-family: "NotoSansBold";
                    line-height: 1.6;
                    text-align: left;

                    &.cal {
                        background: url('../../images/common/calendar2.png') 0 2px/20px no-repeat;
                    }
                    span {
                        color: $red;
                        text-decoration-line: underline;
                    }
                }
                .btnBox {
                    display: flex;
                    // margin-top: 15px;
                    justify-content: space-between;
                    
                    .changeSchedule-btn {
                        margin-right: 0;
                        width: 220px; height: 55px;
                    }
                }
            
            }
            .popBottom {
                padding: 0;
            }
        }
    }
    &.pop-wrap-inquiry-create,
    &.pop-wrap-inquiry-edit {
        .modal-pop-contents {
            width: auto;
            border: none;
            .popContents {
                overflow-y: hidden;
                height: auto;
            }
        }
    }
    &.pop-wrap-changeScheduleTable {
        .modal-pop-contents {
            border: none;
            border-radius: 15px;

            .popTitle {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                line-height: 3.2;
                font-size: 22px;
                padding: 0 25px;
            }

            .popContents {
                overflow-y: hidden;
                height: auto;
            }
        }
    }
    .modal-pop-contents {
        background: $papperBg;
        border: $borderWidth solid #2f99f4;
        padding: 0;
        min-width: 600px;
        width: auto;
        .popTitle {
            //팝업 header
            background: #2f99f4;
            color: $white;
            padding: 0 40px;
            font-size: $h4;
            height: 70px;
            line-height: 70px;
            font-weight: $bold;
        }
        .popContents {
            //팝업 contents
            padding: 30px 50px 0 38px;
            overflow-y: auto;
            height: 600px;
            // 2022-08-08 작은 화면에서 팝업 너무 커 사이즈 조정 후 스크롤 추가 완료
        }
        .popContents.fullContent {
            //팝업 contents
            padding: 0;
            overflow-y: auto;
            height: auto;
        }
        .popBottom {
            //팝업 footer
            padding: 46px 0 40px;
        }
    }
    /* MainModalPopup 추가*/
    .mainModal-pop-contents {
        background: $papperBg;
        // border: $borderWidth solid #2f99f4;
        padding: 0;
        // width: auto;
        // min-width:280px;
        outline: none;
        .popTitle {
            //팝업 header
            //background: #2f99f4;
            color: $white;
            padding: 0 40px;
            font-size: $h4;
            //height: 70px;
            line-height: 70px;
            font-weight: $bold;
        }
        .popContents {
            //팝업 contents
            //padding: 30px 50px 0 38px;
            overflow-y: auto;
            //height: 865px;

            .swiper {
                // width: 60vh; height: 84vh;
                // max-width: 500px; max-height: 700px;
            }
            .popup-banner.txt-area {
                background-color: #045091;
                background-size: 100% 100%;
                width: 100%; height: 100%;
                padding: 2%;
                .popup-banner-inner {
                    width: 100%; height: 94%;
                    overflow-y: auto;
                    padding: 3%;
                    text-align: left;
                    color: #fff;
                    line-height: 1.5em;
                }
            }
            .navigation-wrap {
                right: 20px; bottom: 15px;
            }
        }
        .popContents.fullContent {
            //팝업 contents
            padding: 0;
            overflow-y: auto;
            height: auto;
        }
        .popBottom {
            //팝업 footer
            padding: 0px 10px 0px 10px;
        }
    }
    &.showListModal {
        .modal-pop-contents .popContents {
            height: auto;
            min-height: 200px;
            // padding: 30px 50px 30px 38px;
            ul li {
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                color: #222;
                cursor: pointer;
                display: -webkit-box;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                width: 100%;
                line-height: 2;
            }
        }
    }
}

////////  팝업 버튼 정리
.popBottom {
    // 버튼 감싸는 렙
    text-align: center;
    .pop-button {
        // 기본 버튼 세팅
        height: 48px;
        line-height: 1;
        display: inline-block;
        vertical-align: top;
        color: #1c1c1c;
        border: 1px solid #1c1c1c;
        font-size: $btn-normal;
        font-weight: $bold;
        min-width: 202px;
        padding: 0 40px;
        margin: 0 5px;
        // line-height: 48px;
        font-family: "NotoSansBold";
        &.pop-button-round {
            //라운드형
            border-radius: 24px;
        }
        &.pop-button-type1 {
            //흰색
            background: $white;
        }
        &.pop-button-type2 {
            //포인트
            background: $yellow;
        }
    }
}
////// 메인 팝업 버튼
.main-pop-bottom {
    // 버튼 감싸는 렙
    text-align: center;
    .pop-button {
        // 기본 버튼 세팅
        height: 48px;
        line-height: 1;
        display: inline-block;
        vertical-align: top;
        color: #1c1c1c;
        //border: 1px solid #1c1c1c;
        font-size: 16px;
        font-weight: $bold;
        width:50%;
        //min-width: 202px;
        //padding: 0 40px;
        //margin: 0 46px;
        // line-height: 48px;
        font-family: "NotoSansBold";
        &.pop-button-round {
            //라운드형
            border-radius: 24px;
        }
        //&.pop-button-type1 {
        //    //흰색
        //    background: $white;
        //}
        //&.pop-button-type2 {
        //    //포인트
        //    background: $yellow;
        //}
        &:hover {
            background: #dbf0ff;
        }
    }
}


//  ********************************************************************************************************************************************************************************************
//  ********************************************************************************************************************************************************************************************
//  ************************************************************************************* 모바일 시작 *******************************************************************************************
//  ************************************************************************************* max 1023px *******************************************************************************************
//  ********************************************************************************************************************************************************************************************
//  ********************************************************************************************************************************************************************************************
@include mobile {
    //////// 팝업 레이아웃
    .modal-pop-wrap {
        &.no-padding {
            .modal-pop-contents {
                .popContents {
                    padding: 20px;
                    .categoryClassInfo {
                        padding: 0;
                    }
                }
            }
        }
        .MuiBackdrop-root {
            background-color: $modalBg;
        }
        .pop-close-x-button {
            position: absolute;
            right: 0;
            top: 12px;
        }
        .modal-pop-contents {
            min-width: auto;
            width: 100%;
            height: 100%;

            .popTitle {
                //팝업 header
                background: #2f99f4;
                color: $white;
                padding: 0 0;
                font-size: $h4_2;
                height: 56px;
                line-height: 56px;
                text-align: center;
            }
            .popContents {
                //팝업 contents
                padding: 20px 0 0;
                overflow-y: auto;
                height: calc(100vh - 63px);
                max-height: 89vh;
                // height: calc(100vh - 70px);
            }
            .popBottom {
                //팝업 footer
                // padding: 20px 0 40px;
                padding: 20px 0 25%;
            }


        }
        &.showListModal {
            .modal-pop-contents .popContents {
               
                ul  {padding: 0 10px;
                    li {
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: #222;
                        cursor: pointer;
                        display: -webkit-box;
                        overflow: hidden;
                        text-align: left;
                        text-overflow: ellipsis;
                        width: 100%;
                        line-height: 2;
                    }
                }
            }
        }
        /* MainModalPopup 추가*/
        .mainModal-pop-contents {
            background: $papperBg;
            //border: $borderWidth solid #2f99f4;
            padding: 0;
            // min-width: 600px;
            // width: auto;
            .popTitle {
                //팝업 header
                //background: #2f99f4;
                color: $white;
                padding: 0 40px;
                font-size: $h4;
                //height: 70px;
                line-height: 70px;
                font-weight: $bold;
            }
            .popContents {
                //팝업 contents
                //padding: 30px 50px 0 38px;
                overflow-y: auto;
                //height: 865px;
                // 2022-08-08 작은 화면에서 팝업 너무 커 사이즈 조정 후 스크롤 추가 완료
            }
            .popContents.fullContent {
                //팝업 contents
                padding: 0;
                overflow-y: auto;
                height: auto;
            }
            .popBottom {
                //팝업 footer
                padding: 0px 10px 0px 10px;
            }
        }



        &.pop-wrap-profileEdit,
        &.pop-wrap-changeSchedule,
        &.pop-wrap-inquiry-create,
        &.pop-wrap-inquiry-edit,
        &.pop-wrap-changeScheduleTable {
            .modal-pop-contents {
                width: 100%;
            }
        }

        &.pop-wrap-changeSchedule {
            .pop-close-x-button {
                top: 6px;
            }
            .modal-pop-contents {
                min-width: 300px;
                width: 300px;
                .popTitle {
                    line-height: 3.4;
                    font-size: 18px;
                    text-align: left;
                    padding: 0 20px;
                }
                .popContents {
                    padding: 20px;
                    .guide-tit {
                        font-size: 0.95rem;
                        padding-left: 0;
                    }
                    .popBottom {
                        .pop-button {
                            height: 35px;
                        }
                    }
                    .btnBox {
                        .changeSchedule-btn {
                            margin-right: 0;
                            width: 125px;
                            height: 55px;
                            font-size: 13px;
                            padding: 5px;
                        }
                    }
                }
            }
        }

        &.pop-wrap-profileEdit {
            .pop-close-x-button {
                top: 6px;
            }
            .modal-pop-contents {
                width: 300px;
                .popTitle {
                    height: 45px;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    line-height: 2.6;
                    font-size: 18px;
                    padding: 0 25px;
                    text-align: left;
                }
                .popContents {
                    padding: 20px;
                    .popBottom {
                        .pop-button {
                            height: 35px;
                        }
                    }
                }
            }
        }
    }
    ////////  팝업 버튼 정리
    .popBottom {
        padding-top: 20px;
        .pop-button {
            height: 44px;
            min-width: auto;
            width: 40%;
            padding: 0 10px;
            margin: 0 4px;
            line-height: 1;
            font-size: $h6_2;
        }
    }
    .classInquiry-info-wrap {
        flex-wrap: wrap;
    }
    .modal-mobile-title {
        font-size: $h7;
        color: $black;
        font-weight: $bold;
        display: block;
        margin-bottom: 8px;
        width: 100%;
    }
    ////// 메인 팝업 버튼
    .main-pop-bottom {
        // 버튼 감싸는 렙
        text-align: center;
        .pop-button {
            // 기본 버튼 세팅
            height: 30px;
            line-height: 1;
            display: inline-block;
            vertical-align: top;
            color: #1c1c1c;
            //border: 1px solid #1c1c1c;
            font-size: 10px;
            font-weight: $bold;
            width:50%;
            //min-width: 202px;
            //padding: 0 40px;
            //margin: 0 46px;
            // line-height: 48px;
            // font-family: "NotoSansBold";
            font-family: "NotoSansMedium";
            &.pop-button-round {
                //라운드형
                border-radius: 24px;
            }
            &.pop-button-type1 {
                //흰색
                background: $white;
            }
            &.pop-button-type2 {
                //포인트
                background: $yellow;
            }
        }
    }
}
