
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.footer-navi-icon {
    position: fixed;
    bottom: 95px; right: 10px;
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 10;

    .chat-icon-wrap {
        position: relative;
        cursor: pointer;
        width: 114px; right: -5px;
        margin-bottom: -5px;
        img {
            width: 100%; height: 100%;        
        }
    }
    .event-icon-wrap {
        position: relative;
        cursor: pointer;
        width: 114px; right: -5px;
        &.isOn {
            max-width: 140px;
            width: auto;
            z-index: -1;
            margin-bottom: 7px;
            right: 10px;
            &::before{
                content: none;
            }
            &::after {
                content: "";
                background: url(../../images/common/event-arrow-down.png) no-repeat;
                width: 36px;
                height: 32px;
                background-size: cover;
                top: -23px;
                right: 15px;
                z-index: -1;
            }

            img {
                width: 100%; height: 100%;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
                background: transparent;  
            }
        }
        &::after {
            content: "";
            position: absolute;
            background: url(../../images/common/event-arrow-up.png) no-repeat;
            width: 36px;
            height: 32px;
            background-size: cover;
            top: -12px;
            right: 15px;
            z-index: -1;
        }
        img {
            width: 100%; height: 100%;        
        }
    }

    .scrollTopBtn {
        align-items: center;
        background-color: #1c1c1c66;
        border-radius: 50%;
        box-shadow: 2px 4px 6px 0 #00000029;
        cursor: pointer;
        display: none;
        width: 40px; height: 40px;
        justify-content: center;
        margin-top: 15px;
        
        &.topActive {
            animation: fadeIn 1s;
            display: flex;
        }
    }
}

.CancelButton {
    background: #fff;
    border: 2px solid #2f99f4;
    border-radius: 20px;
    color: #1c1c1c;
    font-family: NotoSansMedium;
    font-size: 14px;
    height: 40px;
    margin-right: 10px;
    width: 114px;
}

.ApplyButton {
    background: #2f99f4;
    border-radius: 20px;
    color: #fff;
    font-family: NotoSansMedium;
    font-size: 14px;
    height: 40px;
    width: 114px;
    &:hover {
        background-color: #2f99f4;
    }
}