//////// 팝업 변수
$black: #000;
$white: #fff;
$yellow: #f3ca53;
$pointYellow: #f6f2e6;
$red: #ff5651;
$blue: #1499ed;
$mainYellow: #f8ce40;
// $pointColor: #0e2b49;
$pointColor: #0e2b49;
$newPointColor: #1c1c1c;
$modalBg: rgba(0, 0, 0, 0.5);
$papperBg: $white;
$calenderBlack: #222222;
$calenderGray: #afb4b2;
$calenderGray2: #eaeaea;
$calenderGray3: #f9f9f9;
$calenderGray4: #f3f3f3;
// *보더
$borderColor: $pointColor;
$borderWidth: 3px;

$gray1: #111;
$gray2: #222;
$gray3: #333;
$gray4: #444;
$gray5: #555;
$gray6: #666;
$gray7: #777;
$gray8: #888;
$gray9: #999;

$subcolor1: #363e4a;
//폰트사이즈
$h4: 24px;
$h4_2: 20px;
$h5: 18px;
$h6: 16px;
$h6_2: 15px;
$h7: 14px;
$h8: 12px;

//폰트굵기
$bold: bold;

//버튼 폰트사이즈
$btn-normal: 20px;

.ml0 {
    margin-left: 0 !important;
}

//반응형
$mobile: 1023px;
$desktop: 1024px;
$mobileSideSpace: 5%;
@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}
@mixin pc {
    @media (min-width: $desktop) {
        @content;
    }
}
@include mobile {
}
