.recomendClass_container {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 0 20px;
}

.recomendClass_container h1 {
    font: 24px/1.33 'NotoSansBlack'; /* 폰트크기수정 2022-07-13 */
    letter-spacing: -0.75px;
    color: #1c1c1c;
    margin-bottom: 20px;
    text-align: left;
}
.recomendClass_container h1 .purple {
    color: #2f99f4;
}
.recomendClass_container h1 .grapefruit {
    color: #ff5651;
}

.recomendClass_contents_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.pb70 {
    padding: 70px 0 0;
}
.recomendClass_container .card-item-root {
    width: 100%;
}
.recomendClass_container .swiper-button-next {
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 38px;
    height: 74px;
    font-size: 0;
    z-index: 2;
}
.recomendClass_container .swiper-button-prev {
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    width: 38px;
    height: 74px;
    font-size: 0;
    z-index: 2;
}
.recomendClass_container .swiper-button-prev:after {
    content: '';
    background: url(../../images/common/slide-prev-btn.png) no-repeat center center / 100%;
    width: 38px;
    height: 74px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
}
.recomendClass_container .swiper-button-next:after {
    content: '';
    background: url(../../images/common/slide-next-btn.png) no-repeat center center / 100%;
    width: 38px;
    height: 74px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
}
@media screen and (max-width: 1023px) {
    .recomendClass_container:first-child {
        padding: 30px 0 20px;
    }
    .teacherDetail .recomendClass_container {
        padding: 30px 0 20px;
    }
    .recomendClass_contents_wrap .slide-card-items-list-type.swiper {
        padding: 0 55px 0 0;
        /* padding: 0 10px 10px 0; */
    }
    .recomendClass_container h1 {
        font: 18px/1.33 'NotoSansBlack';
    }
    .recomendClass_container.itemContainer .recomendClass_contents_wrap img.MuiCardMedia-root {
        height: 100%;
    }
    .recomendClass_container.itemContainer .recomendClass_contents_wrap .card-item-root button.MuiButtonBase-root {height: 65.4vw;}
}
