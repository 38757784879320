@import "../etc/variables.scss";

.calendar-wrap {
  position: relative;
  .fc {
    width: 100%;
    // margin-bottom: 25px;

    .fc-view-harness {
      border: 2px solid #2f99f4;
    }
    .fc-col-header-cell-cushion {
      height: 40px;
      line-height: 40px;
      font-size: $h7;
      color: #1c1c1c;
    }
    .fc-toolbar.fc-header-toolbar {
      position: relative;
      background: $blue;
      margin-bottom: 0;
      text-align: center;
      height: 50px;
    }
    .fc-button-primary {
      background: none;
      border: none;

      &.fc-prev-button {
        margin-left: 15px;
      }

      // &.fc-next-button {
      //     margin-right: 15px;
      // }
    }
    .fc-toolbar-title {
      text-align: center;
      color: $white;
      font-size: $h5;
      font-weight: $bold;
    }
    .fc-day {
      &.fc-day-disabled {
        background: none;
      }
      .fc-daygrid-day-frame {
        align-items: stretch;
        display: flex;
        justify-content: center;
        .fc-daygrid-day-top {
          justify-content: center;
          align-self: center;
          position: relative;
          z-index: 2;
        }
        .fc-daygrid-day-number {
          color: $calenderGray;
          font-size: $h6;
          font-weight: $bold;
        }
      }
      &.event-calender-item {
        position: relative;
        .fc-daygrid-day-number {
          color: $calenderBlack;
          font-size: $h6;
        }
        .fc-daygrid-day-number::after {
          // border-radius: 100%;
          background: #1499ed;
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          bottom: -12px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          width: 8px;
          height: 8px;
          z-index: -2;
        }
      }
      &.fc-day-sun {
        .fc-col-header-cell-cushion {
          color: $red;
        }
        // .fc-daygrid-day-number {
        //     color: $red;

        // }
      }
      &.active {
        // border-radius: 100%;
        //     background: #1499ED;
        position: relative;
        ::before {
          // border-radius: 100%;
          background: #1499ed;
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 46px;
          height: 46px;
          z-index: -1;
        }

        .fc-daygrid-day-number {
          color: $white;
        }
        &.active2 {
          ::before {
            // border-radius: 100%;
            // background: #666;
            background-color: #1499ed;
          }
        }
      }
    }
    .fc-daygrid-day.fc-day-today {
      position: relative;
      background: none;
      > ::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 46px;
        height: 46px;
        transform: translate(-50%, -50%);
        border: 1px solid $calenderBlack;
      }
    }
    .fc-scrollgrid-liquid {
      border: none;
    }
  }
  .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }
  .fc-theme-standard th {
    background-color: #f8f8f8;
  }
  .fc-day-other {
    .fc-daygrid-day-top {
      display: none;
    }
  }
  .fc-scrollgrid-section-header {
    background: #f6f2e6;
  }
  .fc-day-sun .fc-col-header-cell-cushion,
  .fc-daygrid-day-bg {
    .fc-highlight {
      border-radius: 100%;
      background: #1499ed;
    }
  }

  .fc-daygrid-day-events {
    display: none;
    width: 1px;
    height: 1px;
    position: absolute;
    left: -999px;
    top: -999px;
  }
  .calender-title {
    font-size: $h6;
    color: $black;
    font-weight: $bold;
    margin-bottom: 20px;
    img {
      display: inline-block;
      vertical-align: top;
      margin-right: 7px;
    }
    &.calender-title2 {
      margin-top: 20px;
    }
  }
}
.bigcalender-wrap {
  max-width: 1260px;
  margin: 32px auto 0;
  .bigcalender-tab {
    text-align: left;
    position: relative;
    margin-bottom: 30px;
    .tab-button {
      border-radius: 0;
      border: none;
      width: 256px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 16px;
      color: #666;
      background: #f8f8f8;
      font-family: "NotoSansBold";
      border: 1px solid #eaeaea;
      z-index: 1;
      &.active {
        background: #fff;
        color: $gray2;
        font-weight: $bold;
        border: 3px solid #eaeaea;
        border-bottom: none;
        z-index: 3;
        font-size: 18px;
      }
    }
    &::after {
      content: "";
      background: #eaeaea;
      z-index: 2;
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .fc-dayGridWeek-view {
    .fc-view-harness {
      display: none;
    }
  }
}
.profile-wrap {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding: 0 30px;
  .profile {
    position: relative;
    width: 330px;

    .swiper {
      height: auto;
      padding: 0 3px;
      &.flexStart {
        .swiper-wrapper {
          justify-content: flex-start;
        }
      }
      &.flexEnd {
        .swiper-wrapper {
          justify-content: flex-end;
        }
      }
      .swiper-slide {
        background: none;
        width: auto;
        flex-direction: column;
        cursor: pointer;
        padding: 3px 0;
        .profile-item {
          .img {
            position: relative;
            img.chk {
              // filter: grayscale(1);
              position: absolute;
              top: 71%;
              left: 71%;
              z-index: 2;
              display: none;
              width: 20px;
              height: 20px;
            }
          }
          .profile-p {
            word-break: break-all;
            margin-top: 10px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            font-family: NotoSansRegular;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #1c1c1c;
          }
          &.active {
            .img {
              // border: 3px solid $pointColor;
              // background-color: $pointColor;
              // border-radius: 100%;
              img:not(.chk) {
                // outline: 3px solid $pointColor;
                border: 2px solid $newPointColor;
                border-radius: 100%;
              }
              .chk {
                display: block;
              }
            }
          }
        }
      }
      .swiper-slide:last-child {
        margin-right: 0 !important;
      }
    }
    .cal-slide-button {
      position: absolute;
      top: 50%;
      z-index: 5;
      transform: translateY(-50%);
      &.cal-slide-prev {
        left: -30px;
      }
      &.cal-slide-next {
        right: -30px;
      }
    }
  }
}
.calender-noti.MuiTypography-body1 {
  background: #fff;
  text-align: left;
  font-size: 12px;
  color: #666;
  font-family: NotoSansMedium;
}
// 빅켈린더
.bigcalender-cal {
  .cal-state-classDate {
    position: absolute;
    right: 40px;
    top: 24px;
    z-index: 9;
    font-size: $h6;
    color: $white;
    display: block;
    span {
      display: inline-block;
      vertical-align: top;
      background: $mainYellow;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: 10px;
    }
  }
  &.cal-week {
    background: $calenderGray3;
    padding-bottom: 8px;
    height: 233px;
    .fc {
      &.fc-theme-standard {
        height: 100%;
      }
      &.fc-theme-standard .fc-scrollgrid,
      &.fc-theme-standard td,
      &.fc-theme-standard th {
        border: none;
      }
      .fc-daygrid-body {
        height: 100%;
      }
      .fc-scrollgrid-sync-table {
        border: 3px solid $calenderGray2 !important;
        background-color: #fff;
      }

      .fc-day {
        .fc-daygrid-day-number {
          position: relative;
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $gray2;
          font-size: $h4;
          font-weight: normal;
        }
        > ::before {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.2s ease-in-out;
        }
        &.fc-day-sun {
          .fc-daygrid-day-number {
            color: $red;
          }
        }
        &.event-calender-item {
          .fc-daygrid-day-number {
            // color: $white;
            color: #1c1c1c;
            font-weight: bold;
          }
        }
      }
    }
  }

  &.cal-month {
    height: 820px;

    .fc {
      &.fc-theme-standard {
        height: 100%;
      }
      .fc-scrollgrid-sync-table {
        height: 100%;
      }
      .fc-daygrid-body {
        height: 100%;
      }
      .fc-daygrid-day.fc-day-today {
        > ::after {
          // left: auto;
          // right: 26px;
          // top: 49%;
          // transform: translate(0, 0);
        }
      }

      .fc-day {
        .fc-daygrid-day-number {
          padding: 20px 20px;
          text-align: center;
          width: 58px;
          > ::before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.event-calender-item {
          > ::before {
            position: absolute;
            right: 7px;
            top: 6px;
          }
        }
        &.active {
          > ::before {
            position: absolute;
            right: 0px;
            top: 0px;
          }
        }
        &.fc-day-sun {
          .fc-daygrid-day-number {
            color: $red;
          }
        }
        &.event-calender-item {
          .fc-daygrid-day-number {
            color: #1c1c1c;
          }
        }
      }
    }
  }
  margin-top: 20px;
  margin-bottom: 40px;
  position: relative;

  .fc {
    .fc-scrollgrid-section-header {
      .fc-col-header {
        background: #f8f8f8;
        height: 50px;

        .fc-scrollgrid-sync-inner {
          height: 100%;

          a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            line-height: 50px;
            color: #1c1c1c;
            font-weight: $bold;
          }
        }

        .fc-day-sun {
          .fc-scrollgrid-sync-inner {
            a {
              color: $red;
            }
          }
        }
      }
    }

    .fc-toolbar.fc-header-toolbar {
      background: #2f99f4;
      height: 70px;
      margin-bottom: 0;

      .fc-toolbar-chunk {
        position: relative;

        .fc-button {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          background: none;
          border: none;
          outline: none;

          &.fc-prev-button {
            left: -54px;
          }

          &.fc-next-button {
            right: -54px;
          }
        }
      }

      .fc-toolbar-title {
        color: $white;
        font-size: $h4;
        font-weight: $bold;
      }
    }

    .fc-daygrid-day.fc-day-today {
      background: none;

      > ::after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "";
        display: block;
        border: 1px solid $gray2;
        width: 100%;
        height: 100%;
        border-radius: none;
      }
    }

    .fc-daygrid-day-events {
      display: none !important;
    }

    .fc-day {
      .fc-daygrid-day-frame {
        cursor: pointer;
      }
      > ::before {
        border-radius: 100%;
        background: #1499ed;
        content: "";
        display: block;
        width: 0;
        height: 0;
        z-index: -1;
        transition: all 0.2s ease-in-out;
      }

      &.event-calender-item {
        .fc-daygrid-day-number {
          // color: $white;
          font-weight: bold;
        }

        > ::before {
          border-radius: 100%;
          background: #f8ce40;
          content: "";
          display: block;
          width: 46px;
          height: 46px;
          // z-index: -1;
          z-index: 0;
          transition: all 0.2s ease-in-out;
        }
      }

      &.active {
        .fc-daygrid-day-number {
          color: #1c1c1c;
        }

        > ::before {
          background: #f8ce40;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          // z-index: -1;
          z-index: 0;
          border-radius: 0;
        }
        &.active2 {
          ::before {
            // border-radius: 100%;
            background: #666;
          }
        }
      }
    }
  }
}

.cal-list-wrap {
  position: relative;
  z-index: 2;
  .cal-list-item {
    position: relative;
    width: calc(100% - 8px);
    border: 1px solid $newPointColor;
    background: #fff;
    padding: 30px;
    margin-bottom: 40px;
    // &::before {
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 8px;
    //     top: 8px;
    //     background: $pointColor;
    //     display: block;
    //     z-index: -1;
    // }
    .cal-list-p {
      text-align: left;
      margin-bottom: 10px;
      span {
        color: #1c1c1c;
        font-size: $h4;
        font-weight: $bold;
        font-family: "NotoSansBold";
        &.cal-list-ampm {
        }
        &.cal-list-time {
        }
      }
    }

    .cal-grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .profile-item {
        display: flex;
        align-items: center;
        p {
          font-size: 16px;
          font-weight: bold;
          // font-family: "NotoSansRegualr";
          color: #1c1c1c;
          margin-right: 10px;
        }
        div.img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .cal-list-info-wrap {
    display: flex;
    // align-items: flex-end;
    align-items: flex-start;
    > div {
    }
    .orderInfo-img {
      width: 154px;
      height: 142px;
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cal-list-text {
      // max-width: 486px;
      width: 486px;
      .card-content-wrap.MuiCardContent-root {
        padding-top: 0;
        padding-bottom: 0 !important;
      }
      .content-top + h3 {
        margin-top: 10px;
        margin-bottom: 12px;
      }
      .content-bottom {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        .teacher_name p {
          color: #1c1c1c;
          cursor: pointer;
          font-family: NotoSansMedium;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
          text-align: left;
        }
        .teacher-rate {
          // align-items: center;
          cursor: default;
          display: flex;
          font-family: NotoSansMedium;
          font-size: 14px;
          justify-content: start;
          img {
            width: 15px;
            height: 14px;
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }
    }
    .cal-list-button-wrap {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 6.5%;
      .heart-wrap {
        margin-bottom: 30px;
        .favorite-count-icon img {
          display: block;
          margin-bottom: 2px;
          width: 32px;
          height: 28px;
        }

        .cal-list-button-inner {
        }
      }
    }
  }
  .cal-list-button-inner {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    > a {
      margin-left: 5px;
    }
    > a:nth-child(1) {
      flex-grow: 2;
      margin-left: 0;
    }
    > a:nth-child(2) {
      flex-grow: 1;
    }
    > a:nth-child(3) {
      flex-grow: 1;
    }
    .cal-list-button {
      min-width: 170px;
      text-align: center;
      height: 60px;
      line-height: 60px;
      border: 1px solid $newPointColor;
      margin-left: 10px;
      border-radius: 6px;
      color: $newPointColor;
      font-weight: $bold;
      font-family: "NotoSansBold";
      font-size: $h6;
      &.cal-list-button-type1 {
      }

      &.cal-list-button-type2 {
        background: #2f99f4;
        border: 1px solid #2f99f4;
        color: $white;
        &.disabled-btn {
          border: 1px solid #9aaab7;
          color: #fff;
          background: #9aaab7;
          border-radius: 5px;
        }
      }

      &.cal-list-button-s1 {
        min-width: 190px;
        width: 190px;
      }
      > img {
        margin-right: 10px;
        width: 28px;
        height: 24px;
      }
    }
  }
}

.no-list {
  padding: 30px 0 50px;
  p {
    font-size: $h7;
    // margin-top: 20px;
    color: $gray6;
    font-family: NotoSansRegular;
    letter-spacing: -0.5px;
  }
}
.no-list:before {
  content: "";
  background: url("../../images/common/caution-icon.png") no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 33px;
  margin-bottom: 11px;
  width: 33px;
}

//  ********************************************************************************************************************************************************************************************
//  ********************************************************************************************************************************************************************************************
//  ************************************************************************************* 모바일 시작 *******************************************************************************************
//  ************************************************************************************* max 1023px *******************************************************************************************
//  ********************************************************************************************************************************************************************************************
//  ********************************************************************************************************************************************************************************************
@include mobile {
  $h4: 18px;
  $h4_2: 13px;
  $h5: 15px;
  $h6: 16px;
  $h7: 14px;
  $h8: 12px;
  .sub-wrap {
    // min-height: 100vh;
    min-height: 71vh;
    padding-bottom: 0;
  }
  .calendar-date-wrap {
    padding-bottom: 20px;
    width: calc(100% - ($mobileSideSpace * 2));
    margin: 0 auto;
    // border-bottom: 1px solid #eaeaea;
    .dayTime {
      padding: 0 0 8px;
    }
  }
  .calendar-date-wrap2 {
    width: 100%;
    .cal-date-time-wrap,
    .caldw-p {
      padding: $mobileSideSpace;
    }
  }
  .calendar-wrap {
    .fc {
      .fc-view-harness {
        border: 1px solid #eaeaea;
      }
      .fc-col-header-cell-cushion {
        height: 40px;
        line-height: 40px;
        font-size: $h7;
      }
      .fc-toolbar.fc-header-toolbar {
        position: relative;
        background: $blue;
        margin-bottom: 0;
        text-align: center;
        height: 50px;
        &.fc-toolbar {
          // justify-content: center;
        }
      }
      .fc-button-primary {
        background: none;
        border: none;

        // 2022-08-02 상품상세 팝업 내 가운데 정렬 때문에 삭제
        // &.fc-prev-button {
        //     margin-right: 30px;
        // }

        &.fc-next-button {
          // margin-left: 30px;
        }
      }
      .fc-toolbar-title {
        text-align: center;
        color: $white;
        display: inline-block;
        font-size: $h4;
        font-weight: $bold;
      }
    }
    .fc-scrollgrid,
    .fc-theme-standard td,
    .fc-theme-standard th {
      border: none;
    }
    .fc-day-other {
      .fc-daygrid-day-top {
        display: none;
      }
    }
    .fc-scrollgrid-section-header {
      background: #f6f2e6;
    }
    .fc-day-sun .fc-col-header-cell-cushion,
    .fc-daygrid-day-bg {
      .fc-highlight {
        border-radius: 100%;
        background: #1499ed;
      }
    }

    .fc-daygrid-day-events {
      display: none;
      width: 1px;
      height: 1px;
      position: absolute;
      left: -999px;
      top: -999px;
    }
    .calender-title {
      font-size: $h6;
      margin-bottom: 20px;
      padding-left: $mobileSideSpace;
      img {
        margin-top: 2px;
      }
      &.calender-title2 {
        margin-top: 20px;
      }
    }
  }
  .bigcalender-wrap {
    margin: 0 auto;
    height: 100%;
    min-height: 100vh;
    background: $calenderGray4;
    padding-bottom: 50px;
    .bigcalender-tab {
      margin-bottom: 0;
      background: $white;
      .tab-button {
        border: none;
        width: 50%;
        height: 50px;
        line-height: 50px;
        font-family: "NotoSansMedium";
        font-size: $h5;
        background: none;
        &.active {
          border: none;
          border-bottom: 4px solid $red;
          font-size: 15px;
        }
      }
      &::after {
        height: 1px;
      }
    }
  }
  .profile-wrap {
    justify-content: flex-start;
    padding: 20px 0;
    background: $white;
    .profile {
      width: 100%;
      .swiper {
        padding: 0 $mobileSideSpace;
        &.flexStart {
          .swiper-wrapper {
            justify-content: flex-start;
          }
        }
        &.flexEnd {
          .swiper-wrapper {
            justify-content: flex-start;
          }
        }
        .swiper-slide {
          background: none;
          width: auto;
          .profile-item {
            img {
              -webkit-filter: grayscale(0);
              filter: grayscale(0);
              width: 57px;
            }
            .profile-p {
              margin-top: 10px;
              font-weight: $bold;
            }
          }
        }
      }
      .cal-slide-button {
        display: none;
      }
    }
  }
  .calender-noti.MuiTypography-body1 {
    padding: 10px;
  }
  // 빅켈린더
  .bigcalender-cal {
    margin: 0;
    padding-top: 0;
    margin-bottom: 40px;
    .cal-state-classDate {
      right: 14px;
      top: 18px;
      font-size: $h8;
      span {
        width: 8px;
        height: 8px;
        margin-right: 5px;
        margin-top: 3px;
      }
    }

    &.cal-week {
      padding-bottom: 0px;
      background: $white;
      height: 172px;
      .fc {
        .fc-scrollgrid-sync-table {
          border: none !important;
          border-top: 1px solid $calenderGray2 !important;
          border-bottom: 1px solid $calenderGray2 !important;
        }
        .fc-day {
          .fc-daygrid-day-number {
            font-size: $h6;
          }
        }
      }
    }

    &.cal-month {
      height: 385px;
      background: $white;
      border-bottom: 1px solid $calenderGray2;

      .fc {
        &.fc-theme-standard .fc-scrollgrid,
        &.fc-theme-standard td,
        &.fc-theme-standard th {
          border: none;
        }
        .fc-daygrid-day.fc-day-today {
          > ::after {
            left: 50%;
            right: auto;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .fc-day {
          .fc-daygrid-day-number {
            position: relative;
            font-size: $h6;
            padding: 0;
            text-align: center;
            width: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            > ::before {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              border-radius: 100%;
            }
          }
          &.event-calender-item {
            > ::before {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    .fc {
      .fc-scrollgrid-section-header {
        .fc-col-header {
          height: 40px;
          .fc-scrollgrid-sync-inner {
            a {
              line-height: 40px;
            }
          }
        }
      }

      .fc-toolbar.fc-header-toolbar {
        height: 50px;
        .fc-toolbar-title {
          font-size: $h4;
        }
      }
      .fc-daygrid-day.fc-day-today {
        > ::after {
          width: 40px;
          height: 40px;
        }
      }
      .fc-day {
        .fc-daygrid-day-frame {
          cursor: pointer;
        }
        > ::before {
          border-radius: 100%;
          background: #1499ed;
          content: "";
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          transition: all 0.2s ease-in-out;
        }

        &.event-calender-item {
          .fc-daygrid-day-number {
            color: $white;
          }

          > ::before {
            width: 40px;
            height: 40px;
          }
        }

        &.active {
          > ::before {
            width: 40px;
            height: 40px;
            position: absolute;
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .cal-list-wrap {
    padding: 0 $mobileSideSpace;
    .cal-list-item {
      width: calc(100% - 6px);
      padding: 15px;
      margin-bottom: 28px;
      &::before {
        left: 6px;
        top: 6px;
      }
      .cal-list-p {
        margin-bottom: 0;
        span {
          font-size: $h6;
        }
      }
      .cal-grid {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .profile-item {
          display: flex;
          align-items: center;
          p {
            font-size: 12px;
            font-weight: bold;
            font-family: "NotoSansRegualr";
            color: #1c1c1c;
            margin-right: 10px;
          }
          div.img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .cal-list-info-wrap {
      // display: block;
      align-items: flex-start;
      flex-wrap: wrap;
      .orderInfo-img {
        width: 80px;
        height: 74px;
      }
      .cal-list-text {
        width: calc(100% - 80px);
        .card-content-wrap.MuiCardContent-root {
          padding-top: 0;
          padding-bottom: 0 !important;
        }
        .content-top + h3 {
          margin-bottom: 12px;
          font-size: $h7;
        }
        .teacher_name {
          display: flex;
          p {
            font-size: $h8;
            font-family: "NotoSansRegular";
          }
          .teacher-rate {
            margin-left: 5px;
          }
        }
      }
      .cal-list-button-wrap {
        align-items: flex-start;
        padding-top: 0;
        .heart-wrap {
          margin-bottom: 0;
          position: absolute;
          right: 16px;
          top: 14px;
          .favorite-count-icon {
            span {
              font-size: $h8;
            }
            img {
              width: 26px;
              height: 23px;
            }
          }
        }
      }
    }
    .cal-list-button-inner {
      margin-top: 20px;
      display: flex;
      width: 100%;
      > a {
        margin-left: 5px;
      }
      > a:nth-child(1) {
        flex-grow: 2;
        margin-left: 0;
      }
      > a:nth-child(2) {
        flex-grow: 1;
        width: 100%;
        margin-right: 5px;
      }
      > a:nth-child(3) {
        flex-grow: 1;
      }
      .cal-list-button {
        min-width: auto;
        height: 44px;
        width: 100%;
        line-height: 44px;
        border: 1px solid #1c1c1c;
        // margin-left: 0;
        color: #1c1c1c;
        font-size: 12px;
        letter-spacing: -1px;
        img {
          width: 17px;
        }
        &.cal-list-button-type1 {
        }
        &.cal-list-button-type2 {
          color: $white;
          &.disabled-btn {
            line-height: 1;
            border-radius: 5px;
          }
        }
        &.cal-list-button-s1 {
          min-width: auto;
          width: 100%;
        }
        > img {
          margin-right: 10px;
        }
      }
    }
  }

  .no-list {
    p {
      font-size: $h4_2;
      // margin-top: 10px;
      // color: #666;
      font-family: NotoSansRegular;
      letter-spacing: -0.5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .cal-list-wrap .cal-list-button-inner .cal-list-button > img {
    margin-right: 3px;
  }
  .cal-list-wrap .cal-list-button-inner .cal-list-button img {
    width: 12px;
  }
}
