.sub-header {
  width: 100%;
  height: 56px;
  .sub-header-fixed {
    width: 100%;
    height: 56px;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
    transition: top 0.3s;
    z-index: 11;
    .search-btn-wrap {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      img {
        height: 100%;
      }
      .back-btn {
        height: 22px;
        margin-right: 15px;
      }
      .sub-header-title {
        color: #1c1c1c;
        display: flex;
        flex: 1 1;
        font-family: NotoSansBold;
        font-size: 20px;
        height: 100%;
        position: relative;
        .MuiBox-root {
          width: 100%;
        }
      }
      .home-btn {
        height: 22px;
        margin-left: 15px;
      }
    }
  }
  .nav-search-box {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .search-input {
      display: block;
      height: 100%;
      .MuiInputBase-root {
        background-color: #f8f8f8;
        border: 2px solid #0000;
        border-radius: 20px;
        display: block;
        height: 100%;
        padding: 0 60px 0 24px;
        transition: all 0.15s;
        input {
          color: #1c1c1c;
          font-family: NotoSansBold;
          font-size: 14px;
          height: 100%;
          padding: 0;
        }
      }
    }
    .search-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 8px;
      right: 16px;
      img {
        height: 100%;
      }
    }
  }
  .btn-wrap {
    padding-top: 0;
    height: 19px;
    button {
      display: inline-block;
      height: 100%;
    }
    img {
      height: 100%;
    }
  }
}

@media screen and (max-width: 1023px) {
  .sub-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .top-banner {
    & + .sub-header {
      top: 64px;
    }
    & + .mypage-wrap .sub-header.sub-header-non-height {
      top: 64px !important;
    }
    & + .sub-wrap .sub-header {
      top: 64px;
    }
  }

  .sub-header-non-height {
    height: 0 !important;
  }

  .sub-header-event {
    padding-top: 56px !important;
  }
}
