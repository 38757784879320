.sub-Container {
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid #eaeaea;
    padding-top: 4.5%;
}
.sub-Container .event-top {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
}
.sub-Container .event-top p {
    width: 100%;
    text-align: left;
    font-family: 'NotoSansBold';
    font-size: 16px;
    color: #222;
}

.sub-Container .sub-item-root {
    width: 47.4%;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    overflow: visible;
    margin-right: 3.2%;
    margin-bottom: 1.6%;
    display: inline-block;
}
.sub-Container .sub-item-root:nth-last-child(1), .sub-Container .sub-item-root:nth-last-child(2) {margin-bottom: 0;}
/* .sub-Container .sub-item-root button {
    box-shadow: 8px 8px 0 0 #0e2b49;
} */
/* .sub-Container .sub-item-root button::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: #0e2b49;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: -1;
} */
.sub-Container .sub-item-root:nth-of-type(2n) {
    margin-right: 0px;
}

/* 이미지 */
.sub-Container .sub-item-root img {
    /* height: 20.9vw; */
    height: 286px;
}

/* 텍스트 영역 */
.sub-Container .sub-item-root .sub-item-text {
    padding: 30px 0 50px;
    border: none;
}

.sub-Container .sub-item-root .class-name {
    position: relative;
    margin-bottom: 4px;
}

.sub-Container .sub-item-root .class-name h1 {
    font-family: 'NotoSansBold';
    font-size: 24px;
    color: #1c1c1c;
    text-align: left;
    cursor: pointer;
    margin-bottom: 10px;
}

.sub-Container .sub-item-root .sub-item-text .event-period {
    color: #666666;
    font-size: 14px;
    font-family: 'NotoSansMedium';
    text-align: left;
}

.sub-wrap.magazine .MuiBox-root , .sub-wrap.event .MuiBox-root {
    width: 100%;
    justify-content: center;
}

.sub-Container.event-Container {
    padding-top: 0;
}

@media screen and (min-width: 1024px) and (max-width: 1260px) {
    .sub-wrap.magazine .MuiBox-root , .sub-wrap.event .MuiBox-root {
        padding: 0 20px;
        justify-content: space-between;
    }
    .sub-Container .sub-item-root img {height: 22vw;}
}
@media screen and (max-width: 1023px) {
    .sub-wrap.event,
    .sub-wrap.magazine {
        padding: 100px 16px 50px 16px;
    }
    .sub-Container {
        border-bottom: 0;
        padding: 4.5% 16px;
    }
    .sub-Container .sub-item-root {
        width: 98%;
        margin-right: 0;
        margin-bottom: 0;
        padding-top: 20px;
        border-bottom: 1px solid #eaeaea;
    }
    .sub-Container .sub-item-root:first-child { padding-top: 0; border-top: none;}
    .sub-Container .sub-item-root img {
        height: 42vw;
        /* height: 100%; */
    }
    .sub-Container .sub-item-root button::before {
        top: 8px;
        left: 8px;
    }
    .sub-Container .sub-item-root .sub-item-text {
        padding: 11px 0 20px;
    }
    .sub-Container .sub-item-root .class-name h1 {
        font-size: 15px;
    }
    .sub-Container .sub-item-root .sub-item-text .event-period {
        font-size: 12px;
    }

    .sub-Container select.select-type-s {
        width: 100%;
        background: #fff url(../../images/common/drop_icon3.png) no-repeat 97% center;
        background-size: 14px 12px;
        font-size: 12px;
    }

    .sub-Container .event-top .comboBox-type1 {width: 100%;}
}
