@import '../style.scss';
@import '../etc/variables.scss';
@import '../cardComponent.scss';


.togetherClassInfo {
    padding-left: 34px;
    .btnShare {
        cursor: pointer;
        color: #1c1c1c;
    }
    .class-info-top {
        display: flex;
        padding-bottom: 28px;
        position: relative;
        .category-name {
            font-size: 15px;
            color: #1c1c1c;
            font-family: 'NotoSansBold';
            padding-right: 10px;
        }
        .tag-list {
            margin-bottom: 0;
        }
    }
    .class-info-middle {
        text-align: left;

        .class-title {
            font-size: 24px;
            line-height: 1.6;
            font-family: 'NotoSansBold';
            color: #222;
            padding-bottom: 27px;
            border-bottom: 0;
            margin-top: 0;
        }
        .class-summary {
            font-size: 16px;
            line-height: 1.8;
            font-family: 'NotoSansMedium';
            color: #222;
            padding-bottom: 13px;
        }
        .class-hashtag {
            padding-bottom: 20px;
            li {
                font-family: 'NotoSansRegular';
                font-size: 14px;
                color: #666;
                display: inline-block;
                margin-right: 10px;
            }
        }

        .class-price-wrap {
            display: flex;
            align-items: center;
            padding: 18px 0 10px;
            justify-content: space-between;
            position: relative;
            
            + .ref-txt-list {
                padding-bottom: 10px;
                border-bottom: 1px solid #eaeaea;
                li {
                    font-size: 14px;
                    line-height: 1.6;
                    color: #666;
                }
            }
            .include-bookclub_point_max_rate {
                font-size: 14px;
                font-family: 'NotoSansMedium';
                color: #fb5651;
                position: absolute;
                bottom: 20%;
                transform: translateY(-50%);
            }
            .info-price {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;

                .base-price {
                    font-family: 'NotoSansBold';
                    font-size: 16px;
                    color: #666;
                    text-decoration: line-through;
                    position: absolute;
                    top: -20px;
                }
                .discount {
                    font-family: 'NotoSansBold';
                    font-size: 16px;
                    color: #ff5651;
                    margin-right: 10px;
                    position: inherit;
                    left: auto;
                }
                .price {
                    font-family: 'NotoSansBold';
                    font-size: 30px;
                    letter-spacing: -0.75px;
                    color: #1c1c1c;
                    display: flex;
                    align-items: inherit;
                }
            }
        }
        .coupon-down-btn {
            border-radius: 4px;
            padding: 6px 0;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
    .class-info-bottom {
        padding-top: 15px;
        .together-info-regist {
            margin-top: 20px;
            .guide-tit {
                margin-bottom: 10px;
                padding-left: 30px;
                background: url('../../images/common/grade.png') 0 2px/20px no-repeat;
                color: #1c1c1c;
                font-family: "NotoSansBold";
                line-height: 24px;
                text-align: left;

                &.cal {
                    background-image: url('../../images/common/calendar2.png');
                    background-size: 20px;
                }
            }
            .regist-area {
                margin-bottom: 20px;
        
                .gradeSelect {
                    width: 100%;
                    .comboBox-type1 {
                        width: 100%;
                        > div.MuiSelect-select {
                            text-align: center;
                        }
                    }
                }
            }
            .schedule-area {
                .top-area {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 20px;
    
                    .guide-tit {
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                    .ps-txt {
                        padding: 3px 0;
                        color: #666;
                        font-size: 12px;
                        letter-spacing: -0.3px;
                    }
                }
    
                .day-btn-area {
                    button {
                        flex: 1;
                        height: 36px;
                        padding: 0 5px;
                        border-color: #1c1c1c;
                        border-radius: 6px;
                        color: #1c1c1c;
                        font-family: "NotoSansBold";
                        font-size: 16px;
    
                        &.active {
                            background-color: #f8ce40;
                        }
                    }
                }
    
                .slt-area {
                    margin-top: 30px;
                    
                    .cell {
                        flex: 1;
                        height: 35px;
                        line-height: 35px;
                        color: #1c1c1c;
                        font-family: "NotoSansMedium";
                        font-size: 14px;
                        text-align: center;
                        letter-spacing: -0.4px;
    
                        &:nth-child(2) {
                            flex-grow: 2;
                        }
                        .MuiRadio-root {
                            padding: 0;
                            color: #afb4b2;
    
                            &.Mui-checked {
                                color: #1c1c1c;
                            }
                        }

                        & > label {
                            margin-left: 0;
                            margin-right: 0;
                        }

                    }
    
                    .thead {
                        .cell {
                            background-color: #0e7fe0;
                            color: #fff;
                            font-family: "NotoSansBold";
                            font-size: 15px;
                        }
                    }
                    .tbody {
                        border: 2px solid #0e7fe0;
                        .row {
                            &.active {
                                background-color: #f8ce40;
                            }
                            &.disabled {
                                background-color: #f0f0f0;
                                .cell {
                                    color: #afb4b2;
                                }
                            }
                        }
                        .MuiFormGroup-root {
                            .MuiFormControlLabel-root {
                                margin: 0;
                                &.isChecked {
                                    background-color: #f8ce40;
                                }
                                &.disabled {
                                    background-color: #f0f0f0;
                                    .MuiFormControlLabel-label .cell {
                                        color: #afb4b2;
                                    }
                                }
                                .MuiFormControlLabel-label {
                                    width: 100%;
                                    display: flex;

                                    .cell {
                                        flex: 1;
                                        height: 35px;
                                        line-height: 35px;
                                        color: #1c1c1c;
                                        font-family: "NotoSansMedium";
                                        font-size: 14px;
                                        text-align: center;
                                        letter-spacing: -0.4px;

                                        &:nth-child(1) {
                                            flex-grow: 2;
                                        }
                                        .MuiRadio-root {
                                            color: #afb4b2;
                    
                                            &.Mui-checked {
                                                color: #1c1c1c;
                                            }
                                        }
                
                                        & > label {
                                            margin-left: 0;
                                            margin-right: 0;
                                        }
                
                                    }
                                }
                                .MuiRadio-root {
                                    padding: 9px 38px;
                                }
                            }
                        }
                    }
                }
            }
            .favorite-count-icon {
                background-color: #fff;
                border: 2px solid #afb4b2;
                border-radius: 10px;
                flex-flow: column;
                height: 74px;
                padding-top: 10px;
                width: 84px;

                img {
                    height: 28px;
                    margin-bottom: 2px;
                    width: 32px;
                }
                span {
                    font-size: 12px;
                    color: #666;
                }
            }
            .register-class-btn {
                width: calc(100% - 73px);
                height: auto;
                border-radius: 0;
                font-size: 18px;
                margin-left: 0;
                &.disabled-btn {
                    border-radius: 0;
                    color: #fff;
                    background: #8D99A5;
                    border: solid 2px #8D99A5;
                }
            }
            .txt-area {
                text-align: left;
                margin: 10px 0 0 100px;
                > .ps-txt {
                    font-size: 12px;
                }
            }
            .btn-area {
                margin-top: 30px;
        
                .prev-btn {
                    flex-grow: 1;
                    height: 74px;
                    border-color: #afb4b2;
                    border-radius: 10px;
                    color: #666;
                    font-family: "NotoSansMedium";
                    font-size: 18px;
                }
                .schedule-slt-btn {
                    flex-grow: 8.5;
                    height: 74px;
                    background-color: #1499ed;
                    border-radius: 10px;
                    font-family: "NotoSansBold";
                    font-size: 24px;
                }
            }
        }
    }
}


@media screen and (max-width:1023px) {
    .togetherClassInfo {
        padding: 10px;
        .btnShare {
            cursor: pointer;
            color: #1c1c1c;
        }
        .class-info-top {
            display: flex;
            padding-bottom: 28px;
            position: relative;
            .category-name {
                font-size: 15px;
                color: #1c1c1c;
                font-family: 'NotoSansBold';
                padding-right: 10px;
            }
            .tag-list {
                margin-bottom: 0;
            }
        }
        .class-info-middle {
            text-align: left;
    
            .class-title {
                font-size: 24px;
                line-height: 1.6;
                font-family: 'NotoSansBold';
                color: #222;
                padding-bottom: 27px;
                border-bottom: 0;
                margin-top: 0;
            }
            .class-summary {
                font-size: 16px;
                line-height: 1.8;
                font-family: 'NotoSansMedium';
                color: #222;
                padding-bottom: 13px;
            }
            .class-hashtag {
                padding-bottom: 20px;
                li {
                    font-family: 'NotoSansRegular';
                    font-size: 14px;
                    color: #666;
                    display: inline-block;
                    margin-right: 10px;
                }
            }
    
            .class-price-wrap {
                display: flex;
                align-items: center;
                padding: 18px 0 10px;
                justify-content: space-between;
                position: relative;
                
                + .ref-txt-list {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #eaeaea;
                    li {
                        font-size: 14px;
                        line-height: 1.6;
                        color: #666;
                    }
                }
                .include-bookclub_point_max_rate {
                    font-size: 14px;
                    font-family: 'NotoSansMedium';
                    color: #fb5651;
                    position: absolute;
                    bottom: 20%;
                    transform: translateY(-50%);
                }
                .info-price {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: relative;
    
                    .base-price {
                        font-family: 'NotoSansBold';
                        font-size: 16px;
                        color: #666;
                        text-decoration: line-through;
                        position: absolute;
                        top: -20px;
                    }
                    .discount {
                        font-family: 'NotoSansBold';
                        font-size: 16px;
                        color: #ff5651;
                        margin-right: 10px;
                        position: inherit;
                        left: auto;
                    }
                    .price {
                        font-family: 'NotoSansBold';
                        font-size: 30px;
                        letter-spacing: -0.75px;
                        color: #1c1c1c;
                        display: flex;
                        align-items: inherit;
                    }
                }
            }
            .coupon-down-btn {
                border-radius: 4px;
                padding: 6px 0;
                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .class-info-bottom {
            position: fixed;
            bottom: -1px; left: 0;
            padding: 0;
            width: 100%;
            z-index: 100;    
            
            .together-info-regist {
                .btn-area {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 0;
    
                    .favorite-count-icon {
                        background-color: #fff;
                        border-radius: 0;
                        border: 0 solid #afb4b2;
                        flex-flow: column;
                        width: 73px; 
                        // height: 56px;
                        padding: 0;
                        
                        img {
                            width: 24px; height: 21px;
                            margin-bottom: 0;
                            margin-top: 10px;
                        }
                        span {
                            font-size: 12px;
                            color: #666;
                        }
                    }
                    .schedule-slt-btn {
                        font-family: NotoSansBold;
                        border-radius: 0;
                        font-size: 18px;
                        height: auto;
                        margin-left: 0;
                        width: calc(100% - 73px);
            
                        &.disabled-btn {
                            border-radius: 0;
                            color: #fff;
                            background: #8D99A5;
                            border: 2px solid #8D99A5;
                        }
                    }
                }
            }
        }
        .together-info-regist {
            margin-top: 0;
            .together-regist-area {
                .item-slt-area {
                    &.step3 {
                        padding-bottom: 26px;
                        border-bottom: 1px dashed #1c1c1c;
                    }
                    .item-slt-each {
                        position: relative;
                        margin-bottom: 30px;
            
                        &:last-of-type {
                            margin-bottom: 0 !important;
                        }
                        .MuiFormControl-root {
                            width: 100%;
            
                            .comboBox-type1 {
                                width: 100%;
                                height: 60px;
                            }
                        }
                        .chk-txt-box {
                            padding: 18px 30px;
                            background-color: #fff;
                            border: 1px solid #afb4b2;
                            border-radius: 5px;
                            color: #1c1c1c;
                            font-size: 16px;
                            line-height: 24px;
                        }
                        .change-btn {
                            position: absolute;
                            right: 0;
                            top: -6px;
                            width: 62px;
                            height: 32px;
                            line-height: 30px;
                            border-color: #1c1c1c;
                            border-radius: 16px;
                            color: #1c1c1c;
                            font-family: "NotoSansMedium";
                            font-size: 14px;
                        }
                    }
                    .guide-txt {
                        margin-top: 5px;
                        color: #666;
                        font-size: 12px;
                        line-height: 20px;
                        letter-spacing: -0.3px;
                    }
                }
                .guide-tit {
                    margin-bottom: 10px;
                    padding-left: 30px;
                    background: url('../../images/common/grade.png') 0 2px/20px no-repeat;
                    color: #1c1c1c;
                    font-family: "NotoSansBold";
                    line-height: 24px;
        
                    &.period {
                        background-image: url('../../images/common/period.png');
                        background-size: 24px;
                    }
                    &.doc-check {
                        background-image: url('../../images/common/doc-check.png');
                    }
                    &.cal {
                        background-image: url('../../images/common/calendar2.png');
                        background-size: 20px;
                    }
                }
                .gradeSelect {
                    width: 100%;
                    .comboBox-type1 {
                        width: 100%;
                        > div.MuiSelect-select {
                            text-align: center;
                        }
                    }
                }
        
                /* .schedule-area {
                    .top-area {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
        
                        .guide-tit {
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                        .ps-txt {
                            padding: 3px 0;
                            color: #666;
                            font-size: 12px;
                            letter-spacing: -0.3px;
                        }
                    }
        
                    .day-btn-area {
                        button {
                            flex: 1;
                            height: 50px;
                            padding: 0 5px;
                            border-color: #1c1c1c;
                            border-radius: 6px;
                            color: #1c1c1c;
                            font-family: "NotoSansBold";
                            font-size: 16px;
        
                            &.active {
                                background-color: #f8ce40;
                            }
                        }
                    }
        
                    .slt-area {
                        margin-top: 30px;
                        
                        .cell {
                            flex: 1;
                            height: 35px;
                            line-height: 35px;
                            color: #1c1c1c;
                            font-family: "NotoSansMedium";
                            font-size: 14px;
                            text-align: center;
                            letter-spacing: -0.4px;
        
                            &:nth-child(2) {
                                flex-grow: 2;
                            }
                            .MuiRadio-root {
                                padding: 0;
                                color: #afb4b2;
        
                                &.Mui-checked {
                                    color: #1c1c1c;
                                }
                            }
                        }
        
                        .thead {
                            .cell {
                                background-color: #0e7fe0;
                                color: #fff;
                                font-family: "NotoSansBold";
                                font-size: 15px;
                            }
                        }
                        .tbody {
                            border: 2px solid #0e7fe0;
        
                            .row {
                                &.active {
                                    background-color: #f8ce40;
                                }
                                &.disabled {
                                    background-color: #f0f0f0;
        
                                    .cell {
                                        color: #afb4b2;
                                    }
                                }
                            }
                        }
                        
                    }
                } */
            }
            .together-schedule-area {
                .top-area {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin: 20px 0;
    
                    .guide-tit {
                        margin-bottom: 10px;
                        padding-left: 30px;
                        color: #1c1c1c;
                        font-family: "NotoSansBold";
                        line-height: 24px;
                        
                        &.cal {
                            background: url('../../images/common/calendar2.png') 0 2px/20px no-repeat;
                            background-size: 20px;
                        }
                    }
                    .ps-txt {
                        padding: 3px 0;
                        color: #666;
                        font-size: 12px;
                        letter-spacing: -0.3px;
                    }
                }
    
                .day-btn-area {
                    button {
                        flex: 1;
                        height: 36px;
                        padding: 0 5px;
                        border-color: #1c1c1c;
                        border-radius: 6px;
                        color: #1c1c1c;
                        font-family: "NotoSansBold";
                        font-size: 16px;
    
                        &.active {
                            background-color: #f8ce40;
                        }
                    }
                }
                /* .slt-area {
                    margin-top: 30px;
                    
                    .cell {
                        flex: 1;
                        height: 35px;
                        line-height: 35px;
                        color: #1c1c1c;
                        font-family: "NotoSansMedium";
                        font-size: 14px;
                        text-align: center;
                        letter-spacing: -0.4px;
    
                        &:nth-child(2) {
                            flex-grow: 2;
                        }
                        .MuiRadio-root {
                            padding: 0;
                            color: #afb4b2;
    
                            &.Mui-checked {
                                color: #1c1c1c;
                            }
                        }

                        & > label {
                            margin-left: 0;
                            margin-right: 0;
                        }

                    }
    
                    .thead {
                        .cell {
                            background-color: #0e7fe0;
                            color: #fff;
                            font-family: "NotoSansBold";
                            font-size: 15px;
                        }
                    }
                    .tbody {
                        border: 2px solid #0e7fe0;
                        .row {
                            &.active {
                                background-color: #f8ce40;
                            }
                            &.disabled {
                                background-color: #f0f0f0;
                                .cell {
                                    color: #afb4b2;
                                }
                            }
                        }
                    }
                } */
                .slt-area {
                    margin-top: 30px;
                    
                    .cell {
                        flex: 1;
                        height: 35px;
                        line-height: 35px;
                        color: #1c1c1c;
                        font-family: "NotoSansMedium";
                        font-size: 14px;
                        text-align: center;
                        letter-spacing: -0.4px;
    
                        &:nth-child(2) {
                            flex-grow: 2;
                        }
                        .MuiRadio-root {
                            padding: 0;
                            color: #afb4b2;
    
                            &.Mui-checked {
                                color: #1c1c1c;
                            }
                        }

                        & > label {
                            margin-left: 0;
                            margin-right: 0;
                        }

                    }
    
                    .thead {
                        .cell {
                            background-color: #0e7fe0;
                            color: #fff;
                            font-family: "NotoSansBold";
                            font-size: 15px;
                        }
                    }
                    .tbody {
                        border: 2px solid #0e7fe0;
                        .row {
                            &.active {
                                background-color: #f8ce40;
                            }
                            &.disabled {
                                background-color: #f0f0f0;
                                .cell {
                                    color: #afb4b2;
                                }
                            }
                        }
                        .MuiFormGroup-root {
                            .MuiFormControlLabel-root {
                                margin: 0;
                                &.isChecked {
                                    background-color: #f8ce40;
                                }
                                &.disabled {
                                    background-color: #f0f0f0;
                                    .MuiFormControlLabel-label .cell {
                                        color: #afb4b2;
                                    }
                                }
                                .MuiFormControlLabel-label {
                                    width: 100%;
                                    display: flex;

                                    .cell {
                                        flex: 1;
                                        height: 35px;
                                        line-height: 35px;
                                        color: #1c1c1c;
                                        font-family: "NotoSansMedium";
                                        font-size: 14px;
                                        text-align: center;
                                        letter-spacing: -0.4px;

                                        &:nth-child(1) {
                                            flex-grow: 2;
                                        }
                                        .MuiRadio-root {
                                            color: #afb4b2;
                    
                                            &.Mui-checked {
                                                color: #1c1c1c;
                                            }
                                        }
                
                                        & > label {
                                            margin-left: 0;
                                            margin-right: 0;
                                        }
                
                                    }
                                }
                                .MuiRadio-root {
                                    padding: 1.2% 7.2%;
                                }
                            }
                        }
                    }
                }
               
            }
            .together-check-area {
                background: #fdf6ea;
                // box-shadow: 0 -5px 10px 1px rgba($color: $black, $alpha: 0.1);
                // border-top: 1px solid #eaeaea;
                position: fixed;
                bottom: -1px; left: 0;
                // padding: 20px 0 0 0;
                width: 100%;
                z-index: 100; 

                .guide-tit {
                    margin-bottom: 10px;
                    padding-left: 30px;
                    margin-left: 5%;
                    color: #1c1c1c;
                    font-family: "NotoSansBold";
                    line-height: 24px;
                    
                    &.cal {
                        background: url('../../images/common/calendar2.png') 0 2px/20px no-repeat;
                        background-size: 20px;
                    }
                }
                .ps-txt {
                    padding: 3px 0;
                    color: #666;
                    font-size: 12px;
                    letter-spacing: -0.3px;
                }
                .schedule-alert {
                    // padding: 5% 5% 0 5%;
                    padding: 0 5%;
                    .mobile-alert {
                        border: 1px solid $pointColor;
                        background: #fff;
                        border-radius: 6px;
                        .MuiTypography-body1 {
                            font-size: $h7;
                            color: $black;
                        }
                    }
                }
    
                .total-price-wrap {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    line-height: 1.3;
                    padding-bottom: 20px;
                    padding-top: 10px;

                    p {
                        color: #222;
                        font-family: NotoSansBold;
                        font-size: 20px;
                    }
                    span.price {
                        color: #ff5651;
                        font-family: NotoSansBold;
                        font-size: 30px;
                    }
                }
                .class-btnWrap {
                    .favorite-count-icon {
                        width: 73px;
                        height: 56px;
                        // border: solid 1px #b0b4b2;
                        border-radius: 0;
                        background-color: #ffffff;
                        img {
                            width: 24px;
                            height: 21px;
                            margin-bottom: 0;
                        }
                        span {
                            font-size: 12px;
                            color: #666;
                        }
                    }
                    .register-class-btn {
                        width: calc(100% - 73px);
                        height: auto;
                        border-radius: 0;
                        font-size: 18px;
                        margin-left: 0;
                        font-family: "NotoSansBold";

                        &.disabled-btn {
                            border-radius: 0;
                            color: #fff;
                            background: #8D99A5;
                            border: solid 2px #8D99A5;
                        }
                    }
                }
            }

            .favorite-count-icon {
                background-color: #fff;
                border: 0 solid #afb4b2;
                border-radius: 0;
                flex-flow: column;
                height: 74px;
                padding-top: 10px;
                width: 84px;

                img {
                    height: 28px;
                    margin-bottom: 2px;
                    width: 32px;
                }
                span {
                    font-size: 12px;
                    color: #666;
                }
            }
            .register-class-btn {
                width: calc(100% - 73px);
                height: auto;
                border-radius: 0;
                font-size: 18px;
                margin-left: 0;
                &.disabled-btn {
                    border-radius: 0;
                    color: #fff;
                    background: #8D99A5;
                    border: solid 2px #8D99A5;
                }
            }
            .txt-area {
                text-align: left;
                margin: 10px 0 0 100px;
                > .ps-txt {
                    font-size: 12px;
                }
            }
            .btn-area {
                display: flex;
                justify-content: space-between;

                .favorite-count-icon {
                    background-color: #fff;
                    border-radius: 0;
                    border: 0 solid #afb4b2;
                    flex-flow: column;
                    width: 73px; 
                    // height: 56px;
                    padding: 0;
                    
                    img {
                        width: 24px; height: 21px;
                        margin-bottom: 0;
                        margin-top: 10px;
                    }
                    span {
                        font-size: 12px;
                        color: #666;
                    }
                }
                .schedule-slt-btn {
                    font-family: NotoSansBold;
                    border-radius: 0;
                    font-size: 18px;
                    height: auto;
                    margin-left: 0;
                    width: calc(100% - 73px);
        
                    &.disabled-btn {
                        border-radius: 0;
                        color: #fff;
                        background: #8D99A5;
                        border: 2px solid #8D99A5;
                    }
                }
            }
        }
        /* .together-info-regist {
            margin-top: 20px;
        
            .step-view {
                height: 90px;
                margin-bottom: 20px;
                padding: 16px;
                background-color: #f8f8f8;
                text-align: center;
        
                ol {
                    display: inline-block;
                    position: relative;
                    z-index: 1;
                    margin-bottom: 10px;
                    font-size: 0;
                    vertical-align: top;
        
                    li {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 26px;
                        margin-right: 55px;
                        background-color: #f8f8f8;
                        border: 1px solid #1c1c1c;
                        border-radius: 50%;
                        color: #afb4b2;
                        font-family: "NotoSansBold";
                        font-size: 16px;
                        vertical-align: top;
        
                        &:last-child {
                            margin-right: 0;
                        }
                        &.active {
                            background-color: #fc5651;
                            font-family: "NotoSansBlack";
                            color: #fff;
                        }
                        &.complete {
                            background: #fc5651 url('../../images/detail/step-check.png') center/15px no-repeat;
                            font-size: 0;
                        }
                    }
                    ::before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        z-index: -1;
                        width: 100%;
                        height: 1px;
                        background-color: #afb4b2;
                    }
                }
                .tit {
                    display: block;
                    color: #1c1c1c;
                    font-family: "NotoSansBold";
                    line-height: 1;
                }
            }
        
            .together-regist-area {
                .item-slt-area {
                    &.step3 {
                        padding-bottom: 26px;
                        border-bottom: 1px dashed #1c1c1c;
                    }
                    .item-slt-each {
                        position: relative;
                        margin-bottom: 30px;
            
                        &:last-of-type {
                            margin-bottom: 0 !important;
                        }
                        .MuiFormControl-root {
                            width: 100%;
            
                            .comboBox-type1 {
                                width: 100%;
                                height: 60px;
                            }
                        }
                        .chk-txt-box {
                            padding: 18px 30px;
                            background-color: #fff;
                            border: 1px solid #afb4b2;
                            border-radius: 5px;
                            color: #1c1c1c;
                            font-size: 16px;
                            line-height: 24px;
                        }
                        .change-btn {
                            position: absolute;
                            right: 0;
                            top: -6px;
                            width: 62px;
                            height: 32px;
                            line-height: 30px;
                            border-color: #1c1c1c;
                            border-radius: 16px;
                            color: #1c1c1c;
                            font-family: "NotoSansMedium";
                            font-size: 14px;
                        }
                    }
                    .guide-txt {
                        margin-top: 5px;
                        color: #666;
                        font-size: 12px;
                        line-height: 20px;
                        letter-spacing: -0.3px;
                    }
                }
        
                .guide-tit {
                    margin-bottom: 10px;
                    padding-left: 30px;
                    background: url('../../images/common/grade.png') 0 2px/20px no-repeat;
                    color: #1c1c1c;
                    font-family: "NotoSansBold";
                    line-height: 24px;
        
                    &.period {
                        background-image: url('../../images/common/period.png');
                        background-size: 24px;
                    }
                    &.doc-check {
                        background-image: url('../../images/common/doc-check.png');
                    }
                    &.cal {
                        background-image: url('../../images/common/calendar2.png');
                        background-size: 20px;
                    }
                }
        
                .schedule-area {
                    .top-area {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
        
                        .guide-tit {
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                        .ps-txt {
                            padding: 3px 0;
                            color: #666;
                            font-size: 12px;
                            letter-spacing: -0.3px;
                        }
                    }
        
                    .day-btn-area {
                        button {
                            flex: 1;
                            height: 50px;
                            padding: 0 5px;
                            border-color: #1c1c1c;
                            border-radius: 6px;
                            color: #1c1c1c;
                            font-family: "NotoSansBold";
                            font-size: 16px;
        
                            &.active {
                                background-color: #f8ce40;
                            }
                        }
                    }
        
                    .slt-area {
                        margin-top: 30px;
                        
                        .cell {
                            flex: 1;
                            height: 35px;
                            line-height: 35px;
                            color: #1c1c1c;
                            font-family: "NotoSansMedium";
                            font-size: 14px;
                            text-align: center;
                            letter-spacing: -0.4px;
        
                            &:nth-child(2) {
                                flex-grow: 2;
                            }
                            .MuiRadio-root {
                                padding: 0;
                                color: #afb4b2;
        
                                &.Mui-checked {
                                    color: #1c1c1c;
                                }
                            }
                        }
        
                        .thead {
                            .cell {
                                background-color: #0e7fe0;
                                color: #fff;
                                font-family: "NotoSansBold";
                                font-size: 15px;
                            }
                        }
                        .tbody {
                            border: 2px solid #0e7fe0;
        
                            .row {
                                &.active {
                                    background-color: #f8ce40;
                                }
                                &.disabled {
                                    background-color: #f0f0f0;
        
                                    .cell {
                                        color: #afb4b2;
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        
            .alert-txt {
                margin-top: 30px;
                padding-top: 10px;
                border-top: 1px dashed #1c1c1c;
                color: #fc5651;
                font-size: 14px;
                line-height: 20px;
            }
        
            .btn-area {
                margin-top: 30px;
        
                .prev-btn {
                    flex-grow: 1;
                    height: 74px;
                    border-color: #afb4b2;
                    border-radius: 10px;
                    color: #666;
                    font-family: "NotoSansMedium";
                    font-size: 18px;
                }
                .schedule-slt-btn {
                    flex-grow: 8.5;
                    height: 74px;
                    background-color: #1499ed;
                    border-radius: 10px;
                    font-family: "NotoSansBold";
                    font-size: 24px;
                }
            }
        } */
       /*  .together-modal-info-regist {
            margin-top: 20px;
            .guide-tit {
                margin-bottom: 10px;
                padding-left: 30px;
                background: url('../../images/common/grade.png') 0 2px/20px no-repeat;
                color: #1c1c1c;
                font-family: "NotoSansBold";
                line-height: 24px;
                text-align: left;

                &.cal {
                    background-image: url('../../images/common/calendar2.png');
                    background-size: 20px;
                }
            }
            .regist-area {
                margin-bottom: 20px;
        
                .gradeSelect {
                    width: 100%;
                    .comboBox-type1 {
                        width: 100%;
                        > div.MuiSelect-select {
                            text-align: center;
                        }
                    }
                }
            }
            .schedule-area {
                .top-area {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 20px;
    
                    .guide-tit {
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                    .ps-txt {
                        padding: 3px 0;
                        color: #666;
                        font-size: 12px;
                        letter-spacing: -0.3px;
                    }
                }
    
                .day-btn-area {
                    button {
                        flex: 1;
                        height: 36px;
                        padding: 0 5px;
                        border-color: #1c1c1c;
                        border-radius: 6px;
                        color: #1c1c1c;
                        font-family: "NotoSansBold";
                        font-size: 16px;
    
                        &.active {
                            background-color: #f8ce40;
                        }
                    }
                }
    
                .slt-area {
                    margin-top: 30px;
                    
                    .cell {
                        flex: 1;
                        height: 35px;
                        line-height: 35px;
                        color: #1c1c1c;
                        font-family: "NotoSansMedium";
                        font-size: 14px;
                        text-align: center;
                        letter-spacing: -0.4px;
    
                        &:nth-child(2) {
                            flex-grow: 2;
                        }
                        .MuiRadio-root {
                            padding: 0;
                            color: #afb4b2;
    
                            &.Mui-checked {
                                color: #1c1c1c;
                            }
                        }

                        & > label {
                            margin-left: 0;
                            margin-right: 0;
                        }

                    }
    
                    .thead {
                        .cell {
                            background-color: #0e7fe0;
                            color: #fff;
                            font-family: "NotoSansBold";
                            font-size: 15px;
                        }
                    }
                    .tbody {
                        border: 2px solid #0e7fe0;
                        .row {
                            &.active {
                                background-color: #f8ce40;
                            }
                            &.disabled {
                                background-color: #f0f0f0;
                                .cell {
                                    color: #afb4b2;
                                }
                            }
                        }
                    }
                    
                }
            }
            .favorite-count-icon {
                background-color: #fff;
                border: 0 solid #afb4b2;
                border-radius: 0;
                flex-flow: column;
                height: 74px;
                padding-top: 10px;
                width: 84px;

                img {
                    height: 28px;
                    margin-bottom: 2px;
                    width: 32px;
                }
                span {
                    font-size: 12px;
                    color: #666;
                }
            }
            .register-class-btn {
                width: calc(100% - 73px);
                height: auto;
                border-radius: 0;
                font-size: 18px;
                margin-left: 0;
                &.disabled-btn {
                    border-radius: 0;
                    color: #fff;
                    background: #8D99A5;
                    border: solid 2px #8D99A5;
                }
            }
            .txt-area {
                text-align: left;
                margin: 10px 0 0 100px;
                > .ps-txt {
                    font-size: 12px;
                }
            }
        } */
        /* .class-info-bottom {
            background: #fdf6ea;
            box-shadow: 0 -5px 10px 1px rgba($color: $black, $alpha: 0.1);
            border-top: 1px solid #eaeaea;
            .schedule-alert {
                padding: $mobileSideSpace $mobileSideSpace 0;
                .mobile-alert {
                    border: 1px solid $pointColor;
                    background: #fff;
                    border-radius: 6px;
                    .MuiTypography-body1 {
                        font-size: $h7;
                        color: $black;
                    }
                }
            }

            .class-btnWrap {
                .favorite-count-icon {
                    width: 73px;
                    height: 56px;
                    // border: solid 1px #b0b4b2;
                    border-radius: 0;
                    background-color: #ffffff;
                    img {
                        width: 24px;
                        height: 21px;
                        margin-bottom: 0;
                    }
                    span {
                        font-size: 12px;
                        color: #666;
                    }
                }
                .register-class-btn {
                    width: calc(100% - 73px);
                    height: auto;
                    border-radius: 0;
                    font-size: 18px;
                    margin-left: 0;
                &.disabled-btn {
                        border-radius: 0;
                        color: #fff;
                        background: #8D99A5;
                        border: solid 2px #8D99A5;
                    }
                }
            }
        } */
    }
}
