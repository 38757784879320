.tabBox {
    display: flex;
    position: relative;
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 20px;

    .tabMenu, 
    .swiper.tabMenu {
        width: 100%; height: auto;
        display: flex;
        align-items: center;
        margin: 0 -8px;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;

        li {
            background: #f8f8f8;
            border-radius: 16px;
            color: #999;
            cursor: pointer;
            flex: 0 0 auto;
            font-family: NotoSansMedium;
            font-size: 16px;
            line-height: 32px;
            margin: 8px 4px;
            padding: 0 16px;

            &.on {
                background: #1c1c1c;
                color: #fff;
            }
        }

        div.swiper-slide {
            flex: 0 0 auto;
            line-height: 32px;
            margin: 8px 4px;
            padding: 0 16px;
            background: #f8f8f8;
            border-radius: 16px;
            font-family: "NotoSansMedium";
            color: #999;
            font-size: 16px;
            cursor: pointer;

            width: auto !important;
            font-size: 14px;
            padding: 7px 21px 7px 22px;
            margin-left: 6px;


            &.on {
                background: #1c1c1c;
                color: #fff;
            }
            &:not(:first-child){
                margin-left: 14px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .tabBox { 
        .tabMenu, 
        .swiper.tabMenu {
            margin: 0 -6px;
            li {
                line-height: 28px;
                margin: 5px 2px;
                font-size: 13px;
            }
            div.swiper-slide {
                &:not(:first-child){
                    margin-left: 8px;
                }
                &:first-child {
                    margin-left: 14px;
                }
            }
        }
    }
}