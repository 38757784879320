.classReview_tit {
    font: 24px/2.29 'NotoSansBold';
    letter-spacing: -0.6px;
    color: #1c1c1c;
}

.txt_gray {
    font-family: 'NotoSansMedium';
    color: #afb4b2 !important;
}

.classReview_raiting {
    width: 100%;
    height: 98px;
    margin: 9px 0 11px;
    background-color: #fdf6ea;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.classReview_raiting h2 {
    font: 20px/2.75 'NotoSansBold';
    letter-spacing: -0.5px;
    color: #1c1c1c;
}
.classReview_raiting img {
    width: 27px;
    height: 25px;
}
.classReview_raiting img:not(:last-child) {
    margin-right: 4px;
}

.review_thum_slide_wrap {position: relative;}
.review_thum_slide_wrap .slide-card-items-list-type.swiper {
    max-width: 96%;
    padding: 0;
}
.review_thum_slide_wrap .swiper-slide {height: 120px;}
.review_thum_slide_wrap .swiper-slide img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.review_thum_slide_wrap .swiper-button-next {
    right: 0;
    top: 46px;
    width: 38px;
    height: 74px;
    font-size: 0;
	z-index: 2;
}
.review_thum_slide_wrap .swiper-button-prev {
    left: 0;
    top: 46px;
    width: 38px;
    height: 74px;
    font-size: 0;
	z-index: 2;
}
.review_thum_slide_wrap .swiper-button-prev:after {
    content: "";
    background: url(../../images/common/blue-prev-btn.png) no-repeat center center / 100%;
    width: 38px;
    height: 74px;
    opacity: 1;
    transition: opacity 0.20s ease-in;
    position: absolute;
}
.review_thum_slide_wrap .swiper-button-next::after {
    content: "";
    background: url(../../images/common/blue-next-btn.png) no-repeat center center / 100%;
    width: 38px;
    height: 74px;
    opacity: 1;
    transition: opacity 0.20s ease-in;
    position: absolute;
}
.classReview_dropBoxWrap {    
    border-top: 1px solid #b0b4b2;
    margin-top: 20px;
}
.classReview_noti {
    font: 14px/1.71 'NotoSansMedium';
    letter-spacing: -0.35px;
    padding: 20px 0;
    /* border-bottom: 1px solid #afb4b2; */
}
.reviewShow_wrap {display: flex;}
.reviewShow_wrap .slide-card-items-list-type.swiper {
    width: 60%;
    height: 530px;
    padding: 0;
    margin: 0;
}
.reviewShow_wrap .slide-card-items-list-type.swiper .swiper-slide img {width: 100%;height: 100%;object-fit: contain;}
.reviewShow_wrap .swiper-pagination.swiper-horizontal>.swiper-pagination-bullets,
.reviewShow_wrap .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10; 
}
.seen-classInfo {width: 40%;padding: 15px;}
.seen-classInfo > .MuiGrid-item {display: flex;}
.seen-classInfo > .MuiGrid-item .classReview-info-img {width: 120px; height: 113px;}
.seen-classInfo > .MuiGrid-item .classReview-info-img img {width: 100%;height: 100%;}
.seen-classInfo > .MuiGrid-item .classReview-info-img + div {
    width: calc(100% - 120px);
    padding: 0px 16px 4px;
}
.seen-classInfo .tag-list{
    margin-bottom: 12px;
}
.seen-classInfo .tag-list li{
    padding: 5px 15px 0 0;
}
.seen-classInfo .content-top+h3 {
    width: 100%;
}
.seen-classInfo .main-grid {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 10px;
    margin-top: 24px;
}

.seen-classInfo .main-grid > div {
    display: flex;
    align-items: center;
}
.seen-classInfo .main-grid > div img{
    width: 17px;
    height: 18px;
    margin-right: 3px;
}
.seen-classInfo .main-grid > div span {
    font-size: 15px;
    color: #1c1c1c;
    font-weight: bold;
}

.seen-classInfo .main-grid .student-info {
    color: #1c1c1c;    
    font-size: 14px;
    font-family: 'NotoSansMedium';
}
.seen-classInfo .txt_gray {padding: 15px 0 15px;}
.seen-classInfo .txt_gray + p{
    color: #1c1c1c;
    font-family: 'NotoSansMedium';
    overflow-y: auto;
    height: 285px;
}
.seen-classInfo .txt_gray + p.MuiTypography-body1::-webkit-scrollbar {
    width: 4px;
}
.seen-classInfo .txt_gray + p.MuiTypography-body1::-webkit-scrollbar-thumb {
    width: 4px;
    background: #afb4b2;
    border-radius: 2px;
}
.seen-classInfo .txt_gray + p.MuiTypography-body1::-webkit-scrollbar-track {
    background-color: transparent;
}

.classReview_dropBox_top {
    width: 100%;
    height: 90px;
    padding: 0 10px;
    display: flex;
    justify-content: unset;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
}
.classReview_dropBox_top > span {
    font: 16px/2.125 'NotoSansBold';
    color: #1c1c1c;
}

.classReview_dropBox_top div {
    font: 16px/2.125 'NotoSansMedium';
    letter-spacing: -0.4px;
    color: #222;
    position: relative;
    padding: 0 7px;
}
.classReview_dropBox_top div:first-child::after  {
    content:none;
}
.classReview_dropBox_top div:first-child {padding: 0 7px 0 0;}

.classReview_dropBox_top div::after {
    content: "";
    position: absolute;
    width: 1.4px;
    height: 50%;
    background-color: #222;
    top: 50%;
    left: 0;
    transform: translate(-50%,-50%);
}

.classReview_dropBox_top div.on {
    font: 16px/2.125 'NotoSansBold';
    letter-spacing: -0.4px;
    color: #222;
}

.classReview_dropBox_con .classReview_dropBox_tit {
    width: 100%;
    padding: 24px 20px 24px 10px;
    border-bottom: 1px solid #eaeaea;
}

.classReview_dropBox {
    cursor: pointer;
}

.classReview_dropBox_tit .rating {
    font: 15px/1 'NotoSansBold';
    letter-spacing: -0.38px;
    color: #0e2b49;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.classReview_dropBox_tit .rating img {
    width: 18px;
    height: 17px;
    margin-right: 3px;
}
.classReview_dropBox_tit p.txt_gray {
    font: 14px/1.71 'NotoSansMedium';
    letter-spacing: -0.35px;
    color: #afb4b2 !important;
}
.classReview_dropBox_tit .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 46px;
    position: relative;
}
.classReview_dropBox_tit .title h2 {
    font: 16px/2.125 'NotoSansMedium';
    letter-spacing: -0.4px;
    color: #222;
    width: 73%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.classReview_dropBox_tit .title span {
    font-size: 14px;
    color: #afb4b2;
}
.classReview_dropBox_info {
    display: none;
    width: 100%;
    padding: 30px;
    background-color: #f8f8f8;
    font: 16px/1.625 'NotoSansRegular';
    letter-spacing: -0.4px;
    color: #666;
    border-bottom: 1px solid #eaeaea;
}
.classReview_dropBox_info.isOn {
    display: block;
}

.review_thum_wrap {
    width: 260px;
    height: 208px;
    margin-top: 15px;
    position: relative;
}
.review_thum_wrap img {width: 100%;height: 100%;object-fit: contain;}
.review_thum_wrap span {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    font-family: "NotoSansMedium";
    font-size: 20px;
    text-align: center;
    color: #fff;
    line-height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
}
.detailTabBox {
    display: none;
}
.detailTabBox.isOn {
    display: block;
}

.detailTabBox.isOn h1 {
    color: #1c1c1c;
    font: 1.5rem/1.33 NotoSansBlack;
    letter-spacing: -.75px;
    margin: 2% 1%;
    text-align: left;
}


.inform-txt { font-size: 12px;color: #afb4b2; padding-left: 10px;}
.select-btn {
    color: #666 !important;
    background: url(../../images/common/camera_icon.png) no-repeat  12px / 20px 20px;
}
.reviewImgListWrap {
    margin-top: 10px;
}
.reviewImgListWrap .swiper {height: auto;}
.reviewImgListWrap .swiper .swiper-slide {width: 150px;height: 120px;}
.reviewImgItem {width: 150px;height: 120px;position: relative;}
.reviewImgItem > img {width: 100%;height: 100%;object-fit: contain;}
img.close_W {
    width: 25px !important; 
    height: 25px !important;    
    position: absolute;
    padding: 6.7px 6.2px 6.3px 6.8px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
}
.reviewShow_wrap .swiper-pagination-type-2 .swiper-pagination-bullet {
    background-color: #afb4b2;
    width: 8px;
    height: 8px;
}
.reviewShow_wrap .swiper-pagination-type-2 .swiper-pagination-bullet-active {
    background-color: #1c1c1c;
    width: 26px;
    height: 8px;
}
@media screen and (max-width: 1023px) {
    .classReview_tit {
        font: 16px/1.8 'NotoSansBold';
        padding-bottom: 10px;
    }
    .classReview_raiting {
        display: block;
        text-align: center;
        height: auto;
        border-radius: 0;
        margin: 0 0 11px;
        padding: 20px 0 30px;
    }

    .classReview_raiting h2:first-child {
        font: 15px/1.2 'NotoSansBold';
        display: inline-block;
        padding-right: 10px;
    }
    .classReview_raiting h2:first-child + h2 {
        display: inline-block;
        font: 20px/1.2 'NotoSansBold';
    }
    .classReview_raiting h2:first-child + h2 + div {
        display: block;
        width: 100%;
        padding-top: 7px;
    }
    .classReview_noti {
        font: 12px/1.5 'NotoSansMedium';
        padding: 0 0 30px;
        border-bottom: 0;
    }
    .classReview_dropBox_top {
        height: 100%;
        padding: 0 0 10px 0;
        /* border-bottom: 1px solid #afb4b2; */
    }
    .classReview_dropBox_top > span {
        font: 15px/2 'NotoSansBold';
        color: #1c1c1c;
    }

    .classReview_dropBox_tit .rating {
        position: relative;
    }
    .classReview_dropBox_tit .rating span {
        font-family: 'NotoSansRegular';
        position: absolute;
        right: 0;
        font-size: 12px;
        color: #afb4b2;
    }

    .classReview_dropBox_tit .rating img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
    }
    .classReview_dropBox_tit .txt_gray {
        font: 12px/1.5 'NotoSansRegular';
        letter-spacing: -0.35px;
    }
    .classReview_dropBox_tit .title h2 {
        font: 13px/1.8 'NotoSansMedium';
        width: 100%;
    }
    .arrowIcon svg {
        color: #222;
    }
    .classReview_dropBox_info {
        padding: 16px;
        font: 13px/1.5 'NotoSansRegular';
    }
    .select-btn {
        background: url(../../images/common/camera_icon.png) no-repeat  10px / 20px 20px;
    }
    .reviewImgListWrap {
        margin-top: 15px;
    }
    .reviewImgListWrap .swiper {height: auto;}
    .inform-txt {
        display: block;
        padding-left: 0;
        padding-top: 5px;
    }
    .reviewImgItem {    width: 103px;
        height: 83px;position: relative;}
    .reviewImgItem > img {width: 100%;height: 100%;}
    .reviewShow_wrap {display: block;}
    .reviewShow_wrap .swiper-pagination-type-2 .swiper-pagination-bullet {background-color: #afb4b2;}
    .reviewShow_wrap .swiper-pagination-type-2 .swiper-pagination-bullet-active {background-color: #1c1c1c;}
    .reviewShow_wrap .slide-card-items-list-type.swiper {width: 100%;height: auto;}
    .reviewShow_wrap .slide-card-items-list-type.swiper .swiper-slide {height: 93vw;}
    .seen-classInfo {width: 100%;}
    .seen-classInfo > .MuiGrid-item .classReview-info-img {
        width: 80px;
        height: 73px;
    }
    .seen-classInfo > .MuiGrid-item .classReview-info-img + div {
        width: calc(100% - 80px);
        padding: 0 14px;
    }
    .seen-classInfo .main-grid {
        padding-top: 18px;
        margin-top: 0;
    }
    .seen-classInfo .txt_gray {
        font-size: 12px !important;
    }
    .classReview_dropBoxWrap {
        border-top: none;
    }
    .review_thum_slide_wrap .slide-card-items-list-type.swiper {max-width: 100%;}
    .review_thum_slide_wrap .slide-card-items-list-type .swiper-slide {
        width: 42.6% !important;
        height: 115px;
    }
    
    .review_thum_wrap {
        width: 176px;
        height: 140px;
        margin-top: 10px;
        margin-bottom: 18px;
        position: relative;
        background-color: #fff;
    }
    .review_thum_wrap span {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 26px;
        height: 26px;
        font-size: 16px;
        color: #fff;
        line-height: 26px;
    }
    .seen-classInfo .txt_gray + p {
        height: auto;
        font-size: 14px;
    }
}
