@import "../style.scss";

.MuiContainer-root.mainWrap {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  min-height: 100vh;

  .main-title {
    text-align: left;
    padding: 20px 40px 0 15px;
    display: inline-block;
    max-width: 1260px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .main-grid {
      display: block;
    }

    p {
      font-size: 16px;
      letter-spacing: -1px;
      color: #666;
      margin-bottom: 0;
    }

    h2 {
      font-family: "NotoSansBold";
      font-size: 24px;
      color: #1c1c1c;
      letter-spacing: -2px;
    }
  }
  .btn-main {
    font-weight: bold;
    font-family: "NotoSansRegular";
    border-radius: 50px;
    height: 36px;
    padding: 5px 21px 7px 25px;

    &.btn-magazine {
      background-color: #f4e79c;
      color: #b18900;
    }
  }
  .main-banner {
    .slide-banner.swiper img {
      width: 100%;
      height: 100%;
      max-width: 1260px;
    }
  }
  .main-class {
    width: 100%;
    position: relative;
    overflow: hidden;

    article {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 1260px;
      padding: 20px 0 20px 15px;
    }

    &:nth-of-type(odd) .btn-main {
      background-color: #b0d8fa;
      color: #1470bf;
    }
    &:nth-of-type(even) .btn-main {
      background-color: #d3e2a3;
      color: #5e7c01;
    }
  }
  .main-video {
    position: relative;
    background-color: #eeeafb;
    padding: 0 5.5% 100px;
    > .main-title {
      padding: 110px 0 40px;
      margin: 0 auto;
      max-width: 1260px;
      position: relative;
      text-align: center;
      p {
        color: #1c1c1c;
        font-family: NotoSansBold;
        font-size: 34px;
        letter-spacing: -2px;
        line-height: 1.2;

        &:first-child {
          color: #666;
          font-family: NotoSansRegular, Lato, sans-serif;
          font-size: 20px;
          letter-spacing: -1px;
          margin-bottom: 5px;
        }
      }
    }
    .player-wrap {
      margin: 0 auto;
      max-width: 1260px;
      text-align: center;
      width: 100%;
      .react-player {
        display: inline-block;
        // width: 918px; height: 516px;
      }
    }
  }
  .main-label {
    .slide-label.swiper img {
      width: 100%;
      height: 100%;
      max-width: 1260px;
    }
  }
  .main-keyword {
    padding: 100px 5.5%;
    background-color: #48a7f3;
    div {
      margin: 0 auto;
      max-width: 1260px;

      h2 {
        color: #fff;
        font-family: NotoSansBold;
        font-size: 40px;
      }

      .keyword-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        padding: 5% 16% 0 16%;

        a {
          border-radius: 22px;
          color: #fff;
          font-size: 16px;
          margin: 0 10px 10px 0;
          padding: 10px 15px;
          background-color: #7fc2f7;
          display: inline-block;
        }
      }
    }
  }
  .main-magazine {
    .section-inner {
      margin: 0 auto;
      padding: 20px 0 0 15px;
      max-width: 1260px;

      .main-magazine-item {
        margin-bottom: 40px;
      }

      .txt-position {
        position: absolute;
        padding: 35px 30px 10px 30px;
        width: 276px;
        max-height: 220px;

        & + img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          border-bottom: none;
        }
      }

      h3 {
        font-family: "NotoSansBold";
        font-size: 16px;
        // height: 2.6em;
        height: auto;
        line-height: 1.3;
        color: #1c1c1c;
        text-align: left;
        cursor: pointer;
        margin-bottom: 10px;
        letter-spacing: -0.5px;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // display: -webkit-box;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      p {
        font-family: "NotoSansRegular";
        font-size: 12px;
        text-align: left;
        color: #666;
        line-height: 1.4;
        width: 100%;
        letter-spacing: -0.38px;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .swiper-pagination {
        bottom: 15px;
      }
    }
  }
}

.sub-gnb-area {
  display: flex;
  position: fixed;
  top: 168px;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;

  li {
    position: relative;
    flex: 1;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      height: 16px;
      margin-top: -8px;
      background-color: #e0e0e0;
    }
    &:last-child::after {
      display: none;
    }

    a {
      display: block;
      line-height: 47px;
      color: #1c1c1c;
      font-family: "NotoSansMedium";
      font-size: 14px;

      &.active {
        color: #2f99f4;
      }
    }
  }
}

.navigation-wrap {
  align-items: center;
  background-color: #fff;
  border: 1px solid #222;
  border-radius: 20px;
  bottom: 50px;
  display: inline-block;
  display: flex;
  height: 40px;
  padding: 4px 15px 7px 24px;
  position: relative;
  position: absolute;
  right: 17%;
  width: 120px;
  z-index: 1;

  &::before {
    background-color: #afb4b2;
    content: "";
    display: block;
    height: 18px;
    position: absolute;
    right: 34px;
    top: 10px;
    width: 1px;
  }

  .slideNum {
    span {
      color: #afb4b2;
      font-family: NotoSansBold;
      font-size: 20px;
      &.on {
        color: #222;
      }
    }
  }

  .swiper-button {
    position: absolute;
    top: 32px;
  }

  .swiper-button-next {
    content: "";
    background: transparent url(../../images/common/main-next-arrow.png)
      no-repeat center center / 100%;
    width: 16px;
    height: 16px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
    left: 91px;
    content: none;
    font-size: 0;
    ::after {
      background: transparent url(../../images/common/main-prev-arrow.png)
        no-repeat center center / 100%;
      left: 67px;
      right: auto;
    }
  }
  .swiper-button-prev {
    content: "";
    background: transparent url(../../images/common/main-prev-arrow.png)
      no-repeat center center / 100%;
    width: 16px;
    height: 16px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
    left: 52px;
    font-size: 0;
    ::after {
      background: transparent url(../../images/common/main-prev-arrow.png)
        no-repeat center center / 100%;
      left: 67px;
      right: auto;
    }
  }
}

@media screen and (max-width: 1023px) {
  .MuiContainer-root.mainWrap {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;

    .main-title {
      p {
        font-size: 14px;
      }
      .main-grid h2 {
        font-size: 20px;
      }
    }
    .main-label {
      .slide-card-items-list-type.swiper {
        padding: 0;
      }
    }
    .main-keyword {
      padding: 0 5.5%;
      background-color: #48a7f3;
      div {
        width: 100%;
        margin: 0 auto;
        padding: 40px 0 40px;

        h2 {
          text-align: left;
          font-size: 16px;
        }

        .keyword-wrap {
          padding: 5% 0 0 0;
          a {
            font-size: 14px;
          }
        }
      }
    }
    .main-video {
      > .main-title {
        p {
          &:first-child {
            font-size: 16px;
          }
          font-size: 24px;
          letter-spacing: 0;
        }
      }
      .player-wrap .react-player {
        height: 51.6vw;
      }
    }
  }
}
