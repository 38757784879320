@font-face {
    font-family: "NotoSansLight";
    font-style: normal;
    src: url(./assets/font/NotoSansKR-Light.woff2) format("woff2"),
      url(./assets/font/NotoSansKR-Light.woff) format("woff"),
      url(./assets/font/NotoSansKR-Light.otf) format("opentype");
  }
  
  @font-face {
    font-family: "NotoSansRegular";
    font-style: normal;
    src: url(./assets/font/NotoSansKR-Regular.woff2) format("woff2"),
      url(./assets/font/NotoSansKR-Regular.woff) format("woff"),
      url(./assets/font/NotoSansKR-Regular.otf) format("opentype");
  }
  
  @font-face {
    font-family: "NotoSansMedium";
    font-style: normal;
    src: url(./assets/font/NotoSansKR-Medium.woff2) format("woff2"),
      url(./assets/font/NotoSansKR-Medium.woff) format("woff"),
      url(./assets/font/NotoSansKR-Medium.otf) format("opentype");
  }
  
  @font-face {
    font-family: "NotoSansBold";
    font-style: normal;
    src: url(./assets/font/NotoSansKR-Bold.woff2) format("woff2"),
      url(./assets/font/NotoSansKR-Bold.woff) format("woff"),
      url(./assets/font/NotoSansKR-Bold.otf) format("opentype");
  }
  
  @font-face {
    font-family: "NotoSansBlack";
    font-style: normal;
    src: url(./assets/font/NotoSansKR-Black.woff2) format("woff2"),
      url(./assets/font/NotoSansKR-Black.woff) format("woff"),
      url(./assets/font/NotoSansKR-Black.otf) format("opentype");
  }
  
/* init */
*{margin:0;padding:0;  box-sizing: border-box;} 
html,body{width:100%; margin: 0 auto;}
html{overflow-y:scroll}
html body{
    font-size:14px;
    font-family: "NotoSansRegular", sans-serif;
	line-height: 1;
	*word-break:break-all;
	-ms-word-break:break-all;
}
body,h1,h2,h3,h4,h5,h6,th,td,input,textarea,select,option,a,b{
	font-size:14px;
	font-family: "NotoSansMedium", sans-serif;
	line-height: 1;
}
body p{margin:0;padding:0;}
img,fieldset,iframe{border:0 none}
ul,ol,li{list-style:none;margin:0;padding:0;}
em,address{font-style:normal}
img,input,select,textarea{vertical-align:middle}
a{text-decoration:none;cursor:pointer}
a:hover{color:#404040;text-decoration:none}
button,label{cursor:pointer;_cursor /**/:hand}
button *{position:relative}
*:focus {outline: none !important; outline-width: 0; box-shadow: none !important;}
textarea{overflow:auto}
option{padding-right:6px}
.input_text, textarea{*margin:-1px 0;padding-right:1px}
.input_check, .input_radio{width:13px;height:13px}
.blind, legend, caption{text-indent:-9999px;font-size:0;height:0;line-height:0;overflow:hidden}
hr{display:none}
legend{*width:0}
:root legend{font-size:0;line-height:0}
caption{*line-height:0}
textarea {display: inline-block; flex-direction: column; padding: 2px; margin: 0em; background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59)); border-width: 1px; border-style: solid; border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); border-image: initial; -webkit-rtl-ordering: logical; white-space: pre-wrap; overflow-wrap: break-word; column-count: initial !important;  font: 400 13.3333px Arial; color: -internal-light-dark(black, white); text-align: start; text-rendering: auto;  letter-spacing: normal; word-spacing: normal;  text-transform: none; text-indent: 0px; text-shadow: none;  -webkit-writing-mode: horizontal-tb !important; resize: auto; appearance: textarea; cursor: text;}
textarea:focus{outline:none !important;}
button:focus{outline:none !important;}
.cls::after {content: ''; clear: both; display: block; }
.fl-l {float: left !important; }
.fl-r {float: right !important; }
.ta-l {text-align: left !important;}
.ta-c {text-align: center !important;}
.ta-r {text-align: right !important;}
.va-t {vertical-align: top !important;}
.va-m {vertical-align: middle !important;}
.d-none {display: none !important; }
.pb-20 {padding-bottom: 20px;}
.pb-30 {padding-bottom: 30px;}
.mt-15 {margin-top:15px;}
.mb-20 {margin-bottom: 20px;}
.bt_line {border-bottom:solid 1px #dfdfdf;}
.t_line {border-right:solid 1px #dfdfdf;}
.bt_line_lt{border-bottom: 1px solid #e0e0e0;}
.bdt_line_lt{border-top: 1px solid #e0e0e0;}
.lt_line {border-left:solid 1px #dfdfdf;}

/* TABLE */
/* table {width: 100%; white-space: nowrap; border: solid 1px #dfdfdf;} */
tbody {background: #fff;}
thead {background: #fff;}
/* tr:hover {background: #EDF0FF;} */
th, td {color: #404040; padding: 10px; text-align: center;}
td{position: relative;}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
