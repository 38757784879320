.sub-teacher-card-wrap {
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li {
        flex: 0 0 auto;
        width: 20.7%;
        height: 352px;
        background: #bfd5e2;
        position: relative;
        border: 2px solid #0e2b49;

        a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 30px;
        }

        &:not(:last-child),
        &:nth-child(4n) {
            margin-right: 47px;
            margin-bottom: 47px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 13px;
            left: 13px;
            width: 100%;
            height: 100%;
            background: #0e2b49;
        }

        &:nth-child(2n) {
            background: #d5dcb9;
        }
        &:nth-child(3n) {
            background: #e8e0cc;
        }
        &:nth-child(4) {
            background: #ead2e3;
        }

        h1 {
            font-family: 'NotoSansBold';
            font-size: 24px;
            color: #4e6883;
            text-align: center;
        }
    }
}


/* Main teacherCard */
.teacherCard {
	flex: 0 0 auto;
	width: 192px;
	height: 245px;
	padding: 20px;
	background: #bfd5e2;
	position: relative;
	border: 2px solid #0e2b49;
    border-radius: 0;
    overflow: inherit;

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 10px;
        left: 10px;
        width: 100%;
        height: 100%;
        background: #0e2b49;
        border-radius: 0;
    }

    h3 {
        font-family: "NotoSansBold";
        font-size: 24px;
        color: #4e6883;
        text-align: center;
    }
}

@media screen and (max-width: 1023px) {
    .sub-teacher-card-wrap {
        padding: 30px 16px;

        li {
            width: 44.8%;
            height: 50.5vw;

            &:not(:last-child),
            &:nth-child(4n) {
                margin-right: 7%;
                margin-bottom: 7%;
            }

            h1 {
                font-size: 15px;
            }

            &::before {
                top: 7px;
                left: 7px;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}