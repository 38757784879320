.eventDetail .subVisual {
	margin-bottom: 76px;
}
.event-slider {
    overflow: hidden;
    position: relative;
	background: url('../../images/event/PC_2.jpg');
	padding: 5% 0 5% 0;
    box-sizing: border-box;
}
.event-slider-box {
    width:300%;
    height:100%;
    display:flex;
    flex-wrap:nowrap;
    animation: bannermove 15s linear infinite;
}

.LinkInWrap.p_r {
    overflow: hidden;

}

/* 2022-11-11 유의사항 확인하기 토글 이미지 하드 코딩 css 시작 */
.noticeToggle {
    width: 100%;
	height: 63px;
	background: url('../../images/event/livealUse/PC_2_off.png') no-repeat;
	cursor: pointer;
    background-size: contain;
}
.noticeToggle.isOn {
	background: url('../../images/event/livealUse/PC_2_on.png') no-repeat;
    background-size: contain;
}

.noticeToggle_info {
    display: none;
    background-color: #efefef;
}

.noticeToggle_info.isOn {display: block;}

.liveallPoint .noticeToggle {
    width: 100%;
	height: 44px;
	background: url('../../images/event/liveallPoint/PC_TAB_OPEN.png') no-repeat;
	cursor: pointer;
    background-size: cover;
	background-position: center;
}
.liveallPoint .noticeToggle.isOn {
	background: url('../../images/event/liveallPoint/PC_TAB.png') no-repeat;
    background-size: cover;
	background-position: center;
}

.welcomeLiveAll .noticeToggle {
    width: 100%;
	height: 37px;
	background: url('../../images/event/welcome/PC_WELCOME_TAB.png') no-repeat;
	cursor: pointer;
    background-size: cover;
	background-position: center;
}
.welcomeLiveAll .noticeToggle.isOn {
    background: url('../../images/event/welcome/PC_WELCOME_TABCLOSE.png') no-repeat;
    background-size: cover;
	background-position: center;
}



button.link-btn.MuiButton-root{
	font-size: 0;
    position: absolute !important;
	cursor: pointer;
}

button.link-btn.MuiButton-root.eventJoinBtn {
	bottom: 16%;
    height: 10vw;
    width: 20%;
    left: 14%;
}

.welcomeLiveAll button.link-btn.MuiButton-root {
    font-size: 0;
    position: absolute !important;
    cursor: pointer;
    left: 50%; transform: translateX(-50%);
    width: 54%; height: 40%;
    bottom: 30%;
}

.welcomeLiveAll .recommend-btn.btnCoupon {
    top: 87%;
}

.liveallPoint button.link-btn.MuiButton-root.eventJoinBtn {
	bottom: 30px;
    height: 120px;
    width: 42%;
    left: 50%;
    transform: translateX(-50%);
}

.liveallPoint a.eventApplyBtn, .liveallPoint a.eventPointBtn{
	display: inline-block;
    bottom: 40px;
    height: 120px;
    width: 100%;
    left: 0;
    transform: translateX(0);
    position: absolute;
}
button.link-btn.MuiButton-root.eventPointBtn,
button.link-btn.MuiButton-root.eventReviewBtn,
button.link-btn.MuiButton-root.eventJoinBtn {
	-webkit-tap-highlight-color: transparent;
    color: transparent;
    background: transparent;
}
button.link-btn.MuiButton-root.eventPointBtn,
button.link-btn.MuiButton-root.eventReviewBtn{
	bottom: 4%;
    height: 160px;
    width: 42%;
    right: 16%;
}

.liveallPoint button.link-btn.MuiButton-root.eventPointBtn{
	bottom: 6%;
    height: 120px;
    width: 33%;
    right: 18%;
}

.event-detail-img.pb-20 {padding-bottom: 20px; background-color: #efefef;}

/* //2022-11-11 유의사항 확인하기 토글 이미지 하드 코딩 css 끝 */

/* 2023-01-26 라이브올 사용법 */
.event-detail-img.liveallUse2 {padding-bottom: 20px; background-color: #efefef;}
.liveallUse2 .link-btn.join {
	left: 43.8%; /* 554px */
	top: 88.3%; /* 1150px */
	width: 38.88%; /* 490px */
	height: 6.34%; /* 80px */
}
.liveallUse2 .recommend-btn {
	position: absolute;
	left: 32.14%; /* 405px */
	top: 35.82%; /* 364px */
	width: 35.71%; /* 450px */
	height: 8.36%; /* 85px */
}

.welcomeLiveAll .recommend-btn {
    position: absolute;
    top: 51%; left: 50%;
    transform: translate(-50%, -50%);
    width: 50%; height: 7%;
}
/* // 2023-01-26 라이브올 사용법 */

/* 2023-02-08 셀럽특강 */
.event-detail-img.celeb {background-color: #efefef;}
.event-detail-img.celeb .link-btn {position: absolute; z-index: 1; width: 31.03%; height: 2.95%; top: 93.56%;}
.event-detail-img.celeb .link-btn.intro {left: 15.95%;}
.event-detail-img.celeb .link-btn.regist {left: 53.09%;}
/* 2023-02-08 셀럽특강 */

/* 2023-03-08 특별제휴 프로모션 */
.event-detail-img.alliance {background-color: #011f3b;}
.event-detail-img.alliance .link-btn {position: absolute; z-index: 1;}
.event-detail-img.alliance .link-btn[class*="class"] {top: 28.43%; width: 28.96%; height: 4.99%;}
.event-detail-img.alliance .link-btn.class1 {left: 3.96%;}
.event-detail-img.alliance .link-btn.class2 {left: 35.55%;}
.event-detail-img.alliance .link-btn.class3  {left: 67.06%;}
.event-detail-img.alliance .link-btn.join {left: 24.2%; top: 88%; width: 51.66%; height: 5.82%;}
.event-detail-img.alliance .link-btn.view {left: 24.2%; top: 63.86%; width: 51.66%; height: 3.23%;}
/* // 2023-03-08 특별제휴 프로모션 */

/* 2023-03-30 4월 취미클래스 */
.event-detail-img.hobbyClass {background-color: #ffa69e;}
.event-detail-img.hobbyClass .link-btn {background-color: transparent; position: absolute; z-index: 1; }
.event-detail-img.hobbyClass .link-btn[class*="card"] {width: 43.5%; height: 23%;}
.event-detail-img.hobbyClass .link-btn.card1 {top: 4.25%; left: 4.8%;}
.event-detail-img.hobbyClass .link-btn.card2 {top: 4.25%; right: 4.8%;}
.event-detail-img.hobbyClass .link-btn.card3 {top: 28.5%; left: 4.8%;}
.event-detail-img.hobbyClass .link-btn.card4 {top: 28.5%; right: 4.8%;}
.event-detail-img.hobbyClass .link-btn.card5 {top: 52.8%; left: 4.8%;}
.event-detail-img.hobbyClass .link-btn.card6 {top: 52.8%; right: 4.8%;}
.event-detail-img.hobbyClass .link-btn.card7 {top: 77.1%; left: 50%; transform: translateX(-50%); height: 12.4%; width: 90.5%;}
.event-detail-img.hobbyClass .link-btn.toClass{bottom: 3.5%; left: 50%; transform: translateX(-50%); height: 5%; width: 90.5%;}
/* 2023-03-30 4월 취미클래스 */

/* 2023-04-10 과학의 날, 책의 날 기획전 */
.event-detail-img.exhibitScience .goToScience{width: 50%;position: absolute; z-index: 1;left: 0; height: 10%; bottom: 13.5%;}
.event-detail-img.exhibitScience .goToReading{width: 50%;position: absolute; z-index: 1;right: 0; height: 10%; bottom: 13.5%;}
.event-detail-img.exhibitScience .link-btn {background-color: transparent; position: absolute; z-index: 1; left: 50%; transform: translateX(-50%); width: 92%;}
.event-detail-img.exhibitScience .link-btn.class0{bottom: 0.5%;  height: 51%;}
.event-detail-img.exhibitScience .link-btn.class1{top: 33%; height: 22%;}
.event-detail-img.exhibitScience .link-btn.class2{bottom: 9%; height: 22%;}
.event-detail-img.exhibitScience .link-btn.class3{top: 41%; height: 23%;}
.event-detail-img.exhibitScience .link-btn.class4{bottom: 0%; height: 23%;}
.event-detail-img.exhibitScience .link-btn.class5{top: 24%; height: 17%;}
.event-detail-img.exhibitScience .link-btn.class6{bottom: 30%; height: 17%;}
.event-detail-img.exhibitScience .link-btn.toClass{height: 8%; width: 100%; bottom: 15%;}
/* 2023-04-10 과학의 날, 책의 날 기획전 */

/* 2023-05-11 가정의 달 */
.event-detail-img.familyMonth {padding-bottom: 30px; background-color: #ffffe7;}
.event-detail-img.familyMonth button:hover,
.event-detail-img.familyMonth a:hover {background-color: transparent;}
.event-detail-img.familyMonth .event-tag-btn {position: absolute; top: 0; width: 50%; height: 100%;}
.event-detail-img.familyMonth .event-tag-btn.e1 {left: 0;}
.event-detail-img.familyMonth .event-tag-btn.e2 {left: 50%;}
.event-detail-img.familyMonth .hashtag {position: absolute; top: -150px;}
.event-detail-img.familyMonth .coupon-btn {position: absolute; top: 57.94%; width: 39.76%; height: 7.44%;}
.event-detail-img.familyMonth .coupon-btn.per30 {left: 8.09%;}
.event-detail-img.familyMonth .coupon-btn.per20 {left: 52.3%;}
.event-detail-img.familyMonth .class-btn {position: absolute; left: 22.38%; top: 78.05%; width: 55.31%; height: 8.33%;}
.event-detail-img.familyMonth .event-join-btn {position: absolute; left: 22.38%; top: 83.72%; width: 55.31%; height: 6.85%;}
.event-detail-img.familyMonth .noticeToggle {background-image: url('../../images/event/familyMonth/PC_04_TAB_OPEN.png'); background-size: 100%; background-position: center;}
.event-detail-img.familyMonth .noticeToggle.isOn {background-image: url('../../images/event/familyMonth/PC_04_TAB_CLOSE.png');}
.modal-pop-wrap.sns-url-regist .modal-pop-contents .popContents {height: auto;}
.modal-pop-wrap.sns-url-regist .txt {margin-bottom: 10px; color: #333; font-size: 18px; font-weight: 700;}
.modal-pop-wrap.sns-url-regist .txtarea {width: 100%; height: 90px; padding: 20px; border: 1px solid #e5e8ea; border-radius: 6px; resize: none;}

/* 2023-06-22 라이브올 여름캠프 */
.event-detail-img.summerCamp button:hover,
.event-detail-img.summerCamp a:hover {background-color: transparent; font-size: 0;}
.event-detail-img.summerCamp .hashtag {position: absolute; left: 0; top: -180px;}
.event-detail-img.summerCamp .scamp01 .lecturer {position: absolute; left: 23.49%; top: 41.45%; width: 58.25%;}
.event-detail-img.summerCamp .scamp01 .bubble-box img {position: absolute; opacity: 0.5; animation: bubbleScale 1.3s ease-in-out infinite;}
.event-detail-img.summerCamp .scamp01 .bubble-box .bubble1 {left: 16.58%; top: 38.97%; width: 12.14%; animation-delay: 0.1s;}
.event-detail-img.summerCamp .scamp01 .bubble-box .bubble2 {left: 7.06%; top: 48%; width: 12.7%; animation-delay: 1.1s;}
.event-detail-img.summerCamp .scamp01 .bubble-box .bubble3 {left: 5.87%; top: 59.45%; width: 16.74%; animation-delay: 0.5s;}
.event-detail-img.summerCamp .scamp01 .bubble-box .bubble4 {left: 72.61%; top: 38.3%; width: 10.79%; animation-delay: 0.8s;}
.event-detail-img.summerCamp .scamp01 .bubble-box .bubble5 {left: 77.77%; top: 47.39%; width: 16.82%;}
.event-detail-img.summerCamp .scamp01 .bubble-box .bubble6 {left: 76.42%; top: 61.45%; width: 12.7%; animation-delay: 0.3s;}

/* .event-detail-img.summerCamp .scamp02 {padding: 0.95% 4.76% 5.55%; background-color: #f7f1da;} */
.event-detail-img.summerCamp .scamp02 {padding: 0.95% 4.76% 15%; background-color: #f7f1da;}
.event-detail-img.summerCamp .scamp02 .tit {width: 34.12%; margin-bottom: 4.38%;}
.event-detail-img.summerCamp .scamp02 .balloon {position: relative; margin: 0 auto 3.65%; padding: 6.74% 0; background: url(../../images/event/summerCamp/PC_SCAMP02_balloon_top.png) center 0/100% no-repeat, url(../../images/event/summerCamp/PC_SCAMP02_balloon_btm.png) center bottom/100% no-repeat;}
.event-detail-img.summerCamp .scamp02 .balloon .inner {padding: 0.87% 0 3.24%; background: url(../../images/event/summerCamp/PC_SCAMP02_balloon_mid.png) center 0/100% repeat-y;}
.event-detail-img.summerCamp .scamp02 .balloon .box-tit {width: 61.31%; margin-bottom: 4.3%;}

.event-detail-img.summerCamp .scamp02 .stamp-box .stamp {display: inline-block; position: relative; width: 12.19%; margin: 1.09%; padding-bottom: 12.19%; background: center/100% no-repeat; vertical-align: top;}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(12) ~ * {display: none;}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(1) {background-image: url('../../images/event/summerCamp/stamp/stamp01.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(2) {background-image: url('../../images/event/summerCamp/stamp/stamp02.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(3) {background-image: url('../../images/event/summerCamp/stamp/stamp03.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(4) {background-image: url('../../images/event/summerCamp/stamp/stamp04.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(5) {background-image: url('../../images/event/summerCamp/stamp/stamp05.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(6) {background-image: url('../../images/event/summerCamp/stamp/stamp06.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(7) {background-image: url('../../images/event/summerCamp/stamp/stamp07.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(8) {background-image: url('../../images/event/summerCamp/stamp/stamp08.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(9) {background-image: url('../../images/event/summerCamp/stamp/stamp09.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(10) {background-image: url('../../images/event/summerCamp/stamp/stamp10.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(11) {background-image: url('../../images/event/summerCamp/stamp/stamp11.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(12) {background-image: url('../../images/event/summerCamp/stamp/stamp12.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(13) {background-image: url('../../images/event/summerCamp/stamp/stamp13.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(14) {background-image: url('../../images/event/summerCamp/stamp/stamp14.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(15) {background-image: url('../../images/event/summerCamp/stamp/stamp15.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(16) {background-image: url('../../images/event/summerCamp/stamp/stamp16.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(17) {background-image: url('../../images/event/summerCamp/stamp/stamp17.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(18) {background-image: url('../../images/event/summerCamp/stamp/stamp18.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(19) {background-image: url('../../images/event/summerCamp/stamp/stamp19.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(20) {background-image: url('../../images/event/summerCamp/stamp/stamp20.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(21) {background-image: url('../../images/event/summerCamp/stamp/stamp21.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(22) {background-image: url('../../images/event/summerCamp/stamp/stamp22.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(23) {background-image: url('../../images/event/summerCamp/stamp/stamp23.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(24) {background-image: url('../../images/event/summerCamp/stamp/stamp24.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(25) {background-image: url('../../images/event/summerCamp/stamp/stamp25.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(26) {background-image: url('../../images/event/summerCamp/stamp/stamp26.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(27) {background-image: url('../../images/event/summerCamp/stamp/stamp27.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(28) {background-image: url('../../images/event/summerCamp/stamp/stamp28.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(29) {background-image: url('../../images/event/summerCamp/stamp/stamp29.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(30) {background-image: url('../../images/event/summerCamp/stamp/stamp30.png');}

.event-detail-img.summerCamp .scamp02 .stamp-box.open .stamp:nth-child(12) ~ * {display: inline-block;}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(1).on {background-image: url('../../images/event/summerCamp/stamp/stamp01_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(2).on {background-image: url('../../images/event/summerCamp/stamp/stamp02_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(3).on {background-image: url('../../images/event/summerCamp/stamp/stamp03_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(4).on {background-image: url('../../images/event/summerCamp/stamp/stamp04_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(5).on {background-image: url('../../images/event/summerCamp/stamp/stamp05_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(6).on {background-image: url('../../images/event/summerCamp/stamp/stamp06_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(7).on {background-image: url('../../images/event/summerCamp/stamp/stamp07_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(8).on {background-image: url('../../images/event/summerCamp/stamp/stamp08_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(9).on {background-image: url('../../images/event/summerCamp/stamp/stamp09_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(10).on {background-image: url('../../images/event/summerCamp/stamp/stamp10_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(11).on {background-image: url('../../images/event/summerCamp/stamp/stamp11_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(12).on {background-image: url('../../images/event/summerCamp/stamp/stamp12_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(13).on {background-image: url('../../images/event/summerCamp/stamp/stamp13_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(14).on {background-image: url('../../images/event/summerCamp/stamp/stamp14_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(15).on {background-image: url('../../images/event/summerCamp/stamp/stamp15_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(16).on {background-image: url('../../images/event/summerCamp/stamp/stamp16_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(17).on {background-image: url('../../images/event/summerCamp/stamp/stamp17_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(18).on {background-image: url('../../images/event/summerCamp/stamp/stamp18_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(19).on {background-image: url('../../images/event/summerCamp/stamp/stamp19_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(20).on {background-image: url('../../images/event/summerCamp/stamp/stamp20_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(21).on {background-image: url('../../images/event/summerCamp/stamp/stamp21_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(22).on {background-image: url('../../images/event/summerCamp/stamp/stamp22_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(23).on {background-image: url('../../images/event/summerCamp/stamp/stamp23_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(24).on {background-image: url('../../images/event/summerCamp/stamp/stamp24_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(25).on {background-image: url('../../images/event/summerCamp/stamp/stamp25_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(26).on {background-image: url('../../images/event/summerCamp/stamp/stamp26_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(27).on {background-image: url('../../images/event/summerCamp/stamp/stamp27_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(28).on {background-image: url('../../images/event/summerCamp/stamp/stamp28_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(29).on {background-image: url('../../images/event/summerCamp/stamp/stamp29_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(30).on {background-image: url('../../images/event/summerCamp/stamp/stamp30_on.png');}
.event-detail-img.summerCamp .scamp02 .stamp-box .stamp.on::after {content: ''; display: block; position: absolute; left: -5px; top: -5px; width: calc(100% + 10px); height: calc(100% + 10px); border: 10px solid #5dd4da; border-radius: 50%; box-sizing: border-box; animation: stampOnScale 0.3s;}

.event-detail-img.summerCamp .scamp02 .stamp-open-btn {position: absolute; left: 50%; bottom: 8.57%; width: 10.63%; transform: translateX(-50%);}
/* .event-detail-img.summerCamp .scamp02 .btn {width: 53.86%; padding: 0;} */
.event-detail-img.summerCamp .scamp02 .btn {
    width: 50%; height: 13.5%;
    padding: 0;
    position: absolute; left: 50%; transform: translateX(-50%);
    background: url('../../images/event/summerCamp/PC_SCAMP02_btn.png') 0/100% no-repeat;
}


.event-detail-img.summerCamp .scamp03 .btn {position: absolute; top: 30.1%; width: 43.65%; height: 57.36%;}
.event-detail-img.summerCamp .scamp03 .btn.b1 {left: 4.84%;}
.event-detail-img.summerCamp .scamp03 .btn.b2 {left: 51.5%;}

.event-detail-img.summerCamp .scamp04 .bubble-box img {position: absolute; animation: bubbleScale2 1.8s infinite;}
.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble1 {left: 24.36%; top: 14%; z-index: 2; width: 20.71%; transform-origin: right bottom; animation-delay: 0.1s;}
.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble2 {left: 43.65%; top: 16.36%; z-index: 3; width: 16.9%;}
.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble3 {left: 57.54%; top: 14.37%; z-index: 4; width: 16.82%; transform-origin: 0 bottom; animation-delay: 0.05s;}
.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble4 {left: 25.63%; top: 20.9%; z-index: 1; width: 22.93%; transform-origin: right 0;}
.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble5 {left: 53.09%; top: 21.61%; z-index: 5; width: 22.54%; transform-origin: 0 0; animation-delay: 0.15s;}
.event-detail-img.summerCamp .scamp04 .btn.b1 {position: absolute; left: 19.52%; top: 35.25%; width: 61.03%; height: 4.24%;}
.event-detail-img.summerCamp .scamp04 .btn.c1,
.event-detail-img.summerCamp .scamp04 .btn.c2,
.event-detail-img.summerCamp .scamp04 .btn.c3,
.event-detail-img.summerCamp .scamp04 .btn.c4 {position: absolute; width: 33.89%; height: 3.36%;}
.event-detail-img.summerCamp .scamp04 .btn.c1 {left: 11.34%; top: 68.55%;}
.event-detail-img.summerCamp .scamp04 .btn.c2 {left: 54.76%; top: 68.55%;}
.event-detail-img.summerCamp .scamp04 .btn.c3 {left: 11.34%; top: 90.8%;}
.event-detail-img.summerCamp .scamp04 .btn.c4 {left: 54.76%; top: 90.8%;}

.event-detail-img.summerCamp .scamp05 .camp-slider {position: absolute; left: 0; overflow: hidden; width: 100%; padding-bottom: 32.54%;}
.event-detail-img.summerCamp .scamp05 .camp-slider .box {display: flex; flex-wrap: nowrap; position: absolute; left: 0; top: 8.536%; height: 84.878%; animation: bannermove 12s linear infinite;}
.event-detail-img.summerCamp .scamp05 .camp-slider .box > a {display: block; flex-shrink: 0; height: 100%;}
.event-detail-img.summerCamp .scamp05 .camp-slider .box > a img {max-height: 100%;}
.event-detail-img.summerCamp .scamp05 .camp-slider.slider1 {top: 20.2%; background-color: #fff55f;}
.event-detail-img.summerCamp .scamp05 .camp-slider.slider1 .box {width: 281.746%;}
.event-detail-img.summerCamp .scamp05 .camp-slider.slider2 {top: 49.78%; background-color: #136c6f;}
.event-detail-img.summerCamp .scamp05 .camp-slider.slider2 .box {width: 450.793%; animation-duration: 19.2s;}
.event-detail-img.summerCamp .scamp05 .camp-slider.slider3 {top: 80.39%; background-color: #fff55f;}
.event-detail-img.summerCamp .scamp05 .camp-slider.slider3 .box {width: 394.444%; animation-duration: 16.8s;}

.event-detail-img.summerCamp .scamp06 .tit {position: absolute; left: 19.92%; top: 3.06%; z-index: 2; width: 60.23%;}
.event-detail-img.summerCamp .scamp06 .lecturer {position: absolute; left: 21.19%; top: 6.79%; z-index: 1; width: 64.04%;}
.event-detail-img.summerCamp .scamp06 .btn.b1 {position: absolute; left: 30.55%; top: 26.23%; width: 38.97%; height: 3.3%;}
.event-detail-img.summerCamp .scamp06 .btn.b2,
.event-detail-img.summerCamp .scamp06 .btn.b3,
.event-detail-img.summerCamp .scamp06 .btn.b4 {position: absolute; top: 36.56%; width: 23.73%; height: 2.04%;}
.event-detail-img.summerCamp .scamp06 .btn.b2 {left: 8.17%;}
.event-detail-img.summerCamp .scamp06 .btn.b3 {left: 38.17%;}
.event-detail-img.summerCamp .scamp06 .btn.b4 {left: 68.33%;}
.event-detail-img.summerCamp .scamp06 .btn.b5,
.event-detail-img.summerCamp .scamp06 .btn.b6,
.event-detail-img.summerCamp .scamp06 .btn.b7 {position: absolute; left: 65.71%; width: 23.8%; height: 2.09%;}
.event-detail-img.summerCamp .scamp06 .btn.b5 {top: 57.43%;}
.event-detail-img.summerCamp .scamp06 .btn.b6 {top: 78.35%;}
.event-detail-img.summerCamp .scamp06 .btn.b7 {top: 91.03%;}

.event-detail-img.summerCamp .fade-element {
    position: relative;
    margin-top: 80px;
    opacity: 0;
    transition: margin-top 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s linear;
}
.event-detail-img.summerCamp .fade-in {
    margin-top: 0;
    opacity: 1;
}

.event-detail-img.summerCamp .scamp07 {background-color: #fffadc; padding-bottom: 150px;}
.event-detail-img.summerCamp .scamp07 .noticeToggle {background-image: url('../../images/event/summerCamp/PC_SCAMP07_tab_open.png'); background-size: 100%; background-position: center;}
.event-detail-img.summerCamp .scamp07 .noticeToggle.isOn {background-image: url('../../images/event/summerCamp/PC_SCAMP07_tab_close.png');}

@keyframes bubbleScale {
	50% {transform: scale(1.15); opacity: 1;}
	55% {transform: scale(1.15); opacity: 1;}
	100% {transform: scale(1);}
}
@keyframes bubbleScale2 {
	25% {transform: scale(1.15);}
	50% {transform: scale(1);}
}
@keyframes stampOnScale {
	50% {transform: scale(0.87);}
}

@keyframes bannermove {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-50%, 0);
	}
}

@keyframes shake{
    0%{
        transform: rotate(0deg);
    }
    10%{
        transform: rotate(45deg);
    }
    20%{
        transform: rotate(-45deg);
    }
    30%{
        transform: rotate(30deg);
    }
    40%{
        transform: rotate(-30deg);
    }
    50%{
        transform: rotate(10deg);
    }
    60%{
        transform: rotate(-10deg);
    }
    70%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

/* 2023-08-24 PC백투더스쿨 */
.event-detail-img.backToTheSchool {max-width: 1260px; margin: 0 auto 25px;}
.event-detail-img.backToTheSchool .btn {position: absolute;}
/* btt-school1 */
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap {position: absolute;top: 39%; left: 8%;width: 85%;height: 38%;}
.event-detail-img.backToTheSchool .btt-school1 .btn.check {width: 41%;height: 8.4%; top: 80%; left:29%}
/* btt-school2 */
.event-detail-img.backToTheSchool .btt-school2 .btn.download-1 {width: 41%;height: 5.1%; top: 39%; left: 7.2%;}
.event-detail-img.backToTheSchool .btt-school2 .btn.download-2 {width: 41%;height: 5.1%;top: 39%; left: 52.3%;}
.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-1 {width: 40.5%;height: 16.75%;top: 54%; left: 7.2%;}
.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-2 {width: 40.5%;height: 16.75%;top: 54%; left: 52.6%;}
.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-3 {width: 40.5%;height: 16.75%;top: 73%; left: 7.2%;}
.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-4 {width: 40.5%;height: 16.75%;top: 73%; left: 52.6%;}
/* btt-school3 */
.event-detail-img.backToTheSchool .btt-school3 .btn.experience {width: 56.7%;height: 7.5%;top: 80%; left: 21.65%;}
/* btt-school4*/
.event-detail-img.backToTheSchool .btt-school4 .btn.gift-1 {width: 36.7%;height: 14.3%;top: 43.5%; left: 11.65%;}
.event-detail-img.backToTheSchool .btt-school4 .btn.gift-2 {width: 36.7%;height: 14.3%;top: 43.5%; left: 51.65%;}
/* 유의사항 */
.event-detail-img.backToTheSchool .btt-school-notice .noticeToggle {background-image: url('../../images/event/backToTheSchool/PC_tab_off.png'); background-size: cover; background-position: center;height: 90px;}
.event-detail-img.backToTheSchool .btt-school-notice .noticeToggle.isOn {background-image: url('../../images/event/backToTheSchool/PC_tab_on.png');}
/* 출석체크 요소 제어 */
/*background-color: rgba(172, 36, 85, 0.66);*/
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp {display: inline-block; position: relative; width: 15.19%; margin: 1% 2.4%; padding-bottom: 17.19%; background: center/100% no-repeat; vertical-align: top;}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(1) {background-image: url('../../images/event/backToTheSchool/stamp/Day1_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(2) {background-image: url('../../images/event/backToTheSchool/stamp/Day2_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(3) {background-image: url('../../images/event/backToTheSchool/stamp/Day3_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(4) {background-image: url('../../images/event/backToTheSchool/stamp/Day4_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(5) {background-image: url('../../images/event/backToTheSchool/stamp/Day5_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(6) {background-image: url('../../images/event/backToTheSchool/stamp/Day6_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(7) {background-image: url('../../images/event/backToTheSchool/stamp/Day7_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(8) {background-image: url('../../images/event/backToTheSchool/stamp/Day8_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(9) {background-image: url('../../images/event/backToTheSchool/stamp/Day9_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(10) {background-image: url('../../images/event/backToTheSchool/stamp/Day10_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(11) {background-image: url('../../images/event/backToTheSchool/stamp/Day11_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(12) {background-image: url('../../images/event/backToTheSchool/stamp/Day12_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(13) {background-image: url('../../images/event/backToTheSchool/stamp/Day13_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(14) {background-image: url('../../images/event/backToTheSchool/stamp/Day14_OFF.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(15) {background-image: url('../../images/event/backToTheSchool/stamp/올_COIN.png');}

.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(1).on {background-image: url('../../images/event/backToTheSchool/stamp/Day1_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(2).on {background-image: url('../../images/event/backToTheSchool/stamp/Day2_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(3).on {background-image: url('../../images/event/backToTheSchool/stamp/Day3_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(4).on {background-image: url('../../images/event/backToTheSchool/stamp/Day4_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(5).on {background-image: url('../../images/event/backToTheSchool/stamp/Day5_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(6).on {background-image: url('../../images/event/backToTheSchool/stamp/Day6_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(7).on {background-image: url('../../images/event/backToTheSchool/stamp/Day7_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(8).on {background-image: url('../../images/event/backToTheSchool/stamp/Day8_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(9).on {background-image: url('../../images/event/backToTheSchool/stamp/Day9_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(10).on {background-image: url('../../images/event/backToTheSchool/stamp/Day10_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(11).on {background-image: url('../../images/event/backToTheSchool/stamp/Day11_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(12).on {background-image: url('../../images/event/backToTheSchool/stamp/Day12_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(13).on {background-image: url('../../images/event/backToTheSchool/stamp/Day13_ON.png');}
.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(14).on {background-image: url('../../images/event/backToTheSchool/stamp/Day14_ON.png');}

.event-detail-img.festival a:hover,
.event-detail-img.festival button:hover {background-color: transparent;}
.event-detail-img.festival .link-btn {
    position: absolute;
    bottom: 0; left: 50%;
    transform: translateX(-50%);
    width: 92%; height: 5.5%;
    z-index: 10;
}
.event-detail-img.festival .noticeToggle { background-image: url('../../images/event/festival/PC_festival_04_00.png'); background-size: cover; background-position: center; height: 3.2vh;}
.event-detail-img.festival .noticeToggle.isOn { background-image: url('../../images/event/festival/PC_festival_04_02.png'); background-size: cover; background-position: center;}
.event-detail-img.festival .noticeToggle_info { background: transparent; }
.event-detail-img.festival .event-slider { background: transparent; position: absolute; top: 0; left: 0; padding: 3% 0;}
.event-detail-img.festival .event-slider-box { width: 370%; }
.event-detail-img.festival .event-slider-box > img { max-width: 100%; height: 100%;}
/* .event-detail-img.festival .event_box { position: absolute; top: 51.3%; left: 50%; transform: translateX(-50%); width: 88.7%;  background-color: rgba(0,0,0,0.15); border-radius: 27px; } */
.event-detail-img.festival .btn_box { background-color: transparent; position: absolute; top: 77%; left: 50%; transform: translateX(-50%); /* width: 994px; */ width: 79%; height: 260px; display: flex; flex-direction: row; justify-content: space-evenly; }
.event-detail-img.festival .btn_box > button { width: 240px; height: 260px; background-size: contain;}
.event-detail-img.festival .btn_entry { background: url('../../images/event/festival/stamp/btn_participate.png') no-repeat;}
.event-detail-img.festival .btn_festival_join { background: url('../../images/event/festival/stamp/btn_entry.png') no-repeat;}
.event-detail-img.festival .btn_entry.on { background: url('../../images/event/festival/stamp/btn_participate_on.png') no-repeat; background-size: contain; pointer-events: none;}
.event-detail-img.festival .btn_festival_join.on { background: url('../../images/event/festival/stamp/btn_entry_on.png') no-repeat; background-size: contain; pointer-events: none;}

.event-detail-img.joinRenewal a:hover,
.event-detail-img.joinRenewal button:hover {background-color: transparent;}
.event-detail-img.joinRenewal .link-btn {
    position: absolute;
    bottom: 8.5%; left: 50%; transform: translateX(-50%);
    width: 73%; height: 9%;
    z-index: 10;
}
/* 응모하기 */
.event-detail-img.joinRenewal .link-btn.event {
    bottom: 12%;
    width: 44%; height: 9.5%;
}
/* 추천인 코드 */
.event-detail-img.joinRenewal .recommend-btn {
    position: absolute;
    bottom: 22%; left: 50%; transform: translateX(-50%);
    width: 60%; height: 4.5%;
}
.event-detail-img.joinRenewal .noticeToggle { background-image: url('../../images/event/join_renewal/PC_JOIN_RENEWAL_03_0.png'); background-size: cover; background-position: center; height: 36px;}
.event-detail-img.joinRenewal .noticeToggle.isOn { background-image: url('../../images/event/join_renewal/PC_JOIN_RENEWAL_03_1.png'); background-size: cover; background-position: center;}
/* 딸기콩 쿠폰등록하기 */
.event-detail-img.joinRenewal .link-btn.link {
    bottom: 11.5%;
    width: 61%; height: 9%;
}
/* 회원가입 2 */
.event-detail-img.joinRenewal .link-btn.join {
    bottom: 5.7%;
    width: 61%; height: 5.5%;
}
.event-detail-img.joinRenewal .noticeToggle2 { background-image: url('../../images/event/join_renewal/PC_JOIN_RENEWAL_05_0.png'); background-size: cover; background-position: center; height: 40px;}
.event-detail-img.joinRenewal .noticeToggle2.isOn { background-image: url('../../images/event/join_renewal/PC_JOIN_RENEWAL_05_1.png'); background-size: cover; background-position: center;}

.event-detail-img.recsRenewal a:hover,
.event-detail-img.recsRenewal button:hover,
.event-detail-img.recsRenewal div:hover {background-color: transparent;}
.event-detail-img.recsRenewal .recommend-btn {
    position: absolute;
    bottom: 6%; left: 50%; transform: translateX(-50%);
    width: 62%; height: 8%;
}
.event-detail-img.recsRenewal .noticeToggle { background-image: url('../../images/event/recs_renewal/3rd/PC_RECS_RENEWAL_03_01.png'); background-size: cover; background-position: center; height: 97px;}
.event-detail-img.recsRenewal .noticeToggle.isOn { background-image: url('../../images/event/recs_renewal/3rd/PC_RECS_RENEWAL_03_00.png'); background-size: cover; background-position: center;}


/* 겨울방학 캠프 */
.event-detail-img.winterCamp a:hover,
.event-detail-img.winterCamp button:hover,
.event-detail-img.winterCamp div:hover {background-color: transparent;}
.event-detail-img.winterCamp .fade-element {
    position: relative;
    margin-top: 80px;
    opacity: 0;
    transition: margin-top 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s linear;
}
.event-detail-img.winterCamp .fade-in {
    margin-top: 0;
    opacity: 1;
}

.event-detail-img.winterCamp .txt_hot { width: 39%; height: auto; position: absolute; top: 14%; left: 7%; }
.event-detail-img.winterCamp .noticeToggle { background-image: url('../../images/event/winterEvent/PC_WINTERCAMP_05_00.png'); background-size: cover; background-position: center; height: 98px;}
.event-detail-img.winterCamp .noticeToggle.isOn { background-image: url('../../images/event/winterEvent/PC_WINTERCAMP_05_01.png'); background-size: cover; background-position: center;}
.event-detail-img.winterCamp .liveTeacher1 { width: 93.5%; height: 25.5%; position: absolute; top: 21.5%; left: 50%; transform: translateX(-50%); }
.event-detail-img.winterCamp .liveTeacher2 { width: 93.5%; height: 25.5%; position: absolute; top: 60.5%; left: 50%; transform: translateX(-50%); }
.event-detail-img.winterCamp .btn { width: 45%; height: 6%; position: absolute; left: 50%; transform: translateX(-50%);}
.event-detail-img.winterCamp .btn.classBtn1 { top: 48.5%; }
.event-detail-img.winterCamp .btn.classBtn2 { top: 87%; }
.event-detail-img.winterCamp .coffee1 {
    width: 17%; height: 10%;
    position: absolute; top: 83%; left: 10%;
    transform-origin: 50% 60%;
    animation-name: shake;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.8s;
 }
.event-detail-img.winterCamp .coffee2 {
    width: 17%; height: 10%;
    position: absolute; top: 72%; left: 72%;
    transform-origin: 60% 50%;
    animation-name: shake;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.8s;
}
.event-detail-img.winterCamp .btn_box { width: 85%; height: 4%; position: absolute; top: 47%; left: 50%; transform: translateX(-50%);}
.event-detail-img.winterCamp .btn_box .btn_coupon { width: 50%; height: 100%;}
.event-detail-img.winterCamp .btn_all1 { width: 13%; height: 2%; position: absolute; top: 8.7%; left: 6%; }
.event-detail-img.winterCamp .btn_all2 { width: 13%; height: 2%; position: absolute; top: 32.7%; left: 81%; }
.event-detail-img.winterCamp .btn_all3 { width: 13%; height: 2%; position: absolute; top: 56.8%; left: 6%; }
.event-detail-img.winterCamp .btn_all4 { width: 13%; height: 2%; position: absolute; top: 80.8%; left: 81%; }
.event-detail-img.winterCamp .card img { width: 100%; height: 100%; }
.event-detail-img.winterCamp .slide-card-items-list-type.swiper { height: 12%; position: absolute; padding: 0; width: 100%;}
.event-detail-img.winterCamp .slide-card-items-list-type.swiper.newYear { top: 12%; }
.event-detail-img.winterCamp .slide-card-items-list-type.swiper.english { top: 36%; }
.event-detail-img.winterCamp .slide-card-items-list-type.swiper.routin { top: 60%; }
.event-detail-img.winterCamp .slide-card-items-list-type.swiper.shortTerm { top: 85%; }

.event-detail-img.winterCamp .txt_me { width: 17%; height: 10%; position: absolute; top: 24%; left: 8%; animation: bubbleScale 1.3s ease-in-out infinite;}
.event-detail-img.winterCamp .txt_you { width: 17%; height: 10%; position: absolute; top: 29%; left: 76%; animation: bubbleScale 1.3s ease-in-out infinite;}

/* 겨울방학 특강 */
.event-detail-img.winterEvent .btn_box { width: 85%; height: 5%; position: absolute; top: 40%; left: 50%; transform: translateX(-50%); display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; }
.event-detail-img.winterEvent .btn_box .btn_coupon { width: 47%; height: 100%;}
.event-detail-img.winterEvent .coffee1 {
    width: 15%; height: 10%;
    position: absolute; top: 76%; left: 10%;
    transform-origin: 50% 60%;
    animation-name: shake;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.8s;
 }
.event-detail-img.winterEvent .coffee2 {
    width: 15%; height: 10%;
    position: absolute; top: 63%; left: 72%;
    transform-origin: 60% 50%;
    animation-name: shake;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-delay: 0.8s;
}


.event-detail-img.discountClass a:hover,
.event-detail-img.discountClass button:hover,
.event-detail-img.discountClass div:hover {background-color: transparent;}
.event-detail-img.discountClass .btnBox {
    width: 93%; height: 10%;
    position: absolute;
    top: 79.8%; left: 50%; transform: translateX(-50%);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
}
.event-detail-img.discountClass .btnBox a.btn {
    width: 15%; height: 100%;
    margin-top: 5px;
}

.event-detail-img.discountClass .vbtnBox {
    width: 100%; height: 80%;
    position: absolute; top: 14%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly
}

.event-detail-img-relayive {
    position: relative;
}

.event-detail-img-relayive img{
	position: relative;
}
.event-detail-img-relayive .event-detail-abs-btn button{
	position: absolute; top: 65%; left: 20%;
	width: 60%;
	height: 6%;
}

.event-detail-img.discountClass .vbtnBox a.box_btn1 {
    width: 98%; height: 30%;
}

.event-detail-img.discountClass a.box_btn2 {
    width: 98%; height: 51%;
    position: absolute; top: 32.5%; left: 50%;
    transform: translateX(-50%);
}

@media screen and (max-width: 1023px) {
	.event-slider {
		overflow: hidden;
		position: relative;
		background: url('../../images/event/PC_2.jpg');
		padding: 6% 0 6% 0;
		box-sizing: border-box;
	}
	.event-slider-box {
		width: 500vw;
		height:100%;
		display:flex;
		flex-wrap:nowrap;
		animation: bannermove 10s linear infinite;
	}

	.noticeToggle {
		width: 100%;
		height: 46px;
		background: url('../../images/event/livealUse/MO_2_off.png') no-repeat;
		background-size: cover;
	}
	.noticeToggle.isOn {
		background: url('../../images/event/livealUse/MO_2_on.png') no-repeat;
		background-size: cover;
	}

	.liveallPoint .noticeToggle {
		width: 100%;
		height: 33px;
		background: url('../../images/event/liveallPoint/MO_TAB_OPEN.png') no-repeat;
		background-size: contain;
		background-position: center;
	}
	.liveallPoint .noticeToggle.isOn {
		background: url('../../images/event/liveallPoint/MO_TAB.png') no-repeat;
		background-size: contain;
		background-position: center;
	}

    .welcomeLiveAll .noticeToggle {
        max-width: 750px;
        height: 32px;
        background: url('../../images/event/welcome/MO_WELCOME_TAB.png') no-repeat;
        cursor: pointer;
        background-size: cover;
        background-position: center;
        margin: 0 auto;
    }
    .welcomeLiveAll .noticeToggle.isOn {
        background: url('../../images/event/welcome/MO_WELCOME_TABCLOSE.png') no-repeat;
        background-size: cover;
        background-position: center;
    }

	.welcomeLiveAll .recommend-btn {
        position: absolute;
        top: 57%; left: 50%;
        transform: translate(-50%, -50%);
        width: 70%; height: 7%;
	}

    .welcomeLiveAll button.link-btn.MuiButton-root {
        font-size: 0;
        position: absolute !important;
        cursor: pointer;
        left: 50%; transform: translateX(-50%);
        width: 83%; height: 40%;
        bottom: 30%;
    }

	button.link-btn.MuiButton-root.eventJoinBtn {
		bottom: 44%;
		width: 30%;
		right: 10%;
		left: auto;
	}
	.liveallPoint .eventApplyBtn {
		bottom: 5%;
		height: 20vw;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
	.liveallPoint button.link-btn.MuiButton-root.eventJoinBtn {
		bottom: 3%;
		height: 20vw;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
	.liveallPoint button.link-btn.MuiButton-root.eventPointBtn {
		bottom: 18%;
		height: 16vw;
		width: 100%;
		right: 0;
	}
	button.link-btn.MuiButton-root.eventPointBtn,
	button.link-btn.MuiButton-root.eventReviewBtn {
		bottom: 1%;
		height: 90px;
		width: 100%;
		left: 0;
		right: auto;
	}

	.liveallPoint a.eventApplyBtn, .liveallPoint a.eventPointBtn{
		bottom: 3%;
		height: 20vw;
		width: 100%;
	}
	/* //2022-11-11 유의사항 확인하기 토글 하드 코딩 css 끝 */

	/* 2023-01-26 라이브올 사용법 */
	.liveallUse2 .LinkInWrap {max-width: 750px; margin: 0 auto;}
	.liveallUse2 .link-btn.join {
		left: 6.66%;
		top: 89%;
		width: 86.66%;
		height: 7.93%;
	}
	.liveallUse2 .recommend-btn {
		left: 6.66%;
		top: 35.76%;
		width: 86.66%;
		height: 5.87%;
	}
	.liveallUse2 .noticeToggle {
		max-width: 750px;
		height: 107px;
		margin: 0 auto;
		background-image: url('../../images/event/liveallUse2/USE_MO_TAB.png');
		background-position: center;
		background-size: contain;
	}
	.liveallUse2 .noticeToggle.isOn {
		background-image: url('../../images/event/liveallUse2/USE_MO_TAB_OPEN.png');
	}
	/* // 2023-01-26 라이브올 사용법 */

	/* 2023-02-08 셀럽특강 */
	.event-detail-img.celeb .link-btn {width: 70.26%; height: 3.26%;}/*527 98*/
	.event-detail-img.celeb .link-btn.intro {left: 14.93%; top: 83.63%;}
	.event-detail-img.celeb .link-btn.regist {left: 14.93%; top: 91.94%;}
	/* 2023-02-08 셀럽특강 */

	/* 2023-03-08 특별제휴 프로모션 */
	.event-detail-img.alliance .LinkInWrap {max-width: 750px; margin: 0 auto;}
	.event-detail-img.alliance .link-btn[class*="class"] {top: 26.61%; width: 45.33%; height: 3.7%;}
	.event-detail-img.alliance .link-btn.class1 {left: 2.66%;}
	.event-detail-img.alliance .link-btn.class2 {left: 52.13%;}
	.event-detail-img.alliance .link-btn.class3  {left: 27.33%; top: 44%;}
	.event-detail-img.alliance .link-btn.join {left: 7.86%; top: 88.72%; width: 84.4%; height: 5.2%;}
	.event-detail-img.alliance .link-btn.view {left: 7.86%; top: 60.28%; width: 84.4%; height: 3.25%;}
	/* // 2023-03-08 특별제휴 프로모션 */

	/* 2023-05-11 가정의 달 */
	.event-detail-img.familyMonth .LinkInWrap {max-width: 750px; margin: 0 auto;}
	.event-detail-img.familyMonth .hashtag {top: -60px;}
	.event-detail-img.familyMonth .coupon-btn {top: 58.44%; width: 41.86%; height: 6.24%;}
	.event-detail-img.familyMonth .coupon-btn.per30 {left: 6.26%;}
	.event-detail-img.familyMonth .coupon-btn.per20 {left: 52%;}
	.event-detail-img.familyMonth .class-btn {left: 2.66%; top: 78.78%; width: 94.66%; height: 9.54%;}
	.event-detail-img.familyMonth .event-join-btn {left: 2.66%; top: 84.59%; width: 94.66%; height: 7.39%;}
	.event-detail-img.familyMonth .noticeToggle_info {background-color: #ffffe7;}
	.event-detail-img.familyMonth .noticeToggle {max-width: 750px; height: auto; margin: 0 auto; padding-bottom: 14.26%; background-image: url('../../images/event/familyMonth/MO_04_TAB_OPEN.png');}
	.event-detail-img.familyMonth .noticeToggle.isOn {background-image: url('../../images/event/familyMonth/MO_04_TAB_CLOSE.png');}
	.modal-pop-wrap.sns-url-regist .modal-pop-contents .popContents {padding: 20px;}
	.modal-pop-wrap.sns-url-regist .txt {font-size: 16px;}
	.modal-pop-wrap.sns-url-regist .txtarea {padding: 15px; font-size: 15px;}
	/* // 2023-05-11 가정의 달 */

	/* 2023-06-22 라이브올 여름캠프 */
	.event-detail-img.summerCamp {max-width: 750px; margin: 0 auto;}
	.event-detail-img.summerCamp .hashtag {top: -56px;}

	.event-detail-img.summerCamp .scamp01 .lecturer {left: 21.06%; top: 40.76%; width: 64.53%;}
	.event-detail-img.summerCamp .scamp01 .bubble-box .bubble1 {left: 11.33%; top: 46.01%; width: 12.66%;}
	.event-detail-img.summerCamp .scamp01 .bubble-box .bubble2 {left: 5.46%; top: 55.17%; width: 12.93%;}
	.event-detail-img.summerCamp .scamp01 .bubble-box .bubble3 {left: 3.46%; top: 65.25%; width: 17.06%;}
	.event-detail-img.summerCamp .scamp01 .bubble-box .bubble4 {left: 85.6%; top: 48.05%; width: 10.93%;}
	.event-detail-img.summerCamp .scamp01 .bubble-box .bubble5 {left: 80.66%; top: 56.27%; width: 16.13%;}
	.event-detail-img.summerCamp .scamp01 .bubble-box .bubble6 {left: 83.33%; top: 67.45%; width: 12.93%;}
	/* .event-detail-img.summerCamp .scamp02 {padding: 6.66% 2.66% 8.66%;} */
    .event-detail-img.summerCamp .scamp02 {padding: 6.66% 2.66% 11%;}
	.event-detail-img.summerCamp .scamp02 .tit {width: 48.87%; margin-bottom: 6.66%;}
	.event-detail-img.summerCamp .scamp02 .balloon {margin: 0 auto 5.5%; padding: 11.54% 0 16.9%; background: url(../../images/event/summerCamp/MO_SCAMP02_balloon_top.png) center 0/100% no-repeat, url(../../images/event/summerCamp/MO_SCAMP02_balloon_btm.png) center bottom/100% no-repeat;}
	.event-detail-img.summerCamp .scamp02 .balloon .inner {padding: 0; background-image: url(../../images/event/summerCamp/MO_SCAMP02_balloon_mid.png);}
	.event-detail-img.summerCamp .scamp02 .balloon .box-tit {width: 80.14%; margin-bottom: 3.52%;}
	.event-detail-img.summerCamp .scamp02 .stamp-box .stamp {width: 15.91%; margin: 1.4%; padding-bottom: 15.91%;}
	.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(12) ~ * {display: inline-block;}
	.event-detail-img.summerCamp .scamp02 .stamp-box .stamp:nth-child(10) ~ * {display: none;}
	.event-detail-img.summerCamp .scamp02 .stamp-box.open .stamp:nth-child(10) ~ * {display: inline-block;}
	.event-detail-img.summerCamp .scamp02 .stamp-box .stamp.on::after {left: -4px; top: -4px; width: calc(100% + 8px); height: calc(100% + 8px); border-width: 8px;}
	.event-detail-img.summerCamp .scamp02 .stamp-open-btn {bottom: 10.57%; width: 14.36%;}
	/* .event-detail-img.summerCamp .scamp02 .btn {width: 53.86%; padding: 0;} */
    .event-detail-img.summerCamp .scamp02 .btn {
        width: 50%; height: 13%;
        padding: 0;
        position: absolute; left: 50%; transform: translateX(-50%);
        background: url('../../images/event/summerCamp/PC_SCAMP02_btn.png') center 0/100% no-repeat;
    }

	.event-detail-img.summerCamp .scamp03 .btn {top: 34.36%; width: 46.13%; height: 51.16%;}
	.event-detail-img.summerCamp .scamp03 .btn.b1 {left: 2.66%;}
	.event-detail-img.summerCamp .scamp03 .btn.b2 {left: 51.33%;}

	.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble1 {left: 6.66%; top: 13.84%; width: 34.93%;}
	.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble2 {left: 39.2%; top: 16.81%; width: 28.26%;}
	.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble3 {left: 62.53%; top: 14.3%; width: 28.13%;}
	.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble4 {left: 8.8%; top: 22.54%; width: 38.53%;}
	.event-detail-img.summerCamp .scamp04 .bubble-box img.bubble5 {left: 55.06%; top: 23.44%; width: 37.73%;}
	.event-detail-img.summerCamp .scamp04 .btn.b1 {left: 2.66%; top: 38.85%; width: 94.66%; height: 4.92%;}
	.event-detail-img.summerCamp .scamp04 .btn.c1,
	.event-detail-img.summerCamp .scamp04 .btn.c2,
	.event-detail-img.summerCamp .scamp04 .btn.c3,
	.event-detail-img.summerCamp .scamp04 .btn.c4 {width: 37.33%; height: 2.88%;}
	.event-detail-img.summerCamp .scamp04 .btn.c1 {left: 8.4%; top: 72.44%;}
	.event-detail-img.summerCamp .scamp04 .btn.c2 {left: 54.4%; top: 72.44%;}
	.event-detail-img.summerCamp .scamp04 .btn.c3 {left: 8.4%; top: 91.72%;}
	.event-detail-img.summerCamp .scamp04 .btn.c4 {left: 54.4%; top: 91.72%;}

	.event-detail-img.summerCamp .scamp05 .camp-slider {padding-bottom: 48.26%;}
	.event-detail-img.summerCamp .scamp05 .camp-slider .box {top: 11.325%; height: 77.348%;}
	.event-detail-img.summerCamp .scamp05 .camp-slider.slider1 {top: 21.4%;}
	.event-detail-img.summerCamp .scamp05 .camp-slider.slider1 .box {width: 381.333%;}
	.event-detail-img.summerCamp .scamp05 .camp-slider.slider2 {top: 51.83%;}
	.event-detail-img.summerCamp .scamp05 .camp-slider.slider2 .box {width: 610.133%;}
	.event-detail-img.summerCamp .scamp05 .camp-slider.slider3 {top: 82.15%;}
	.event-detail-img.summerCamp .scamp05 .camp-slider.slider3 .box {width: 533.866%;}

	.event-detail-img.summerCamp .scamp06 .lecturer {left: 9.86%; top: 9.9%; width: 85.6%;}
	.event-detail-img.summerCamp .scamp06 .btn.b1 {left: 5.6%; top: 30.96%; width: 88.93%; height: 3.89%;}
	.event-detail-img.summerCamp .scamp06 .btn.b2,
	.event-detail-img.summerCamp .scamp06 .btn.b3,
	.event-detail-img.summerCamp .scamp06 .btn.b4 {top: 41.07%; width: 24.93%; height: 1.67%;}
	.event-detail-img.summerCamp .scamp06 .btn.b2 {left: 6.53%;}
	.event-detail-img.summerCamp .scamp06 .btn.b3 {left: 37.6%;}
	.event-detail-img.summerCamp .scamp06 .btn.b4 {left: 68.66%;}
	.event-detail-img.summerCamp .scamp06 .btn.b5,
	.event-detail-img.summerCamp .scamp06 .btn.b6,
	.event-detail-img.summerCamp .scamp06 .btn.b7 {left: 64%; width: 28.4%; height: 1.91%;}
	.event-detail-img.summerCamp .scamp06 .btn.b5 {top: 60.12%;}
	.event-detail-img.summerCamp .scamp06 .btn.b6 {top: 80.57%;}
	.event-detail-img.summerCamp .scamp06 .btn.b7 {top: 91.53%;}

	.event-detail-img.summerCamp .scamp07 {padding-bottom: 13.33%;}
	.event-detail-img.summerCamp .scamp07 .noticeToggle {height: auto; padding-bottom: 6.66%; background-image: url('../../images/event/summerCamp/MO_SCAMP07_tab_open.png');}
	.event-detail-img.summerCamp .scamp07 .noticeToggle.isOn {background-image: url('../../images/event/summerCamp/MO_SCAMP07_tab_close.png');}

	/* 2023-08-24 백투더스쿨 */
	/*background-color: rgba(172, 36, 85, 0.66);*/
	.event-detail-img.backToTheSchool {max-width: 1260px; margin: 0 auto 25px;}
	.event-detail-img.backToTheSchool .btn {position: absolute;}
	/* btt-school1 */
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap {position: absolute;top: 34%; left: 8%;width: 85%;height: 38%;}
	.event-detail-img.backToTheSchool .btt-school1 .btn.check {width: 41%;height: 8.4%; top: 74%; left: 29.6%;}

	/* btt-school2 */
	.event-detail-img.backToTheSchool .btt-school2 .btn.download-1 {width: 38%;height: 5%; top: 37.9%; left: 10%;}
	.event-detail-img.backToTheSchool .btt-school2 .btn.download-2 {width: 38%;height: 5.1%;top: 37.9%; left: 52%;}
	.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-1 {width: 38%;height: 17.5%;top: 52.8%; left: 10%;}
	.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-2 {width: 38%;height: 17.5%;top: 52.8%; left: 52.3%;}
	.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-3 {width: 38%;height: 17.5%;top: 72%; left: 10%;}
	.event-detail-img.backToTheSchool .btt-school2 .btn.recommend-4 {width: 38%;height: 17.5%;top: 72%; left: 52.3%;}
	/* btt-school3 */
	.event-detail-img.backToTheSchool .btt-school3 .btn.experience {width: 53%;height: 9.7%;top: 85%; left: 23.5%;}
	/* btt-school4*/
	.event-detail-img.backToTheSchool .btt-school4 .btn.gift-1 {width: 35%;height: 13%;top: 47.5%; left: 13%;}
	.event-detail-img.backToTheSchool .btt-school4 .btn.gift-2 {width: 35%;height: 13%;top: 47.5%; left: 51.65%;}
	/* 유의사항 */
	.event-detail-img.backToTheSchool .btt-school-notice .noticeToggle {
		background-image: url('../../images/event/backToTheSchool/MO_tab_off.png') ;
		background-size: cover;
		background-position: center;
		height:25px;
		margin: -1px auto;
		max-width: 750px;
	}
	.event-detail-img.backToTheSchool .btt-school-notice .noticeToggle.isOn {
		background-image: url('../../images/event/backToTheSchool/MO_tab_on.png');
		background-size: cover;
	}
	.event-detail-img.backToTheSchool .btt-school-notice .noticeToggle_info {
		display:none; background-color: white;
	}
	.event-detail-img.backToTheSchool .btt-school-notice .noticeToggle_info.isOn{display: block;}
	/* 출석체크 요소 제어 */
	/*background-color: rgba(172, 36, 85, 0.66);*/
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp {display: inline-block; position: relative; width: 15.19%; margin: 1% 2.4%; padding-bottom: 17.19%; background: center/100% no-repeat; vertical-align: top;}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(1) {background-image: url('../../images/event/backToTheSchool/stamp/Day1_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(2) {background-image: url('../../images/event/backToTheSchool/stamp/Day2_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(3) {background-image: url('../../images/event/backToTheSchool/stamp/Day3_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(4) {background-image: url('../../images/event/backToTheSchool/stamp/Day4_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(5) {background-image: url('../../images/event/backToTheSchool/stamp/Day5_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(6) {background-image: url('../../images/event/backToTheSchool/stamp/Day6_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(7) {background-image: url('../../images/event/backToTheSchool/stamp/Day7_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(8) {background-image: url('../../images/event/backToTheSchool/stamp/Day8_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(9) {background-image: url('../../images/event/backToTheSchool/stamp/Day9_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(10) {background-image: url('../../images/event/backToTheSchool/stamp/Day10_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(11) {background-image: url('../../images/event/backToTheSchool/stamp/Day11_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(12) {background-image: url('../../images/event/backToTheSchool/stamp/Day12_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(13) {background-image: url('../../images/event/backToTheSchool/stamp/Day13_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(14) {background-image: url('../../images/event/backToTheSchool/stamp/Day14_OFF.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(15) {background-image: url('../../images/event/backToTheSchool/stamp/올_COIN.png');}

	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(1).on {background-image: url('../../images/event/backToTheSchool/stamp/Day1_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(2).on {background-image: url('../../images/event/backToTheSchool/stamp/Day2_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(3).on {background-image: url('../../images/event/backToTheSchool/stamp/Day3_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(4).on {background-image: url('../../images/event/backToTheSchool/stamp/Day4_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(5).on {background-image: url('../../images/event/backToTheSchool/stamp/Day5_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(6).on {background-image: url('../../images/event/backToTheSchool/stamp/Day6_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(7).on {background-image: url('../../images/event/backToTheSchool/stamp/Day7_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(8).on {background-image: url('../../images/event/backToTheSchool/stamp/Day8_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(9).on {background-image: url('../../images/event/backToTheSchool/stamp/Day9_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(10).on {background-image: url('../../images/event/backToTheSchool/stamp/Day10_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(11).on {background-image: url('../../images/event/backToTheSchool/stamp/Day11_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(12).on {background-image: url('../../images/event/backToTheSchool/stamp/Day12_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(13).on {background-image: url('../../images/event/backToTheSchool/stamp/Day13_ON.png');}
	.event-detail-img.backToTheSchool .btt-school1 .check-stamp-wrap .stamp:nth-child(14).on {background-image: url('../../images/event/backToTheSchool/stamp/Day14_ON.png');}
}


@media screen and (max-width: 767px) {
	.liveallUse2 .noticeToggle {
		height: auto;
		padding-bottom: 14.26%;
	}

    .welcomeLiveAll .noticeToggle {
		height: auto;
		padding-bottom: 5%;
	}

    .event-detail-img.festival .link-btn { width: 95%; height: 5.5%; }
    .event-detail-img.festival .event-slider { padding: 5% 0;}
    .event-detail-img.festival .event-slider-box { width: 540%; }
    .event-detail-img.festival .noticeToggle { background-image: url('../../images/event/festival/MO_festival_04_00.png'); background-size: cover; background-position: center; height: 2.1vh;}
    .event-detail-img.festival .noticeToggle.isOn { background-image: url('../../images/event/festival/MO_festival_04_02.png');}
    .event-detail-img.festival .btn_box { width: 85%; height: 10%; }

    .event-detail-img.joinRenewal a:hover,
    .event-detail-img.joinRenewal button:hover {background-color: transparent;}
    .event-detail-img.joinRenewal .link-btn {
        bottom: 7.5%;
        width: 85%; height: 10%;
    }
    .event-detail-img.joinRenewal .link-btn.event {
        bottom: 12%;
        width: 60%; height: 10.5%;
    }
    .event-detail-img.joinRenewal .recommend-btn {
        bottom: 29%;
        width: 82%; height: 4.5%;
    }
    .event-detail-img.joinRenewal .noticeToggle { background-image: url('../../images/event/join_renewal/MO_JOIN_RENEWAL_03_0.png'); background-size: cover; background-position: center; height: 26px;}
    .event-detail-img.joinRenewal .noticeToggle.isOn { background-image: url('../../images/event/join_renewal/MO_JOIN_RENEWAL_03_1.png'); background-size: cover; background-position: center;}
    .event-detail-img.joinRenewal .link-btn.link {
        bottom: 13.5%;
        width: 66%; height: 9.5%;
    }
    .event-detail-img.joinRenewal .link-btn.join {
        bottom: 6.5%;
        width: 66%; height: 6.5%;
    }
    .event-detail-img.joinRenewal .noticeToggle2 { background-image: url('../../images/event/join_renewal/MO_JOIN_RENEWAL_05_0.png'); background-size: cover; background-position: center; height: 28px;}
    .event-detail-img.joinRenewal .noticeToggle2.isOn { background-image: url('../../images/event/join_renewal/MO_JOIN_RENEWAL_05_1.png'); background-size: cover; background-position: center;}

    .event-detail-img.recsRenewal .recommend-btn {
        bottom: 3.5%;
        width: 72.5%; height: 9.5%;
    }
    .event-detail-img.recsRenewal .noticeToggle { background-image: url('../../images/event/recs_renewal/3rd/MO_RECS_RENEWAL_03_01.png'); background-size: cover; background-position: center; height: 63px;}
    .event-detail-img.recsRenewal .noticeToggle.isOn { background-image: url('../../images/event/recs_renewal/3rd/MO_RECS_RENEWAL_03_00.png'); background-size: cover; background-position: center;}

    .event-detail-img.winterCamp .txt_hot {
        width: 41%; height: auto;
        left: 5%;
    }
    .event-detail-img.winterCamp .slide-card-items-list-type.swiper { height: 14%; }
    .event-detail-img.winterCamp .slide-card-items-list-type.swiper.newYear { top: 11%; }
    .event-detail-img.winterCamp .slide-card-items-list-type.swiper.english { top: 34.5%; }
    .event-detail-img.winterCamp .slide-card-items-list-type.swiper.routin { top: 59.5%; }
    .event-detail-img.winterCamp .slide-card-items-list-type.swiper.shortTerm { top: 84%; }
    .event-detail-img.winterCamp .noticeToggle { background-image: url('../../images/event/winterEvent/MO_WINTERCAMP_05_00.png'); background-size: cover; background-position: center; height: 62px;}
    .event-detail-img.winterCamp .noticeToggle.isOn { background-image: url('../../images/event/winterEvent/MO_WINTERCAMP_05_01.png'); background-size: cover; background-position: center;}

    .event-detail-img.winterEvent .noticeToggle {background-image: url('../../images/event/winterEvent/MO_WINTERCAMP_05_00.png'); background-size: cover; background-position: center; height: 62px;}
    .event-detail-img.winterEvent .noticeToggle.isOn { background-image: url('../../images/event/winterEvent/MO_WINTERCAMP_05_01.png'); background-size: cover; background-position: center;}

    .event-detail-img.discountClass .btnBox {
        width: 70%; height: 22%;
        top: 67.5%;

        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    .event-detail-img.discountClass .btnBox a.btn{
        width: 31%; height: 48%;
        margin-top: 9px;
     }

     .event-detail-img.discountClass .vbtnBox { top: 13%; }
     .event-detail-img.discountClass a.box_btn2 { top: 30%; }
}

@media screen and (max-width: 480px) {
	/* 2023-06-22 라이브올 여름캠프 */
	.event-detail-img.summerCamp .scamp02 .stamp-box .stamp.on::after {left: -3px; top: -3px; width: calc(100% + 6px); height: calc(100% + 6px); border-width: 6px;}
}

.bottomTabBox{
	width: 100%;
	position: fixed;
	z-index: 4;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.bottomTab-title{
	position: relative;
	width: 100vw;
	height: 90px;
	border: none;
	box-sizing: border-box;
	font-weight: 700;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	letter-spacing: -0.5px;
}
@media screen and (max-width: 767px) {
	.bottomTab-title{
		height: 60px;
	}
}

.colorType96{
	background-color: #ff6969;
	color:  #ffffff;
}
.colorType95{
	background-color: #f5f588;
	color:  #000000;
}
.colorType94{
	background-color: #ffd43f;
	color:  #0d5667;
}

.bottomTab-title:hover{
	cursor: pointer;
}

/* 2024-03-04 라이브올 브랜드 대전 */
.event-detail-img .imgBox{
	position: relative;
}

.event-detail-img .imgBox .flexBox{
	padding: 0 20%;
	display: flex;
  justify-content: space-between;
}

.event-detail-img .imgBox .btn-type1{
	position: absolute;
	top: 44.5%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 35%;
	height: 4%;
}

.event-detail-img .imgBox .btn-type2{
	position: absolute;
	top: 54%;
	transform: translate(-50%, -50%);
	width: 23%;
	height: 4%;
}

.event-detail-img .imgBox .btn-type3{
	position: absolute;
	top: 54%;
	transform: translate(-50%, -50%);
	width: 23%;
	height: 4%;
}

.event-detail-img .imgBox .btn-type4{
	position: absolute;
	top: 54%;
	transform: translate(-50%, -50%);
	width: 23%;
	height: 4%;
}
.event-detail-img .imgBox .btn-type5{
	position: absolute;
	top: 67%;
	left: 35%;
	transform: translate(-50%, -50%);
	width: 23%;
	height: 2.5%;
}
.event-detail-img .imgBox .btn-type6{
	position: absolute;
	top: 81%;
	left: 35%;
	transform: translate(-50%, -50%);
	width: 23%;
	height: 2.5%;
}
.event-detail-img .imgBox .btn-type7{
	position: absolute;
	top: 94%;
	left: 35%;
	transform: translate(-50%, -50%);
	width: 23%;
	height: 2.5%;
}

@media screen and (min-width: 825px) {
	.event-detail-img .imgBox .flexBox{
		padding: 0 30%;
	}
}

@media screen and (max-width: 1064px) {
	.event-detail-img .imgBox .btn-type5{
		left: 30%;
		width: 40%;
	}
	.event-detail-img .imgBox .btn-type6{
		left: 30%;
		width: 40%;
	}
	.event-detail-img .imgBox .btn-type7{
		left: 30%;
		width: 40%;
	}
}
