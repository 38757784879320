.sub-wrap.search-empty .list-empty {
    .list-empty-caution {
        margin: 0 auto;
        width: 100%;
    }
    .title-section {
        &:before {
            background: url('../../images/common/caution-icon.png') no-repeat;
            background-size: 100% 100%;
            content: "";
            display: inline-block;
            // width: 72px; height: 72px;
            width: 36px; height: 36px;
            margin-bottom: 20px;
        }
        .title {
            color: #1c1c1c;
            // font-size: 42px;
            font-size: 2rem;
            font-family: 'NotoSansBold';
            letter-spacing: -0.5px;
        }
    }
    .defalut-section li {
        color: #1c1c1c;
        // font-size: 20px;
        font-size: 1rem;
        letter-spacing: -0.5px;
        line-height: 1.5;
        font-family: 'NotoSansRegular';
    }
}

.table-empty {
    // border-bottom: 2px solid #eaeaea;
    .title-section {
        &::before {
            content: '';
            background: url('../../images/common/caution-icon.png') no-repeat;
            background-size: 100% 100%;
            width: 33px;
            height: 33px;
            display: inline-block;
            margin-bottom: 11px;
        }
        .title {
            color: #666;
            font-size: 14px;
            font-family: 'NotoSansRegular';
            letter-spacing: -0.5px;
        }
    }
}

@media screen and (max-width: 767px) {
    .sub-wrap.search-empty {
        width: 80%;
        margin: auto;
        .list-empty {
            .title-section {            
                &:before {
                    width: 8.8vw; height: 8.8vw;
                    margin-bottom: 20px;
                }
                .title {
                    font-size: 5.4vw;
                }
            }
            .defalut-section li {
                font-size: 3.3vw;
            }
        }
    }
}