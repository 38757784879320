.main-popular .itemContainer,
.itemContainer {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.sub-wrap .itemContainer .card-item-root button img.MuiCardMedia-img,
.main-discount .itemContainer .card-item-root button img.MuiCardMedia-img,
.popular-item-box img.MuiCardMedia-img {
  width: 100%;
  height: auto;
}

.sub-wrap.best .itemContainer .card-item-root button img.MuiCardMedia-img {
  width: 100%;
  height: 274px;
}

/* 2022-08-01 접수 대기 상태일때 클래스 sold-out 추가 */
/* .sold-out button.MuiButtonBase-root::before {
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.4;
    border: solid 1px #707070;
    background-color: #000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.sold-out button.MuiButtonBase-root::after {
    content: "";
    border-radius: none;
    background-color: #000;
    
    border-radius: 0;
    width: 95px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    font-size: 18px;
    font-family: "NotoSansBold";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.sold-out button.MuiButtonBase-root .commingSoon-txt {
    font-size: 18px;
    line-height: 1.3;
    font-family: "NotoSansBold";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    z-index: 4;
    right: 10px;
    top: 12px;
} */

/* 2022-08-01 접수 마감 상태일때 클래스 sold-out-closed */
/* .sold-out-closed button.MuiButtonBase-root::before {
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: #000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.sold-out-closed button.MuiButtonBase-root::after {
    content: "마감";
    border-radius: 0;
    background-color: #000;
    width: 95px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    font-size: 18px;
    font-family: "NotoSansBold";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}  */

.itemContainer .card-item-root,
.popular-item-root {
  width: 23.6%;
  border: solid 1px #e0e0e0;
  box-shadow: none;
  border-radius: 0px;
  position: relative;
  overflow: visible;
  margin: 1.5% 0.8%;
}

.detailTabBox_wrap .itemContainer .card-item-root {
  margin: 2% 0.8%;
}

.itemContainer .card-item-root:nth-of-type(4n),
.popular-item-root:nth-of-type(4n) {
  margin-right: 0px;
}

@media screen and (min-width: 1023px) and (max-width: 1245px) {
  .itemContainer .card-item-root,
  .popular-item-root {
    width: 23.3%;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1045px) {
  .itemContainer .card-item-root:nth-of-type(4n),
  .popular-item-root:nth-of-type(4n) {
    margin-right: 9px;
  }
}

/* best 랭킹 */
.raking-label {
  width: 90px;
  height: 54px;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}
.raking-label p {
  color: #fff;
  font-size: 30px;
  font-family: "NotoSansBlack";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.raking-label::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(100% 0%, 88% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  clip-path: polygon(100% 0%, 88% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  background-color: #0e2b49;
}

/* 클래스 썸네일 이미지 */
.itemContainer .card-content-wrap.MuiCardContent-root {
  background: #fffbf4;
  height: 183px;
}

/* 정보 텍스트 */
.content-top {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  cursor: pointer;
}

.content-top + h3 {
  font-size: 1rem;
  width: 83%;
  height: 2.4em;
  font-family: "NotoSansBold";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1c1c1c;
  text-align: left;
  cursor: pointer;
  margin-bottom: 15px;
}
.content-top .favorite-count-icon img {
  display: block;
  margin-bottom: 2px;
  width: 32px;
  height: 28px;
}
.content-top .favorite-count-icon .num {
  font-family: "NotoSansMedium";
  font-size: 12px;
  color: #666;
}

.popular-item-root .content-bottom {
  justify-content: space-between;
}

.main-discount-container {
  margin: 0 auto;
  display: flex;
  position: relative;
  padding-bottom: 90px;
  z-index: 1;
}

@media screen and (max-width: 1023px) {
  .sub-wrap.best .itemContainer .card-item-root button img.MuiCardMedia-img {
    width: 100%;
    height: 100%;
  }

  .sub-wrap.best .content-bottom .info-teacher .teacher_name p,
  .sub-wrap.best .content-bottom .info-price .price,
  .sub-wrap.best .itemContainer.moContainer .content-top h3 {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .sub-wrap.best
    .itemContainer.moContainer
    .content-bottom
    .info-teacher
    .teacher-img {
    width: 40px;
    height: 40px;
    border: 2.5px solid #0e2b49;
  }

  .sub-wrap.best .content-bottom .info-price .discount {
    left: -20%;
  }

  .itemContainer .card-item-root::before {
    top: 8px;
    left: 8px;
  }
  .content-top {
    align-items: center;
  }
  .content-top + h3 {
    font-size: 1rem;
    width: 83%;
    margin-bottom: 12px;
  }

  .content-top.favorite-count-icon {
    display: flex;
    align-items: center;
  }

  .content-top .favorite-count-icon img {
    width: 26px;
    height: 23px;
  }
  .content-bottom .info-teacher .teacher-img {
    width: 32px;
    height: 32px;
    border: 1px solid #0e2b49;
  }

  .content-bottom .info-teacher .teacher_name p.teacher-rate {
    font-size: 12px;
  }
  .content-bottom .info-teacher .teacher_name p,
  .content-bottom .info-price .discount-price span,
  .content-bottom .info-price .discount {
    font-size: 13px;
  }
  .content-bottom .info-price .discount-price,
  .content-bottom .info-price .price {
    font-size: 15px;
  }
  /* 
		기존 클래스 리스트 itemContainer 에 moContainer 추가
		모바일 가로형 클래스 카드 리스트 css 시작 
	
	*/

  .itemContainer.moContainer .card-item-root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .itemContainer.moContainer .content-top {
    flex-wrap: wrap;
  }

  .itemContainer.moContainer .content-top h3 {
    width: 100%;
    margin-bottom: 20px;
    height: 2.4em;
    font-size: 18px;
    font-family: "NotoSansBold";
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1c1c1c;
    text-align: left;
    cursor: pointer;
  }

  .itemContainer.moContainer .card-content-wrap {
    width: 100%;
    padding: 14px;
  }

  .itemContainer.moContainer .card-content-wrap.MuiCardContent-root {
    height: 183px;
  }

  .itemContainer.moContainer .content-bottom .info-teacher .teacher-img {
    width: 32px;
    height: 32px;
  }
  /* 
	
		모바일 가로형 클래스 카드 리스트 css 끝 
	
	*/

  /* 2022-07-27 모바일 클래스 리스트 soldout 일때 클래스 sold-out 추가 */

  .sold-out button.MuiButtonBase-root::before {
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.4;
    border: none;
    background-color: #020202;
    left: 0;
    top: 0;
    transform: none;
    z-index: 1;
    color: #fff;
    font-family: "NotoSansBold";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
  }
  .sold-out button.MuiButtonBase-root::after {
    content: "";
    border-radius: none;
    background-color: #000;
    width: 80px;
    height: 36px;
  }
  .sold-out button.MuiButtonBase-root .commingSoon-txt {
    font-size: 16px;
    line-height: 1;
    font-family: "NotoSansBold";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    z-index: 4;
    right: 8px;
    top: 9px;
  }
  .sold-out-closed button.MuiButtonBase-root::after {
    content: "마감";
    width: 80px;
    height: 36px;
    border: none;
    background-color: #020202;
    right: 0;
    top: 0;
    transform: none;
    z-index: 1;
    color: #fff;
    font-family: "NotoSansBold";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    border-radius: initial;
  }
  .moContainer .sold-out-closed button.MuiButtonBase-root::after {
    content: "마감";
    width: 80px;
    height: 36px;
    opacity: 1;
    border: none;
    background-color: #020202;
    right: 0;
    top: 0;
    transform: none;
    z-index: 1;
    color: #fff;
    font-family: "NotoSansBold";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    border-radius: initial;
  }
  .sectionBg .itemContainer img.MuiCardMedia-root {
    width: 100%;
    height: 100%;
  }
}

/* 2023-06-26 PAD */
@media screen and (min-width: 750px) and (max-width: 1022px) {
  .itemContainer .card-content-wrap.MuiCardContent-root {
    height: 9rem;
  }
  .moContainer .content-top h3 {
    font-size: 16px;
  }
  .itemContainer .swiper-wrapper .card-item-root,
  .main-popular .itemContainer {
    margin-bottom: 40px;
    margin-right: 0;
    width: 100%;
  }
  .itemContainer .swiper-wrapper .card-item-root .content-top + h3 {
    font-size: 18px;
    margin-bottom: 12px;
    width: 83%;
  }
  .itemContainer
    .swiper-wrapper
    .card-item-root
    .content-top
    .favorite-count-icon
    img {
    height: 23px;
    width: 26px;
    display: block;
    margin-bottom: 2px;
    margin-right: 0;
  }
  .itemContainer .swiper-wrapper .content-bottom .info-price .base-price {
    font-size: 14px;
  }
  .itemContainer .swiper-wrapper .content-bottom .info-price .price {
    font-size: 20px;
  }
  .itemContainer .swiper-wrapper .content-bottom .info-price {
    min-width: 28%;
  }

  .detailTabBox_wrap {
    padding: 0 !important;
  }
  .sub-wrap.event.eventDetail .detailTabList.fixed {
    width: 94%;
    top: 55px;
    padding: 0;
    margin-left: 29px !important;
  }

  .sub-wrap.event.eventDetail .detailTabList li {
    font-size: 16px;
  }

  .detailTabBox_wrap .detailTabBox .itemContainer .card-item-root {
    margin: 1% 0.65%;
  }

  .itemContainer .card-item-root {
    width: 32%;
    margin: 1% 0.65%;
  }
  .card-content-wrap.MuiCardContent-root {
    padding: 10px;
  }

  .content-top .favorite-count-icon img {
    display: inline-block;
    margin-right: 5px;
    width: 20px;
    height: auto;
  }
  .content-bottom {
    display: flex;
  }

  .content-bottom .info-price {
    min-width: 28%;
  }

  .content-bottom .info-price .base-price {
    font-size: 11px;
  }

  .content-bottom .info-price .price {
    font-size: 15px;
  }

  .swiper-wrapper .popular-item-root .content-top {
    align-items: center;
    margin: 0;
  }

  .swiper-wrapper .popular-item-root .content-top + h3 {
    font-size: 18px;
    margin-bottom: 12px;
    width: 83%;
  }

  .swiper-wrapper .popular-item-root .content-bottom {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }

  .swiper-wrapper
    .popular-item-root
    .content-bottom
    .info-teacher
    .teacher-img {
    border: 2px solid #0e2b49;
    height: 40px;
    width: 40px;
  }

  .swiper-wrapper
    .popular-item-root
    .content-bottom
    .info-teacher
    .teacher_name
    p {
    font-size: 14px;
  }

  .swiper-wrapper .popular-item-root .content-bottom .info-price .price,
  .swiper-wrapper .popular-item-root .content-bottom .info-price .price > span {
    font-size: 20px;
  }

  .itemContainer .swiper-wrapper .card-item-root button img.MuiCardMedia-img,
  .swiper-wrapper .popular-item-root img.MuiCardMedia-img,
  .swiper-wrapper .card-item-root img.MuiCardMedia-img {
    height: 100%;
  }
}

/* 2023-06-26 MO */
@media screen and (min-width: 401px) and (max-width: 749px) {
  .sub-wrap.event.eventDetail .classInfo {
    padding: 0;
  }
  .main-discount-container .swiper-wrapper .card-item-root {
    margin-bottom: 40px;
    margin-right: 0;
    width: 100%;
  }

  .sub-wrap.event.eventDetail .detailTabBox,
  .sub-wrap.event.eventDetail .detailTabList + .detailTabBox_wrap .detailTabBox,
  .sub-wrap.event.eventDetail .detailTabBox_wrap {
    padding: 0 !important;
  }

  .sub-wrap.event.eventDetail .detailTabList.fixed {
    width: 95%;
    padding: 0;
    top: 55px;
    margin-left: 29px !important;
  }

  /* .class-detail-container .middle .tapWrap .detailTabBox_Container .detailTabList li, 
    .sub-wrap.event.eventDetail .detailTabList li {
        font: 14px/30px "NotoSansMedium";
    } */

  .detailTabBox_Container h1 {
    font-size: 1.4rem !important;
  }

  .sub-wrap.event.eventDetail .itemContainer .card-item-root {
    width: 47%;
    margin: 2% 1.5%;
  }

  .sub-wrap.event.eventDetail .card-content-wrap.MuiCardContent-root {
    padding: 10px;
  }

  .sub-wrap.event.eventDetail .itemContainer .card-item-root .content-top {
    margin-bottom: 15px;
  }

  .sub-wrap.event.eventDetail
    .itemContainer
    .card-item-root
    .content-top
    .tag-list
    li::after {
    right: 0px;
  }
  .sub-wrap.event.eventDetail
    .itemContainer
    .card-item-root
    .content-top
    .tag-list
    li {
    padding: 0 5px;
  }

  .sub-wrap.event.eventDetail .itemContainer .content-top + h3 {
    font-size: 1rem;
  }

  .sub-wrap.event.eventDetail .itemContainer .content-bottom {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
  }

  .sub-wrap.event.eventDetail
    .itemContainer
    .card-item-root
    .content-top
    .favorite-count-icon
    img {
    display: inline-block;
    margin-right: 5px;
    width: 25px;
    height: auto;
  }
  .content-bottom .info-teacher .teacher-img {
    width: 46px;
    height: 46px;
    border: 2px solid #0e2b49;
  }

  .sub-wrap.event.eventDetail .itemContainer .card-item-root .teacher_name p {
    font-size: 15px;
    width: 100%;
  }

  .sub-wrap.event.eventDetail
    .itemContainer
    .card-item-root
    .info-price
    .price {
    font-size: 16px;
  }

  .swiper-wrapper .popular-item-root .content-top {
    align-items: center;
    margin: 0;
  }

  .swiper-wrapper .popular-item-root .content-top + h3 {
    font-size: 18px;
    margin-bottom: 12px;
    width: 83%;
  }

  .swiper-wrapper .popular-item-root .content-bottom {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }

  .swiper-wrapper
    .popular-item-root
    .content-bottom
    .info-teacher
    .teacher-img {
    border: 2px solid #0e2b49;
    height: 40px;
    width: 40px;
  }

  .swiper-wrapper
    .popular-item-root
    .content-bottom
    .info-teacher
    .teacher_name
    p {
    font-size: 14px;
  }

  .swiper-wrapper .popular-item-root .content-bottom .info-price .price {
    font-size: 20px;
  }

  .swiper-wrapper .popular-item-root .content-bottom .info-price .price > span {
    font-size: 20px;
  }

  .itemContainer .swiper-wrapper .card-item-root button img.MuiCardMedia-img {
    height: 100%;
  }

  .swiper-wrapper .popular-item-root img.MuiCardMedia-img,
  .swiper-wrapper .card-item-root img.MuiCardMedia-img {
    height: 100%;
  }

  .itemContainer .swiper-wrapper .card-item-root button img.MuiCardMedia-img {
    height: 100%;
  }

  .itemContainer .swiper-wrapper .card-item-root .content-bottom {
    justify-content: space-between;
  }
}

@media screen and (max-width: 400px) {
  .sub-wrap.event.eventDetail .detailTabList.fixed {
    width: 95%;
    padding: 0;
    top: 55px;
    margin-left: 10px !important;
  }

  .itemContainer.moContainer .content-top h3 {
    margin: 10px 0;
    height: 3.4em;
    font-size: 16px;
  }

  .sub-wrap.event.eventDetail .itemContainer .card-item-root .teacher_name p {
    width: 100%;
  }

  /* .sub-wrap.event.eventDetail {
        padding: 0 10px !important;
    } */
  .sub-wrap.event.eventDetail .classInfo {
    padding-bottom: 0;
  }

  .sub-wrap.event.eventDetail .detailTabBox_Container .detailTabList {
    padding: 0;
  }

  .sub-wrap.event.eventDetail .detailTabBox_Container .detailTabList.fixed {
    width: 94%;
    top: 56px;
    padding: 0;
  }

  .sub-wrap.event.eventDetail .detailTabBox_Container .detailTabList li {
    font-size: 1.2rem;
    padding: 5%;
  }

  .sub-wrap.event.eventDetail
    .detailTabList
    + .detailTabBox_wrap
    .detailTabBox {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .sub-wrap.event.eventDetail .detailTabBox_wrap {
    padding: 0;
  }

  .sub-wrap.event.eventDetail
    .detailTabBox_wrap
    .itemContainer
    .card-item-root {
    margin: 5% 0;
  }

  .sub-wrap.event.eventDetail .itemContainer {
    width: 100%;
  }
  .sub-wrap.event.eventDetail .itemContainer .card-item-root {
    width: 100%;
  }

  .itemContainer .swiper-wrapper .card-item-root {
    width: 100%;
  }
  .itemContainer .swiper-wrapper .card-item-root button img.MuiCardMedia-img {
    height: 100%;
  }

  .detailTabBox_wrap
    .itemContainer
    .card-item-root
    button
    img.MuiCardMedia-img {
    width: 100%;
    /* height: 21rem; */
    height: auto;
  }

  .itemContainer .swiper-wrapper .card-item-root .info-price .discount {
    left: -28px;
  }
}
