@import "../components/tab.scss";
@import "../cardComponent.scss";

.sub-wrap.best,
.sub-wrap.together {
  .breadcrumb {
    max-width: 1260px;
    margin: 0 auto;
    font-size: 0;
    text-align: left;
    padding: 15px 20px;

    .depth {
      display: inline-block;
      position: relative;
      margin-right: 24px;
      color: #666;
      font-size: 14px;
      line-height: 24px;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -20px;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        background: url("../../images/common/breadcrumb-arr@2x.png") center/16px
          no-repeat;
      }
      &:last-child {
        margin-right: 0;
        color: #1c1c1c;
        font-family: "NotoSansMedium";
        &::after {
          display: none;
        }
      }
    }
  }
  .list-sort-slt-area {
    display: block;
    max-width: 1260px;
    margin: 0 auto;
    text-align: right;
    padding: 0 20px 40px 20px;

    .each {
      display: inline-block;
      position: relative;
      margin-right: 32px;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: -16px;
        width: 1px;
        height: 16px;
        margin-top: -8px;
        background-color: #e0e0e0;
      }

      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      .comboBox-type1 {
        border: 0;
        .MuiSelect-select {
          font-family: "NotoSansMedium";
          font-size: 16px;
        }
        .MuiOutlinedInput-notchedOutline {
          border: 0;
          &::after {
            content: "";
            display: block;
            position: absolute;
            right: 8px;
            top: 50%;
            width: 16px;
            height: 16px;
            margin-top: -8px;
            background: url("../../images/common/dropdown-arr@2x.png")
              center/16px no-repeat;
          }
        }

        .MuiSelect-select[aria-expanded="true"]
          ~ .MuiOutlinedInput-notchedOutline::after {
          transform: rotate(180deg);
        }
        .MuiSvgIcon-root {
          display: none;
        }
      }
    }
  }

  article {
    display: flex;
    max-width: 1260px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
}

.sub-wrap.together > article {
  padding-top: 80px;
}

@media screen and (max-width: 767px) {
  .sub-wrap.best,
  .sub-wrap.together {
    .list-sort-slt-area {
      padding: 0 8px 20px 10px;

      .each {
        margin-right: 8px;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: -8px;
          width: 1px;
          height: 16px;
          margin-top: -8px;
          background-color: #e0e0e0;
        }

        .comboBox-type1 {
          width: 95px;
          .MuiSelect-select {
            font-size: 12px;
          }
        }
      }
    }
  }

  .sub-wrap.together > article {
    padding-top: 25px;
  }
  .together-img {
    width: 100vw;
  }
}
