.detailTabBox .classInquiry_title {
	font: 24px/2.29 'NotoSansBold';
	letter-spacing: -0.6px;
	color: #222;
	display: flex;
	justify-content: space-between;
}
.classInquiry_img {
	background-color: black !important;
}

.qa-btn.MuiButton-root {
    border-radius: 30px;
	padding: 0;
	width: 130px;
    height: 36px;
	font-family: "NotoSansBold";
    font-size: 14px;
	border: solid 1px #1c1c1c;
    background-color: #b0d8fa;
    color: #1c1c1c;
}

.qa-btn.MuiButton-root img {width: 100%;}

@media screen and (max-width:1023px) {
	.detailTabBox .classInquiry_title{
		font: 16px/1.8 'NotoSansBold';
		padding-bottom: 10px;
	}
	.detailTabBox .classInquiry_title + button.MuiButton-root {width: 110px;height: 28px;}
	.detailTabBox .classInquiry_title + button.MuiButton-root img {width: 100%;height: 100%;}
	.classInquiry_list_wrap {
		border-top: 1px solid #afb4b2;
		font: 16px/1 'NotoSansMedium';
	}

	.classInquiry_question {
		height: auto;
		flex-direction: column;
		padding-right: 0;
	}
	.inquiry-question-top {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 20px 0 14px;
	}
	.inquiry-question-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
		padding-bottom: 25px;
	}
	.classInquiry_list_wrap .answerState,
	.classInquiry_list_wrap .name,
	.classInquiry_list_wrap .date {width: auto;font-size: 12px;}

	.classInquiry_list_wrap .name {padding-right: 10px;}
	.classInquiry_list_wrap .answerState + div {display: flex;}

	.classInquiry_list_wrap .title {
		width: 100%;
		font-size: 13px;
		text-align: left;
		padding-left: 0;
	}
	.classInquiry_answer .classInquiry_answer_bottom .title {padding-left: 0;}
	.classInquiry_question .title img {
		width: 14px;
		height: 16px;
		margin-right: 6px;
	}
	.classInquiry_question .arrowIcon {
		width: 12px;
		font-size: 0;
		right: 0;
		position: inherit;
		transform: translateY(0);
	}
	.classInquiry_question .arrowIcon svg {width: 12px;}

	.classInquiry_answer.isOn {border-top: 1px solid #eaeaea;}
	.classInquiry_answer {padding: 19px 14px;}
	.classInquiry_answer > div {
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 0;
	}
	.classInquiry_answer .square {
		width: 38px;
		height: 20px;    
		border-radius: 0px;
		font: 10px/1.8 'NotoSansMedium';
	}
	.classInquiry_answer_top .circle {
		width: 50px;
		height: 25px;
		font: 12px/22px 'NotoSansMedium';
	}
	.classInquiry_answer_top .title {
		width: 100%;
		padding-top: 14px;
		padding-bottom: 20px;
	}
	.classInquiry_answer_bottom .answerState{padding: 20px 0 14px;}
	.classInquiry_answer_bottom .date span {font:12px/1.5 'NotoSansRegular'}
	.classInquiry_answer_bottom .date span:last-child {font-size: 12px;line-height: 1.5;}

	.detailTabBox .table-empty {border-bottom: none;}
	.qa-btn.MuiButton-root {
		width: 115px;
		height: 34px;
	}
	.qa-btn.MuiButton-root img {height: 100%;}
}