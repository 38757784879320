.classInquiry-create-wrap {
    position: relative;
    width: 885px;

    .classInquiry-info-wrap {
        display: flex;
        .classInquiry-info-img {
            width: 154px;
            height: 142px;
            overflow: hidden;
            .orderInfo-img {
                display: block;
                width: 100%;height: 100%;
                cursor: default;
                img {width: 100%;height: 100%;object-fit: contain;}
            }
        }

        .card-content-wrap {
            padding: 0 0 30px 30px;
            width: calc(100% - 154px);
            .content-top + h3 {
                // font-size: $h6;
                font-size: 18px;
                margin-bottom: 18px;
                margin-top: 10px;
                cursor: default;
            }

            .content-bottom {
                .info-teacher {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .teacher_name, .teacher-rate {
                        > p {
                            font-size: 0.7rem;
                        }
                    }
                } 
                .teacher-img {
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    border: 0px solid #1c1c1c;
                    background-color: #fff;
                    border-radius: 50%;
                    overflow: hidden;
                    display: block;
                    margin-right: 15px;
                    img {
                        width: 100%;
                        // height: 100%;
                        // object-fit: contain;
                    }
                }
                .teacher_name {
                    width: 67%;
                    p {
                        font-family: 'NotoSansBold';
                        font-size: 16px;
                        color: #1c1c1c;
                    }
                    span {
                        font-size: 14px;
                        color: #222;
                    }
                    span.line-info-1 {
                        line-height: 1.2;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                    }
                    div.teacher-rate {
                        font-family: 'NotoSansMedium';
                        font-size: 14px;
                        color: #4e6883;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        padding-top: 5px;
                        cursor: pointer;
                        img {
                            width: 18px;
                            height: 17px;
                            margin-right: 3px;
                        }
                        span {
                            font-size: 15px;
                            color: #222;
                            font-weight: bold;
                            margin: 0 3px;
                        }
                        span:last-child {
                            font-size: 14px;
                            color: #666;
                            font-weight: 400;
                            position: relative;
                            margin: 0 14px;
                        }
                        span:last-child::before {
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 80%;
                            background-color: #666;
                            top: 60%;
                            left: -6px;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    }

    .inquiryTypeSelect > div {
        width: 100%; height: 59px;
        border-radius: 6px;
        margin-bottom: 16px;
    }
}

.classInquiry_list_wrap {
	width: 100%;
	border-top: 2px solid #0e2b49;
	margin-bottom: 39.5px;
	text-align: center;
	font: 16px/3.75 'NotoSansMedium';
	color: #222;

    li {
        border-bottom: 1px solid #eaeaea;
    }

    .num {
        width: 6.66%;
    }
    .answerState {
        width: 13.33%;
        font-size: 14px;
    }
    .title {
        width: 46.66%;
        text-align: left;
        padding-left: 30px;
        color: #222;
        font-family: 'NotoSansRegular';
    }
    .name {
        width: 13.33%;
    }
    .date {
        width: 19.99%;
        font-size: 14px;
    }
}

.classInquiry_list_title {
	width: 100%;
	height: 60px;
	padding-right: 80px;
	display: flex;
	justify-content: start;
	align-items: center;

    .title {
        text-align: center;
        padding-left: 0;
    }
    .answerState,
    .date {
        font-size: 16px;
    }
}

.classInquiry_question {
	width: 100%;
	height: 74px;
	display: flex;
	justify-content: start;
	align-items: center;
	padding-right: 80px;
	position: relative;
	color: #363e4a;

    .answerState.gray {
        color: #afb4b2;
    }
    
    .title img {
        width: 16px;
        height: 18px;
        margin-right: 12px;
        margin-bottom: 5px;
    }

    .arrowIcon {
        right: 36px;
        font-size: 0;

        svg {
            width: 16px;
        }
    }
}

.classInquiry_answer {
    .date {
        width: 22%;
        font-size: 14px;
    }

	display: none;
	width: 100%;
	padding: 0 80px;
	background-color: #f8f8f8;

    &.isOn {
        display: block;
    }

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
    }

    .title {
        width: 60%;
        line-height: 1.625;
    }

    > div.classInquiry_answer_top {
        &.flexStart {
            justify-content: flex-start;
        }
        .circle {
            display: inline-block;
            width: 62px;
            height: 32px;
            border-radius: 15px;
            border: 1px solid #1c1c1c;
            background-color: #fff;
            font: 14px/30px 'NotoSansMedium';
            letter-spacing: -0.35px;
            cursor: pointer;

            &:last-child {
                margin-left: 6px;
                color: #f04d4d;
            }
        }
    }

    .classInquiry_answer_bottom {
        border-top: 1px solid #eaeaea;

        .title {
            padding-left: 6px;
        }
        .square {
            color: #ff5651;
        }
        .date span {
            display: block;
            font: 14px/1.85 'NotoSansRegular';
            color: #666;
            letter-spacing: -0.35px;

            &:last-child {
                font-size: 14px;
                line-height: 1.625;
                letter-spacing: -0.4px;
            }
        }
    }

    .square {
        display: inline-block;
        width: 50px;
        height: 24px;
        border: 1px solid #afb4b2;
        border-radius: 3px;
        font: 12px/22px 'NotoSansMedium';
        letter-spacing: -0.3px;
        color: #666;
        background-color: #fff;
    }
}

@media screen and (max-width: 1023px) {
    .classInquiry-create-wrap {
        .inquiryTypeSelect > div {
            height: 50px;
        }
    }
}

/* .classInquiry-create-wrap {
    width: 100%;
    padding: 0 $mobileSideSpace;

    .classInquiry-info-wrap {
        border-bottom: 1px solid #eaeaea;
        .classInquiry-info-img {
            width: 80px;
            height: 74px;
        }
        .card-content-wrap {
            padding: 0 0 20px 20px;
            // width: calc(100% - 154px);
            width: calc(100% - 80px);
            .content-top + h3 {
                font-size: $h6;
            }
        }
    }
    .classInquiry-info-text-wrap {
        .pop-table {
            border: none;

            letter-spacing: -1px;
            .MuiGrid-root.top,
            .tr2 {
                margin-top: 20px;
                display: block;
                .columnLeft {
                    margin-bottom: 8px;
                }
                > * {
                    display: block;
                    width: 100%;
                    max-width: none;
                }
                .classReview-info-wrap {
                    display: flex;
                }
            }
            .columnLeft {
                font-size: $h7;
            }
            .columnRight {
                .input-type1 {
                    // display: block;
                    width: 100%;
                    .MuiOutlinedInput-input {
                        height: auto;
                    }
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid #d9dcde;
                        top: 0;
                    }
                    input {
                        padding: 14px;
                        font-size: 14px;
                        &::placeholder {
                            font-size: 14px;
                            color: #afb4b2;
                            opacity: 1;
                        }
                    }
                }
                .textarea-type1 {
                    padding: 14px;
                    height: 180px;
                    overflow-y: scroll;
                    font-size: $h7;
                    resize: none;
                    &::placeholder {
                        font-size: $h7;
                    }
                }
            }
        }
    }
    .classInquiry-notice-wrap {
        .classInquiry-notice-dot {
            font-size: $h8;
            .classInquiry-notice-bar {
                font-size: $h8;
            }
        }
    }
}

.classReview-create-wrap {
    width: 100%;
    .classReview-info-wrap {
        .classReview-info-img {
            width: 96px;
            height: 90px;
        }
        .card-content-wrap {
            padding: 0 0 0 20px;
            width: calc(100% - 96px);
            .content-top + h3 {
                font-size: $h6;
                margin-top: 6px;
            }
        }
    }
    .classReview-info-text-wrap {
        .pop-table {
            border: none;
            padding: 0 $mobileSideSpace;
            letter-spacing: -1px;
            .tr2 {
                // margin-top: 20px;
                &:first-child, &:last-child  {padding: 0 0 20px 0;}
                &.line1 {
                    // border-bottom: 2px solid $pointColor;
                    padding-bottom: 20px;
                    .columnLeft {
                        flex-basis: 25%;
                        max-width: 25%;
                    }
                    .columnRight {
                        padding-left: 8px;
                        max-width: 70%;
                        flex-basis: 70%;
                        .student-info {font-size: 13px;line-height: 1.3;color: #1c1c1c;}
                    }
                }
                &.line2 {
                    display: block;
                    .columnLeft {
                        margin-bottom: 8px;
                    }
                    > * {
                        display: block;
                        width: 100%;
                        max-width: none;
                    }

                    .classReview-info-wrap {
                        display: flex;
                    }
                    .columnRight {
                        max-width: 100%;
                        flex-basis: 100%;
                    }
                    &.rateLine {
                        display: flex;
                        .columnLeft {flex-basis: 25%;margin-bottom: 0;}
                        .columnRight {
                            padding-left: 6px;
                            max-width: 75%;
                            flex-basis: 75%;
                        }

                        + .tr2.line1 {
                            display: block;
                            border-bottom: 0;

                            .columnRight {
                                max-width: 100%;
                                padding-top: 10px;
                                padding-left: 0px;
                            }
                        }
                    }
                }
            }
            .columnLeft {
                font-size: $h7;
            }
            .columnRight {
                .MuiRating-iconFilled {
                    .MuiSvgIcon-root {
                        stroke-width: 1px;
                    }
                }
                .MuiRating-iconEmpty {
                    .MuiSvgIcon-root {
                        stroke-width: 1px;
                    }
                }
                .score-text {
                    font-size: $h6_2;
                    margin-top: 6px;
                    margin-left: 5px;
                    span {
                        font-size: $h6_2;
                    }
                }
                .textarea-type1 {
                    // min-height: 160px;
                    padding: 14px;
                    font-size: $h7;
                    resize: none;
                    overflow-y: scroll ;
                    height: 160px ;
                    &::placeholder {
                        font-size: $h7;
                    }
                }
            }
        }
    }
} */